export default function Agreement_Kinfa() {
  // 개인정보 수집·이용·제공 동의서(서민금융진흥원)
  return (
    <div className="agreement-container">
      <section>
        <b>[서민금융진흥원] 귀중</b>
        <span>
          서민금융진흥원(이하 ‘진흥원’이라 합니다)과의 신용보증약정 또는
          채권관리 등과 관련하여 진흥원이 본인의 개인(신용)정보를
          수집·이용하거나 제3자에게 제공하고자 하는 경우에는 「개인정보 보호법」
          제15조제1항제1호, 제17조제1항제1호, 제24조제1항제1호, 제24조의2
          「신용정보의 이용 및 보호에 관한 법률」제15조제2항, 제32조제1항,
          제33조 및 제34조에 따라 본인의 동의를 얻어야 합니다. 이에 본인은
          진흥원이 아래의 내용과 같이 본인의 개인(신용)정보를 수집·이용 또는
          제공하는 것에 동의합니다.
        </span>
      </section>
      <section>
        <h3>1. 수집·이용에 대한 사항</h3>
        <h4 className="list-title">1-1. 수집·이용 목적</h4>
        <ul>
          <li>(1) 신용보증 거래관계의 설정 여부 판단</li>
          <li>
            (2) 신용보증 거래관계의 설정·유지·이행·관리(기일 안내 상담, 각종
            기일도래 DM발송 포함)
          </li>
          <li>(3) 채권관리, 금융사고 조사, 분쟁 해결, 민원 처리 </li>
          <li>(4) 법령상의 의무이행 등</li>
        </ul>
        <h4 className="list-title">1-2. 보유 및 이용기간</h4>
        <ul>
          <li>
            (1) (금융)거래 종료일로부터 5년까지
            <br />
            ((금융)거래 종료일 후에는 금융사고 조사, 분쟁 해결, 민원처리,
            진흥원의 리스크 관리업무 및 관련 법령상 의무이행만을 위하여
            보유·이용됩니다. 단, 관련 법령에 별도 규정이 있는 경우 해당 법령상의
            보존기간을 따릅니다)
            <br />
            *위 보유기간에서의 거래 종료일이란 계약상 일련의 과정(보증, 구상권
            회수 등) 및 서비스(전자금융거래 등)가 종료한 날 중 가장 나중에
            도래한 사유를 기준으로 판단한 날을 말합니다.
          </li>
        </ul>
        <h4 className="list-title">1-3. 거부 권리 및 불이익</h4>
        <ul>
          <li>
            위 개인(신용)정보의 수집·이용 동의를 거부하실 수 있습니다. 다만, 위
            개인(신용)정보 수집·이용에 관한 동의는 “(금융)거래 계약의 체결 및
            이행을 위한” 필수적 사항이므로, 위 사항에 동의하지 않으실 경우
            신용보증 등 거래관계의 설정 또는 유지가 불가합니다.
          </li>
        </ul>
        <h4 className="list-title">1-4. 수집·이용 항목</h4>
        <ul>
          <li>
            (1) 고유식별정보(주민등록번호, 외국인등록번호, 여권번호,
            운전면허번호)
          </li>
          <li>(2) 개인(신용)정보</li>
        </ul>
        <ul className="list-2depth">
          <li>⦁ 일반개인정보</li>
          <li>
            - 성명, 연령, 주소, 생년월일, 이메일, 유‧무선 전화번호, 성별, 국적,
            직장명, 직위, 입사일, 재직기간, 담당업무, 직장연락처, 4대보험
            가입여부, 진흥원 금융교육 및 신용․부채관리 컨설팅 관련
            정보(교육여부, 교육일자)
          </li>
          <li>⦁ 신용거래정보</li>
          <li>
            - 금융거래 업무 관련 정보(상품종류, 거래조건(이자율, 만기, 담보 등),
            거래일시, 거래금액 등 거래 설정 및 내역정보), 사업자등록번호,
            (금융)거래 설정·유지·이행·관리를 위한 상담 등을 통해 생성되는 정보
          </li>
          <li>⦁ 신용도판단정보</li>
          <li>
            - 연체‧대위변제‧대지급‧부도‧금융질서 문란 및 관련인 등록과 관련된
            금액, 발생, 해소 시기 등에 관한 정보
          </li>
          <li>⦁ 신용능력정보</li>
          <li>
            - 재산, 채무, 월평균소득, 연소득, 납세실적, 4대보험 납부 정보,
            주거소유여부, 주거형태
          </li>
          <li>⦁ 공공정보 등</li>
          <li>
            - 법원 및 공공기관의 재판‧결정 또는 체납 등의 공공정보,
            개인신용평점(등급)
          </li>
        </ul>
      </section>
      <section>
        <h3>2. 제공에 관한 사항 </h3>
        <h4 className="list-title">2-1. 제공받는 자</h4>
        <ul>
          <li>(1) 종합신용정보집중기관 : 한국신용정보원</li>
          <li>(2) 신용정보회사 : 코리아크레딧뷰로(주), NICE평가정보(주)</li>
          <li>
            (3) 금융기관 : 「서민의 금융생활지원에 관한 법률」제2조제1호에 의한
            금융회사
          </li>
          <li>
            (4) 기타기관 : 신용보증기금, 기술보증기금, 지역신용보증재단,
            신용회복위원회, 한마음금융(주), ㈜국민행복기금, 신용보증재단중앙회
            등
          </li>
        </ul>
        <h4 className="list-title">2-2. 제공받는 자의 이용목적</h4>
        <ul>
          <li>
            (1) 종합신용정보집중기관 : 개인(신용)정보 조회, 신용정보의 집중관리
            및 활용 등 법령에서 정한 종합신용정보집중기관의 업무수행
          </li>
          <li>
            (2) 신용정보회사 : 개인(신용)정보 조회, 신용정보의 집중관리‧활용 및
            교환, 신용평가모형 개발‧분석, 본인의 신용 판단 등 법령에서 정한
            신용정보회사의 업무수행, 공공기관 정책자료로 활용(비식별정보 형태)
          </li>
          <li>(3) 금융기관 : 본인 확인, 금융거래 업무 등</li>
          <li>(4) 기타기관 : 본인의 신용판단 등</li>
        </ul>
        <h4 className="list-title">2-3. 보유 및 이용기간</h4>
        <ul>
          <li>
            (1) 동의 철회시 또는 제공받는 자의 이용목적을 달성할 때까지 (동의
            철회 또는 제공된 목적 달성 후에는 금융사고 조사, 분쟁 해결, 민원처리
            및 관련 법령상 의무이행만을 위하여 보유·이용됩니다. 단, 관련 법령에
            별도 규정이 있는 경우 해당 법령상의 보존기간을 따릅니다)
          </li>
        </ul>
        <h4 className="list-title">2-4. 거부 권리 및 불이익</h4>
        <ul>
          <li>
            (1) 위 개인(신용)정보의 제공 동의를 거부하실 수 있습니다. 다만, 위
            개인(신용)정보 제공에 관한 동의는 “(금융)거래 계약의 체결 및 이행을
            위한” 필수적 사항이므로, 위 사항에 동의하지 않으실 경우 신용보증 등
            거래관계의 설정 또는 유지가 불가합니다.
          </li>
        </ul>
        <h4 className="list-title">2-5. 제공 항목</h4>
        <ul>
          <li>
            (1) 고유식별정보(주민등록번호, 외국인등록번호, 여권번호,
            운전면허번호)
          </li>
          <li>
            (2) 개인(신용)정보
            <ul className="list-2depth">
              <li>⦁ 일반개인정보</li>
              <li>
                - 성명, 연령, 주소, 생년월일, 이메일, 유‧무선 전화번호, 성별,
                국적, 직장명, 직위, 입사일, 재직기간, 담당업무, 직장연락처,
                4대보험 가입여부
              </li>
              <li>⦁ 신용거래정보</li>
              <li>
                - 금융거래 업무 관련 정보(상품종류, 거래조건(이자율, 만기, 담보
                등), 거래일시, 거래금액 등 거래 설정 및 내역정보), (금융)거래
                설정·유지·이행·관리를 위한 상담 등을 통해 생성되는 정보
              </li>
              <li>⦁ 신용도 판단정보</li>
              <li>
                - 연체‧대위변제‧대지급‧부도‧금융질서 문란 및 관련인 등록과
                관련된 금액, 발생‧해소 시기 등에 관한 정보
              </li>
              <li>⦁ 신용능력정보</li>
              <li>
                - 재산, 채무, 월평균소득, 연소득, 납세실적, 4대보험 납부 정보,
                주거소유여부, 주거형태
              </li>
            </ul>
          </li>
        </ul>
        <span style={{ paddingTop: "30px" }}>
          ※ 업무위탁을 목적으로 개인(신용)정보를 처리하는 경우 별도의 동의 없이
          업무 수탁자에게 귀하의 정보를 제공할 수 있습니다. 진흥원은 수탁업체에
          개인(신용)정보를 제공할 경우, 업무수행에 필요한 최소한의 정보만을
          제공하며, 수탁업체의 추가·변경 및 제공 목적의 세부적 내용은 진흥원
          홈페이지에서 확인하실 수 있습니다. <br />
          ※ 금융기관의 고의 또는 과실 등 귀책사유로 인한 개인정보 유출로
          고객님에게 발생한 손해에 대해 관계법령 등에 따라 보장받으실 수
          있습니다. <br />※ 본 동의 이후 동일한 목적 또는 이용 범위 내에서
          개인(신용)정보의 정확성, 최신성을 유지하기 위해 제공하는 경우에는
          별도의 추가 동의가 필요하지 않습니다.
        </span>
      </section>
      <section className="last-section">
        <h3>3. 행정ㆍ공공기관 자료제공 요청 안내 </h3>
        <h4 className="list-title">
          3-1. 진흥원은 본인에 대한 신용보증과 관련하여 아래와 같이
          행정·공공기관에 본인에 대한 자료 제공을 요청할 수 있습니다.
        </h4>
        <ul>
          <li>
            (1) 근거법률 : 「서민의 금융생활 지원에 관한 법률」(이하
            ‘서민금융법’) 제81조
          </li>
          <li>
            (2) 대상기관 : 국가기관(국토교통부, 행정안전부, 고용노종부, 국세청
            등), 지방자치단체, 국민연금공단, 국민건강보험공단, 근로복지공단,
            한국자산관리공사, 신용보증재단중앙회, 그 밖에 서민금융법 시행령으로
            정하는 공공단체
          </li>
          <li>
            (3) 요청정보 : 지적 전산정보 자료, 주민등록 전산정보 자료,
            과세정보(사업자등록증명원, 국세납세증명서, 납세사실증명원,
            폐업사실증명원 등)등
          </li>
          <li>(4) 사용목적 : 신용보증, 구상권행사</li>
          <li>(5) 요청기간 : 보증채무 또는 구상채무 소멸시까지</li>
        </ul>
      </section>
    </div>
  );
}
