export default function Agreement_Bank() {
  // [필수] 개인(신용)정보 수집·이용∙제공 동의서 [여신금융거래]
  return (
    <div className="agreement-container">
      <section>
        <p>2022.02.11</p>
        <b>[이든파이낸셜 제휴 금융사] 귀중</b>
        <span>
          금융기관과의 여신(금융)거래와 관련하여, 금융기관이 귀하의
          개인(신용)정보를 수집·이용하거나 제3자에게 제공하고자 하는 경우에는
          「신용정보의 이용 및 보호에 관한 법률」, 「개인정보보호법」 등
          관계법령에 따라 귀하의 동의가 필요합니다.
          <br />※ 여신(금융)거래라 함은 대출업무(여신), 겸영업무(신용카드 등),
          부수업무(보증, 팩토링 등)와 관련된 거래를 의미합니다.
        </span>
      </section>
      <span>
        이 동의서를 통해 귀하의 동의를 받고자 하는 금융기관은 아래와 같습니다.
        <br />
        ㈜다올저축은행, ㈜키움저축은행, ㈜애큐온캐피탈
      </span>
      <section>
        <h3>1. 수집·이용에 대한 사항</h3>
        <h4 className="list-title">1-1. 수집·이용 목적 </h4>
        <ul>
          <li>(1) (금융) 거래관계의 설정·유지·이행·관리</li>
          <li>(2) (금융) 거래관계의 설정 여부 판단</li>
          <li>(3) 금융사고 조사, 분쟁 해결, 민원 처리 </li>
          <li>(4) 법령상 의무이행, 본인확인</li>
        </ul>
        <h4 className="list-title">1-2. 보유 및 이용기간 </h4>
        <ul>
          <li>
            (1) (금융)거래 종료일로부터 5년간 보유·이용
            <br />
            (단, 다른 관련 법령에 해당하는 경우 해당 법령상의 보존기간을 따름)
          </li>
          <li>(2) 계약미체결시 : 정보 제공일로부터 최대 3개월</li>
        </ul>
        <span>
          ※ 위 보유 기간에서의 (금융)거래 종료일이란 “이든파이낸셜 제휴 금융사와
          거래중인 모든 계약(여·수신, 내·외국환, 카드 및 제3자 담보제공) 해지 및
          서비스(대여금고, 보호예수, 외국환 거래지정, 인터넷뱅킹 포함
          전자금융거래 등)가 종료된 날”을 말합니다.
          <br />※ (금융)거래 종료일 후에는 금융사고 조사, 분쟁해결, 민원처리,
          법령상 의무이행을 위한 목적으로만 보유·이용됩니다.
        </span>
        <h4 className="list-title">1-3. 거부 권리 및 불이익 </h4>
        <ul>
          <li>
            (1) 귀하는 동의를 거부하실 수 있습니다. 다만, 위 개인(신용)정보
            수집·이용에 관한 동의는 “직장인신용대출, 새희망홀씨대출, 아파트
            신용대출, 기타신용대출 등 (금융)거래 계약의 체결 및 이행”을 위한
            필수적 사항이므로, 위 사항에 동의하셔야만 (금융)거래관계의 설정 및
            유지가 가능합니다.
          </li>
        </ul>
        <h4 className="list-title">1-4. 수집·이용 항목 </h4>
        <ul>
          <li>
            (1) 고유식별정보(주민등록번호, 외국인등록번호, 여권번호,
            운전면허번호)
          </li>
          <li>
            (2) 개인(신용)정보
            <ul className="list-2depth">
              <li>⦁ 일반개인정보</li>
              <li>
                - 성명, 주소, 휴대전화번호, 자택전화번호, 국적,
                국내거소신고번호, 전자우편, 연계정보(CI) <br />
                - 직장정보(직업군, 직장명, 직장전화번호, 직장주소, 부서명, 직위,
                고용형태, 입사년월일) <br />
                - 거주정보(주거구분, 전입일자, 동거가족 등) <br />- 고객ID,
                접속일시, IP주소, 이용 전화번호 등 전자금융거래법에 따른
                수집정보(전자금융거래에 한함)
              </li>
              <li>⦁ 신용거래정보</li>
              <li>
                - (금융)거래의 설정·유지·이행·관리를 위한 상담 등을 통해
                생성되는 정보 등 <br /> - 거래일시, 상품정보, 금액,
                거래조건(이자율, 만기 등), 거래설정·내역정보 등 <br /> - 타기관
                대출결제계좌 금융기관명 및 타기관계좌번호, 당해 대출계좌번호,
                납부하여야 할 원(리)금액 <br />
                - 원리금납입계좌번호 <br />- 타기관 예금계좌 거래내역 건수/최초
                개설일자/예금주명(타기관 인터넷뱅킹 가입고객을 대상으로 하는
                전자금융거래에 한함)
              </li>
              <li>⦁ 신용능력정보</li>
              <li>
                - 연소득정보, 연소득 추정정보(근로소득, 사업소득, 카드사용액,
                건강보험료 납부관련정보, <br />
                국민연금납부관련정보, 부동산임대료, 매출액정보 등) <br />
                - 재산정보(기타금융자산, 주택구입정보, 임대차보증금 등) <br />-
                소유부동산정보, 임대계약정보, 납세실적정보, 부채현황 등
                신용평가를 위한 정보
              </li>
              <li>⦁ 신용도 판단정보</li>
              <li>
                - 연체정보, 대위변제·대지급정보, 부도정보, 금융질서문란정보 등
              </li>
              <li>⦁ 공공정보 등</li>
              <li>
                - 개인신용평점정보, 건강보험가입/납입정보,
                국민연금가입/납입정보, 세금체납정보, 채무불이행 관련정보,
                산재/고용보험료/임금 체납(불)정보, 신용회복지원(회생, 파산면책,
                신용회복위원회, 국민행복기금 등) 정보 등, 사회보험·공공요금
                관련정보, 주민등록관련정보 등 공공기록정보
              </li>
            </ul>
          </li>
        </ul>
      </section>
      <section className="last-section">
        <h3>2. 제공에 관한 사항 </h3>
        <h4 className="list-title">2-1. 제공받는 자 </h4>
        <ul>
          <li>
            (1) 종합신용정보집중기관([신용정보의 이용 및 보호에 관한 법률] 제 25
            조제 1 항에 따른 종합신용정보집중기관) : (사)한국신용정보원
          </li>
          <li>
            (2) 신용정보회사([신용정보의 이용 및 보호에 관한 법률] 제 2 조제 5
            호에 따른 신용정보회사) : NICE 평가정보㈜, 코리아크레딧뷰로㈜, SCI
            평가정보㈜, 한국기업데이터㈜, 나이스디앤비㈜, ㈜이크레더블
          </li>
          <li>
            ※ 신용조회사 및 신용정보집중기관의 세부 현황은 각 금융회사
            홈페이지에서 확인하실 수 있습니다.
          </li>
        </ul>
        <h4 className="list-title">2-2. 제공받는 자의 이용목적</h4>
        <ul>
          <li>
            (1) 본인의 신용 판단 및 본인확인 등 법령에서 정한 신용정보회사의
            업무수행
          </li>
          <li>
            (2) 본인의 신용 판단 및 본인확인 등 법령에서 정한 신용정보회사의
            업무수행
          </li>
        </ul>
        <h4 className="list-title">2-3. 보유 및 이용기간</h4>
        <ul>
          <li>
            (1) 본인와 제공받는 자의 (금융)거래 종료일로부터 5년간
            보유·이용됩니다.
            <br />
            ※ (금융)거래 종료일 후에는 금융사고 조사, 분쟁해결, 민원처리 및
            법령상 의무이행만을 위하여 보유·이용됩니다
            <br />※ 위 보유기간에서의 (금융)거래 종료일이란 “제공받는 자와
            거래중인 모든 계약(여·수신, 내·외국환, 카드 및 제3자 담보제공 등)
            해지 및 서비스(대여금고, 보호예수, 외국환거래지정, 인터넷뱅킹 포함
            전자금융거래 등)가 종료된 날”을 말합니다.
          </li>
        </ul>
        <h4 className="list-title">2-4. 거부 권리 및 불이익</h4>
        <ul>
          <li>
            귀하는 동의를 거부하실 수 있습니다. 다만, 위 개인(신용)정보 제공에
            관한 동의는 “(금융)거래계약의 체결 및 이행”을 위한 필수적
            사항이므로, 위 사항에 동의하셔야만 (금융)거래관계의 설정 및 유지가
            가능합니다.
          </li>
        </ul>
        <h4 className="list-title">2-5. 제공 항목</h4>
        <ul>
          <li>
            (1) 고유식별정보(주민등록번호, 외국인등록번호, 여권번호,
            운전면허번호)
          </li>
          <li>
            (2) 개인(신용)정보
            <ul className="list-2depth">
              <li>⦁ 일반개인정보</li>
              <li>
                - 성명, 주소, 휴대전화번호, 자택전화번호, 국적,
                국내거소신고번호, 전자우편, 연계정보(CI)
              </li>
              <li>
                - 직장정보(직업군, 직장명, 직장전화번호, 직장주소, 부서명, 직위,
                고용형태, 입사년월일)
              </li>
              <li>- 거주정보(주거구분, 전입일자, 동거가족 등)</li>
              <li>
                - 고객ID, 접속일시, IP주소, 이용 전화번호 등 전자금융거래법에
                따른 수집정보(전자금융거래에 한함)
              </li>
              <li>⦁ 신용거래정보</li>
              <li>
                - (금융)거래의 설정·유지·이행·관리를 위한 상담 등을 통해
                생성되는 정보 등
                <br />
                - 거래일시, 상품정보, 금액, 거래조건(이자율, 만기 등),
                거래설정·내역정보 등
                <br />
                - 타기관 대출결제계좌 금융기관명 및 타기관계좌번호, 당해
                대출계좌번호, 납부하여야 할 원(리)금액
                <br />
                - 원리금납입계좌번호
                <br />- 타기관 예금계좌 거래내역 건수/최초
                개설일자/예금주명(타기관 인터넷뱅킹 가입고객을 대상으로 하는
                전자금융거래에 한함)
              </li>
              <li>⦁ 신용능력정보</li>
              <li>
                - 소득정보(연소득, 기타소득 등)
                <br /> - 재무현황(타 금융기관 부채현황 등)
                <br /> - 재산·채무·소득의 총액, 납세실적 등
              </li>
              <li>⦁ 신용도 판단정보</li>
              <li>
                - 연체정보, 대위변제·대지급정보, 부도정보, 금융질서문란정보 등
              </li>
              <li>⦁ 공공정보 등</li>
              <li>
                - 개인신용평점정보, 세금체납정보, 채무불이행정보, 채무조정정보,
                주민등록관련정보, 사망자 정보 등 공공기록정보
              </li>
            </ul>
          </li>
        </ul>
        <span style={{ paddingTop: "60px" }}>
          ※ 본 동의 이전에 발생한 개인(신용)정보도 수집·이용·제공 대상에
          포함됩니다. <br />
          ※ 금융기관의 고의 또는 과실 등 귀책사유로 인한 개인정보 유출로
          고객님에게 발생한 손해에 대해 관계법령 등에 따라 보장받으실 수
          있습니다. <br />※ 본 동의 이후 동일한 목적 또는 이용 범위 내에서
          개인(신용)정보의 정확성, 최신성을 유지하기 위해 제공하는 경우에는
          별도의 추가 동의가 필요하지 않습니다.
        </span>
      </section>
    </div>
  );
}
