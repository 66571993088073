import { TiArrowSortedDown } from "react-icons/ti";
import { useHistory } from "react-router-dom";

export default function CarProductCard({ item, isLease }) {
  const history = useHistory();

  return (
    <div
      className="car-card"
      onClick={() => {
        history.push({
          pathname: `/${isLease ? "lease" : "installment"}/list/detail`,
          state: {
            item: {
              car_name: item.car_name,
              fuel: item.fuel,
              car_logo: item.car_logo,
              car_img: item.car_img,
              greatest_interest: item.greatest_interest,
              lowest_interest: item.lowest_interest,
              release_price: item.release_price,
              greatest_price: item.greatest_price,
              lowest_price: item.lowest_price,
            },
          },
        });
      }}
    >
      <section className="card-top">
        <div className="card-name">
          <h3>{item.car_name}</h3>
          <h5>{item.fuel}</h5>
        </div>
        <img src={item.car_logo} alt="브랜드로고" />
      </section>
      <section className="car-img">
        <img src={item.car_img} alt="자동차" />
      </section>
      <section className="card-middle">
        <div className="middle-item">
          <span>{isLease ? "최고가" : "최고금리"}</span>
          {isLease ? (
            <h3>
              <span className="small">월 </span>
              {Number(item.greatest_price).toLocaleString()}
              <span> 원</span>
            </h3>
          ) : (
            <h3>{item.greatest_interest}%</h3>
          )}
        </div>
        <div className="middle-item">
          <span>{isLease ? "최저가" : "최저금리"}</span>
          {isLease ? (
            <h3>
              <span className="small">월 </span>
              {Number(item.lowest_price).toLocaleString()}
              <span> 원</span>
            </h3>
          ) : (
            <h3>{item.lowest_interest}%</h3>
          )}
        </div>
      </section>
      <span className="card-last">
        출고가 {(item.release_price / 10000).toLocaleString()}만원ㅣ
      </span>
    </div>
  );
}
