import React, { useState, useEffect } from "react";
import LoanPurpose from "../more-infomation/loan-purpose/Loan_Purpose";
import HouseInformation from "./house-info/House_Information";
import JobInformation from "./job-info/Job_Infomation";
import NHMbrDs from "./NHMembersDivision/NHMbrDs";
import CarInformation from "./car-info/Car_Information";
import { useRecoilState } from "recoil";
import * as state from "recoil/atom";
import "./More_Information.scss";
// import edenLogo from "assets/eden-financial-logo-mobile.svg";
import { useForm } from "react-hook-form";
import { cdn_url } from "utils/api/_utils";
import { useHistory } from "react-router-dom";
const edenLogo = `${cdn_url}/assets/eden-financial-logo-mobile.svg`;

export default function More_Information() {
  const history = useHistory();
  const [userInfo, setUserInfo] = useRecoilState(state.userInfoState);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
  } = useForm();

  const onFormValid = (data) => {
    console.log(data);
    if (Boolean(getValues("plate_number"))) {
      if (
        /([0-9]{2,3})+([가-힣]{1})+([0-9]{4})$/.test(
          getValues("plate_number")
        ) === false
      ) {
        setError("plate_number", true);
        alert("입력항목을 확인해주세요");
        return;
      }
    }
    history.push("/credit-loan");
  };

  const onFormInvalid = (data) => {
    alert("입력항목을 확인해주세요");
    console.log(data);
  };

  return (
    <form
      className="more-information-container"
      onSubmit={handleSubmit(onFormValid, onFormInvalid)}
    >
      <div className="more-information-outer">
        <img src={edenLogo} alt="e-든든" />
        <div className="outer-textbox">
          <p>든든 대출 조회</p>
          <div className="indicator">
            <div className="pills" />
            <div className="pills active" />
            <div className="pills" />
          </div>
        </div>
      </div>
      <div className="more-information-inner">
        <div className="heading">
          <h2>추가 정보를 입력해주세요</h2>
          <p>정확한 정보를 위해 꼭 필요한 정보입니다.</p>
        </div>
        <LoanPurpose
          register={register}
          errors={errors}
          getValues={getValues}
        />
        <JobInformation
          register={register}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
        />
        <NHMbrDs
          register={register}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          setUserInfo={setUserInfo}
        />
        <HouseInformation register={register} errors={errors} />
        <CarInformation register={register} errors={errors} />
        <button className="confirm-btn">조회하기</button>
      </div>
    </form>
  );
}
