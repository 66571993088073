import { Link, useRouteMatch } from "react-router-dom";
import BottomIndicator from "./BottomIndicator";

function NaviMenus({ closeMobileNavi, resetAtomState }) {
  const introductionMatch = useRouteMatch("/introduction");
  const credit = useRouteMatch("/credit-certification"); // 신용대출
  const mortage = useRouteMatch("/mortage-certification"); // 담보대출
  const ism = useRouteMatch("/ism-certification"); // 할부, 리스

  return (
    <>
      <div
        className="navi-item"
        onClick={() => {
          resetAtomState();
          closeMobileNavi();
        }}
      >
        <Link
          to="/introduction"
          className={`navi-item-link ${introductionMatch ? "active" : ""}`}
        >
          회사소개
          {introductionMatch && <BottomIndicator />}
        </Link>
      </div>
      <div
        className="navi-item"
        onClick={() => {
          console.log("신용");
          resetAtomState();
          closeMobileNavi();
        }}
        // onClick={(resetAtomState, closeMobileNavi)}
      >
        <Link
          to="/credit-certification"
          className={`navi-item-link ${credit ? "active" : ""}`}
        >
          신용대출
          {credit && <BottomIndicator />}
        </Link>
      </div>
      <div
        className="navi-item"
        onClick={() => {
          console.log("담보");
          resetAtomState();
          closeMobileNavi();
        }}
        // onClick={(resetAtomState, closeMobileNavi)}
      >
        <Link
          to="/mortage-certification"
          className={`navi-item-link ${mortage ? "active" : ""}`}
        >
          담보대출
          {mortage && <BottomIndicator />}
        </Link>
      </div>
      <div
        className="navi-item"
        onClick={() => {
          console.log("할부");
          resetAtomState();
          closeMobileNavi();
        }}
        // onClick={(resetAtomState, closeMobileNavi)}
      >
        <Link
          to="/ism-certification"
          className={`navi-item-link ${ism ? "active" : ""}`}
        >
          {ism && <BottomIndicator />}
          할부/리스
        </Link>
      </div>
    </>
  );
}

export default NaviMenus;
