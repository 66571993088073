import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import LicenseModal from "./screens/LicenseModal";
import FTLogo1 from "assets/footer/footer-logo1.svg";
import FTLogo2 from "assets/footer/footer-logo2.svg";
import FTLogo3 from "assets/footer/footer-logo3.svg";
import FTLogo4 from "assets/footer/footer-logo4.svg";
import FTLogo4Color from "assets/footer/footer-logo4-color.svg";
import RelatedSite from "./screens/RelatedSite";
import { footerLink } from "utils/siteLink";

function Footer() {
  const [showLicenseModal, setShowLicenseModal] = useState({
    id: "",
    title: "",
    show: false,
  });

  const openLicense = (event) => {
    setShowLicenseModal((prevState) => ({
      ...prevState,
      [`id`]: event.target.value,
      [`title`]: event.target.id,
      [`show`]: true,
    }));
  };

  const lscList = [
    {
      id: "1",
      name: "기업부설연구소",
      heading: "기업부설연구소 인정서",
      img: FTLogo1,
    },
    {
      id: "2",
      name: "대출모집인 등록",
      heading: "대출모집인 등록증",
      img: FTLogo2,
    },
    {
      id: "3",
      name: "벤처기업 혁신유형",
      heading: "벤처기업 확인증",
      img: FTLogo3,
    },
    {
      id: "4",
      name: "한국핀테크 산업협회 회원",
      heading: "한국핀테크 산업협회 회원증",
      img: FTLogo4,
    },
  ];

  return (
    <>
      <LicenseModal
        showLicenseModal={showLicenseModal}
        setShowLicenseModal={setShowLicenseModal}
      />
      <div className="footer-container-top">
        <div className="footer-top-box">
          <ul className="footer-ul">
            {footerLink.map((link, idx) => (
              <li className="footer-li" key={idx}>
                <a href={link.link} target="_blank" rel="noopener noreferrer">
                  {link.name}
                </a>
              </li>
            ))}
            {/* <li className="footer-li">
              <Link to="/partnership">사업제휴 문의</Link>
            </li> */}
          </ul>
          <RelatedSite />
        </div>
      </div>
      <div className="footer-container-bottom">
        <div className="footer-inner">
          <section className="footer-company-detail">
            <strong>(주) 이든파이낸셜</strong>
            <div>
              <p className="detail-box detail-top">
                <span>대표 정형화</span>
                <span>사업자 등록 번호 870-88-01482</span>
              </p>
              <p className="detail-box detail-bottom">
                주소 광주광역시 서구 상무대로 989-4, 5층(내방동)
              </p>
            </div>
          </section>
          <div className="footer-link">
            {lscList.map((item, idx) => (
              <div className="link-btn" key={idx}>
                <button
                  id={item.heading}
                  value={item.id}
                  onClick={openLicense}
                />
                <img
                  className={item.id === "4" ? "change-img" : null}
                  src={item.img}
                  alt={item.name}
                />
                {item.id === "4" && (
                  <img
                    className="change-img"
                    src={FTLogo4Color}
                    alt={item.name}
                  />
                )}
                {item.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
