import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import * as state from "recoil/atom";
import "./MoreMortage.scss";
// import edenLogo from "assets/eden-financial-logo-mobile.svg";
import { useForm } from "react-hook-form";
import { cdn_url } from "utils/api/_utils";
import { useHistory } from "react-router-dom";
import CreditScore from "./screens/CreditScore";
import MortageFamilyInfo from "./screens/MortageFamilyInfo";
import MortageJobInfo from "./screens/MortageJobInfo";
import MortagePurpose from "./screens/MortagePurpose";
import MortageOffice from "./screens/MortageOffice";
const edenLogo = `${cdn_url}/assets/eden-financial-logo-mobile.svg`;

export default function MoreMortage() {
  const history = useHistory();
  const [isResult, setIsResult] = useState(false);
  const [userInfo, setUserInfo] = useRecoilState(state.userInfoState);
  const [moreInfo, setMoreInfo] = useState({
    purpose: "",
    amount: "",
    salary: "",
    join_date: "",
    credit_score: "",
    income_type: "",
    marriage: "",
    house: "",
    children: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
  } = useForm();

  const onFormValid = (data) => {
    console.log(data);
    if (
      /^\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01])$/.test(
        data.join_date
      ) === true
    ) {
      setIsResult(true);
      history.push("/mortage-certification/house-info", {
        state: data,
      });
    } else {
      alert("입력사항을 확인해주세요");
      setError("join_date", { shouldFocus: true });
      return;
    }
  };

  const onFormInvalid = (data) => {
    alert("입력항목을 확인해주세요");
    console.log(data);
    if (
      /^\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01])$/.test(
        data.join_date
      ) === true
    ) {
      setError("join_date", { shouldFocus: true });
      return;
    }
  };

  const handleChangeValue = (event) => {
    setMoreInfo((prevState) => ({
      ...prevState,
      [`${event.target.name}`]: event.target.value,
    }));
  };

  // console.log(moreInfo);

  return (
    <form
      className="more-information-container"
      onSubmit={handleSubmit(onFormValid, onFormInvalid)}
    >
      <div className="more-information-outer">
        <img src={edenLogo} alt="e-든든" />
        <div className="outer-textbox">
          <p>든든 담보대출 조회</p>
          <div className="indicator">
            <div className="pills" />
            <div className="pills active" />
            <div className="pills" />
            <div className="pills" />
          </div>
        </div>
      </div>
      <div className="more-information-inner">
        <div className="heading">
          <h2>추가 정보를 입력해주세요</h2>
          <p>정확한 금리 조회를 위한 필수 정보입니다.</p>
        </div>
        <MortagePurpose
          register={register}
          errors={errors}
          handleChangeValue={handleChangeValue}
          getValues={getValues}
          moreInfo={moreInfo}
        />
        <MortageOffice
          register={register}
          errors={errors}
          handleChangeValue={handleChangeValue}
          getValues={getValues}
          moreInfo={moreInfo}
        />
        <CreditScore
          register={register}
          errors={errors}
          handleChangeValue={handleChangeValue}
          getValues={getValues}
          moreInfo={moreInfo}
        />
        <MortageJobInfo
          register={register}
          errors={errors}
          handleChangeValue={handleChangeValue}
          getValues={getValues}
          moreInfo={moreInfo}
        />
        <MortageFamilyInfo
          register={register}
          errors={errors}
          handleChangeValue={handleChangeValue}
          getValues={getValues}
          moreInfo={moreInfo}
        />
        <button className="confirm-btn">다음</button>
      </div>
    </form>
  );
}
