// 할부, 리스
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { leaseFilter } from "components/koscom/koscom.js";
import FilterSelectStyle from "components/styledComponent/FilterSelectStyle.jsx";
import CarSelelct from "../filter/CarSelect.jsx";

export default function LeaseFilter() {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [leaseOption, setLeaseOption] = useState({
    condition: "국산차",
    manufacturer: "",
    type: "",
    model: "",
    rate: "",
    period: "",
  });

  const [openOption, setOpenOption] = useState("");
  const [optionState, setOptionState] = useState("국산차");
  const istm_session = JSON.parse(window.sessionStorage.getItem("installment"));

  const switchOrder = (event) => {
    const value = event.target.parentNode.id;
    setLeaseOption((prevState) => ({
      ...prevState,
      [`${value}`]: event.target.id,
    }));
  };

  const handleBadgeState = (event) => {
    setLeaseOption((prevState) => ({
      ...prevState,
      [`${event.target.name}`]: event.target.id,
    }));
  };

  const onFormValid = () => {
    history.push("/lease/list", {
      leaseOption: leaseOption,
    });
    window.sessionStorage.setItem("lease", JSON.stringify(leaseOption));
  };

  return (
    <>
      <div className="loanable-list-container">
        <div className="loanable-list-outer">
          <div className="outer-textbox">
            <p>할부 / 리스</p>
          </div>
        </div>
        <div className="loanable-list-inner">
          <section className="installment-lease-container">
            <div className="select-loanway">
              <Link to={`/installment${istm_session === null ? "" : "/list"}`}>
                할부
              </Link>
              <Link to="/lease" className="active">
                리스
              </Link>
            </div>
            <form
              className="filter-select-container"
              onSubmit={handleSubmit(onFormValid)}
            >
              <div style={{ zIndex: "4" }}>
                <span className="option-title">상품</span>
                <FilterSelectStyle
                  selectId="condition"
                  optionArr={leaseFilter.condition}
                  openOption={openOption}
                  setOpenOption={setOpenOption}
                  changeOptionState={switchOrder}
                  carOption={leaseOption}
                  state="0"
                  modelNull={false}
                />
              </div>
              <CarSelelct
                optionState={optionState}
                data={leaseFilter}
                filter={leaseOption}
                openOption={openOption}
                setOpenOption={setOpenOption}
                switchOrder={switchOrder}
                isLease={true}
              />
              <div>
                <span className="option-title">현금 구매비율</span>
                <div className="filter-badge-box">
                  {leaseFilter.rate.map(({ labelName }, index) => (
                    <div className="filter-badge" key={index}>
                      <input
                        autoFocus
                        id={labelName}
                        value={labelName}
                        type="radio"
                        name="rate"
                        onChange={handleBadgeState}
                      />
                      <label
                        className={`btn ${
                          leaseOption.rate === labelName ? "active" : "none"
                        }`}
                        htmlFor={labelName}
                      >
                        {labelName}%
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <span className="option-title">이용 기간</span>
                <div className="filter-badge-box">
                  {leaseFilter.period.map(({ labelName }, index) => (
                    <div className="filter-badge" key={index}>
                      <input
                        autoFocus
                        id={labelName}
                        value={labelName}
                        type="radio"
                        name="period"
                        onChange={handleBadgeState}
                      />
                      <label
                        className={`btn ${
                          leaseOption.period === labelName ? "active" : "none"
                        }`}
                        htmlFor={labelName}
                      >
                        {labelName}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <button className={`sc-button`}>
                <span>조회하기</span>
              </button>
            </form>
          </section>
        </div>
      </div>
    </>
  );
}
