import React from "react";
import HomeBanner from "./screens/HomeBanner";
import HomeBenefits from "./screens/HomeBenefits";
import HomeCreditLoan from "./screens/HomeCreditLoan";
import HomePhone from "./screens/HomePhone";
import Alert from "./screens/Alert";

function Main() {
  let url = window.location.origin;
  const domain = url.includes("e-denden.com");
  return (
    <div className="main">
      <HomeBanner />
      <HomeCreditLoan />
      <HomePhone />
      <HomeBenefits domain={domain} />
      <Alert />
    </div>
  );
}

export default Main;
