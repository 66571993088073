import { Link, Route, Switch, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "../slick.css";
import "../slick-theme.css";
import CarDetail from "components/koscom/installmentOrLease/screens/carList/CarDetail";
import CarList from "components/koscom/installmentOrLease/screens/carList/CarList";
import ReplayIcon from "assets/koscom/replay.png";
import { koscom } from "components/koscom/koscom.js";

export default function CarInquire({
  match,
  isLease,
  location,
  setIsSearch,
  clearFilterOption,
}) {
  const [istmResult, setIstmResult] = useState("");
  const [leaseResult, setLeaseResult] = useState("");
  const filter_inst = JSON.parse(window.sessionStorage.getItem("installment"));
  const filter_lease = JSON.parse(window.sessionStorage.getItem("lease"));

  let pathname = useLocation().pathname;

  useEffect(() => {
    if (filter_inst !== null) {
      setIstmResult(filter_inst);
    }
    if (filter_lease !== null) {
      setLeaseResult(filter_lease);
    }
  }, []);

  return (
    <>
      <div
        className="result-filter"
        style={{
          boxShadow:
            pathname.includes("detail") && "0px 4px 4px rgb(0 0 0 / 5%)",
        }}
      >
        <Link
          className="filter-icon"
          to="/installment-or-lease"
          onClick={() => {
            setIsSearch((prevState) => ({
              ...prevState,
              [`${isLease ? "lease" : "istm"}`]: false,
            }));
            clearFilterOption();
            window.sessionStorage.removeItem(
              `${isLease ? "lease" : "installment"}`
            );
          }}
        >
          <span>다시조회</span>
          <img src={ReplayIcon} alt="filter" />
        </Link>
        {isLease ? (
          <LeaseBadges
            settings={settings}
            istmResult={istmResult}
            leaseResult={leaseResult}
          />
        ) : (
          <InstallmentBadges
            settings={settings}
            istmResult={istmResult}
            leaseResult={leaseResult}
          />
        )}
      </div>
      <Switch>
        <Route
          exact
          path={`${match.path}/list`}
          component={() =>
            isLease ? (
              <CarList
                filter={leaseResult}
                data={koscom.lease}
                isLease={isLease}
              />
            ) : (
              <CarList
                filter={istmResult}
                data={koscom.installment}
                isLease={isLease}
              />
            )
          }
        />
        <Route
          path={`${match.path}/list/*`}
          component={() => <CarDetail location={location} isLease={isLease} />}
        />
      </Switch>
    </>
  );
}

const InstallmentBadges = ({ settings, istmResult }) => (
  <Slider {...settings}>
    <div className="badge">{istmResult.condition}</div>

    <div className="badge">
      <span>
        {istmResult.manufacturer !== "" ? istmResult.manufacturer : "전체 차량"}
      </span>
      {istmResult.type !== "" && <span> {istmResult.type}</span>}
      {istmResult.model !== "" && <span> {istmResult.model}</span>}
    </div>

    {istmResult.rate !== "" && (
      <div className="badge">현금구매비율 / {istmResult.rate}%</div>
    )}
    {istmResult.evaluator !== "" && (
      <div className="badge">
        <span>{istmResult.evaluator}</span>{" "}
        {istmResult.creditScore !== "" && (
          <span> {istmResult.creditScore}</span>
        )}
      </div>
    )}
    {istmResult.period !== "" && (
      <div className="badge">대출기간 / {istmResult.period}</div>
    )}
  </Slider>
);

const LeaseBadges = ({ settings, leaseResult }) => (
  <Slider {...settings}>
    <div className="badge">{leaseResult.condition}</div>
    <div className="badge">
      <span>
        {leaseResult.manufacturer !== ""
          ? leaseResult.manufacturer
          : "전체 차량"}
      </span>
      {leaseResult.type !== "" && <span> {leaseResult.type}</span>}
      {leaseResult.model !== "" && <span> {leaseResult.model}</span>}
    </div>
    {leaseResult.rate !== "" && (
      <div className="badge">현금구매비율 / {leaseResult.rate}%</div>
    )}
    {leaseResult.period !== "" && (
      <div className="badge">이용기간 / {leaseResult.period}</div>
    )}
  </Slider>
);

const settings = {
  dots: false,
  infinite: false,
  autoplay: false,
  slidesToScroll: 1,
  slidesToShow: 2,
  className: "badges",
  draggable: true,
  variableWidth: true,
  arrows: false,
};
