import { useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import "./LoanList.scss";

function RecommendedProduct({
  data,
  lowestInterest,
  greatestAmount,
  title,
  how,
}) {
  const lowestObj = data.find((item) => item.interest === lowestInterest);
  const lowestIndex = data.findIndex(
    (item) => item.interest === lowestInterest
  );
  const greatestObj = data.find((item) => item.amount === greatestAmount);
  const greatestIndex = data.findIndex(
    (item) => item.amount === greatestAmount
  );

  const [showSingle, setShowSingle] = useState(false);
  useEffect(() => {
    lowestIndex === greatestIndex ? setShowSingle(true) : setShowSingle(false);
  }, [data]);

  return (
    <>
      <>
        <h2>{showSingle ? "최저금리 / 최대한도" : "최저금리"}</h2>

        <ProductCard
          lowest={true}
          title={title}
          greatest={false}
          showSingle={showSingle}
          layoutCode={lowestObj.product_name + "reco"} // name이 한글 변수여서 추후 변경 필요
          bankName={lowestObj.bank_name}
          productName={lowestObj.product_name}
          interest={lowestObj.interest}
          amount={lowestObj.amount}
          productCode={lowestObj.product_code}
          sequence={lowestObj.sequence}
          data={data}
          url={lowestObj.url}
          how={how}
        />
      </>
      {!showSingle && (
        <>
          <h2>최대한도</h2>
          <ProductCard
            lowest={false}
            title={title}
            greatest={true}
            showSingle={showSingle}
            layoutCode={greatestObj.product_name + "reco"}
            bankName={greatestObj.bank_name}
            productName={greatestObj.product_name}
            interest={greatestObj.interest}
            amount={greatestObj.amount}
            productCode={greatestObj.product_code}
            sequence={greatestObj.sequence}
            data={data}
            url={greatestObj.url}
            how={how}
          />
        </>
      )}
    </>
  );
}

export default RecommendedProduct;
