import React, { useState } from "react";
import styleVariables from "assets/scss/_variables.scss";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/atom";

function NHMbrDs({ register, errors, setValue, getValues, setUserInfo }) {
  const getUserInfo = useRecoilValue(userInfoState);
  const [openOthers, setOpenOthers] = useState(false);
  const hitInputValue = getValues("health_insurance_type");
  const hiTypeArray = [
    {
      id: "0",
      labelName: "자격없음",
    },
    {
      id: "2",
      labelName: "직장피부양자",
    },
    {
      id: "4",
      labelName: "지역세대원",
    },
    {
      id: "5",
      labelName: "임의계속가입자",
    },
    {
      id: "6",
      labelName: "임의계속피부양자",
    },
  ];

  function SelectHIType({ id, labelName, register }) {
    const onClick = (event) => {
      if (event.target.value === "1" || event.target.value === "3") {
        setOpenOthers(false);
      }
      setUserInfo((prevState) => ({
        ...prevState,
        health_insurance_type: event.target.value,
      }));
    };

    const hiTypeButton = {
      backgroundColor: hitInputValue === id ? styleVariables.subColor : "",
      color: hitInputValue === id ? styleVariables.mainColor : "",
      fontWeight: hitInputValue === id ? "bold" : "",
      border: hitInputValue === id ? "none" : "solid 1px #ccc",
    };

    return (
      <div className="select-item">
        <input
          {...register("health_insurance_type", {
            required: "가입자 구분은 필수 항목입니다",
            // onChange: handleChangeValue,
            onChange: (event) => onClick(event),
          })}
          id={`가입자구분-${labelName}`}
          type="radio"
          name="health_insurance_type"
          className="hidden"
          value={id}
          checked={hitInputValue === id}
        />
        <label
          // onClick={() => onClick(id)}
          style={hiTypeButton}
          htmlFor={`가입자구분-${labelName}`}
        >
          {labelName}
        </label>
      </div>
    );
  }

  function ToggleOthers() {
    const otherClick = () => {
      setValue("health_insurance_type", "");
      setUserInfo((prevState) => ({
        ...prevState,
        [`health_insurance_type`]: "",
      }));
      setOpenOthers((prev) => !prev);
    };
    return (
      <div className="select-item">
        <label
          onClick={() => otherClick()}
          style={{
            backgroundColor: openOthers ? styleVariables.subColor : "",
            color: openOthers ? styleVariables.mainColor : "",
            fontWeight: openOthers ? "bold" : "",
            border: openOthers ? "none" : "solid 1px #ccc",
          }}
        >
          기타
        </label>
      </div>
    );
  }

  return (
    <>
      {getUserInfo.employment_type === "4" ? null : (
        <>
          <section className="select-input-box">
            <p className="select-input-title">
              가입자구분
              {errors?.health_insurance_type ? (
                <span>{errors?.health_insurance_type?.message}</span>
              ) : null}
            </p>
            <div className="select-items">
              <SelectHIType id="1" labelName="직장가입자" register={register} />
              <SelectHIType id="3" labelName="지역세대주" register={register} />
              <ToggleOthers />
            </div>
          </section>
          {openOthers ? (
            <section className="select-input-box">
              <p className="select-input-title">기타 가입자구분</p>
              <div className="select-items">
                {hiTypeArray.map(({ id, labelName }) => (
                  <SelectHIType
                    id={id}
                    labelName={labelName}
                    key={id}
                    register={register}
                  />
                ))}
              </div>
            </section>
          ) : null}
        </>
      )}
    </>
  );
}

export default NHMbrDs;
