export default function Period({
  istmOption,
  installmentFilter,
  handleBadgeState,
}) {
  return (
    <div>
      <p className="option-title">대출 기간</p>
      <div className="filter-badge-box">
        {installmentFilter.period.map(({ id, labelName }, index) => (
          <div className="filter-badge" key={index}>
            <input
              autoFocus
              id={labelName}
              value={id}
              type="radio"
              name="period"
              onChange={handleBadgeState}
            />
            <label
              className={`btn ${
                istmOption.period === labelName ? "active" : "none"
              }`}
              htmlFor={labelName}
            >
              {labelName}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
