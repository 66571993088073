import React from "react";
import { Link } from "react-router-dom";
// import Error from "assets/error/page404.png";
import "./Preparing.scss";
import { useRecoilValue } from "recoil";
import * as state from "recoil/atom";
import { cdn_url } from "utils/api/_utils";
const Error = `${cdn_url}/assets/error/page404.png`;

export default function Page404() {
  const windowSize = useRecoilValue(state.windowSizeAtom);

  return (
    <div className="preparing-container">
      <div className="preparing-content-box">
        <img src={Error} className="preparing-img" alt="ready" />
        {/* <div className="preparing-content"> */}
        {windowSize.width > 758 ? (
          <p className="preparing-content-sub">
            <br />
            찾을 수 없는 페이지 입니다.
            <br />
            요청하신 페이지가 사라졌거나, 잘못된 경로를 이용하셨습니다.
          </p>
        ) : (
          <p className="preparing-content-sub">
            <br />
            찾을 수 없는 페이지 입니다.
            <br />
            요청하신 페이지가 사라졌거나,
            <br />
            잘못된 경로를 이용하셨습니다.
          </p>
        )}
        <Link to="/" className="go-to-main-btn">
          메인화면
        </Link>
      </div>
    </div>
    // </div>
  );
}
