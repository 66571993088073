import "./HomeBanner.scss";
// import mainBackground from "assets/main/1_mainBackground_low.png";
// import edenText from "assets/main/1_e든.png";
import { cdn_url } from "utils/api/_utils";

import { useRecoilValue } from "recoil";
import * as state from "recoil/atom";
const mainBackground = `${cdn_url}/assets/main/1_mainBackground_low.png`;
const edenText = `${cdn_url}/assets/main/1_e든.png`;

function HomeBanner() {
  const windowSize = useRecoilValue(state.windowSizeAtom);
  return (
    <>
      <main
        className="homebanner-container"
        style={{ backgroundImage: `url(${mainBackground})` }}
      >
        <section className="homebanner-wrapper">
          <div className="eden-intro">
            <img className="eden-logo" src={edenText} alt="e든" />
            <div className="intro-textbox">
              <h5>
                이 상품의 금리가 저 상품과는 달라{" "}
                {windowSize.width <= 758 ? <br /> : ""}
                이해로서 서로 맞지 아니하므로,
                <br />
                이런 까닭으로 금리를 모르는 이들이{" "}
                {windowSize.width <= 758 ? <br /> : ""}
                금융 혜택을 받고자 하는 바 있어도
                <br />
                마침내 제 이익을 능히 얻지 못할{" "}
                {windowSize.width <= 758 ? <br /> : ""}
                사람이 많으니라.
                <br />
                이든든이 이를 위하여,
              </h5>
              <h5>
                고충을 줄이고자 금융사를 한 곳에 모으니
                <br />
                사람마다 쉽게 익혀 사용함에
                <br />
                싱글벙글 웃음꽃이 피는구나
              </h5>
              <p>훈민금융 이든본, 2022, 현대한국어판</p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default HomeBanner;
