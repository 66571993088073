// 한글 정규식
export function regexKor(value) {
  value = value.replace(/[^ㄱ-ㅎ|가-힣]/g, "");
  return value.replace(/[^ㄱ-ㅎ|가-힣]$/);
}

// 휴대폰번호 정규식
export function regexPhone(value) {
  value = value.replace(/[^0-9]/g, "");
  return value.replace(/(\d{3})(\d{3,4})(\d{4})/, "$1-$2-$3");
}

// 숫자 정규식
export function regexNum(value) {
  return value.replace(/[^0-9]/g, "");
  // return value.replace(/^[0-9]$/);
}

// 연월일 정규식
export function regexDate(value) {
  value = value.replace(/[^0-9]/g, "");
  return value.replace(/(\d{4})(\d{2})(\d{2})/, "$1.$2.$3");
}

// 숫자 정규식 + 마스킹 처리 *
export function regexBackNum(value) {
  return value.replace(/[^0-9|*]/g, "");
}

// 사업자번호 정규식
export function regexBusinessNum(value) {
  value = value.replace(/[^0-9]/g, "");
  return value.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
}

// 차량번호 정규식
export function regexPlateNum(value) {
  value = value.replace(/[^0-9|ㄱ-ㅎ|가-힣| ]/g, "");
  return value.replace(/([0-9]{2,3})-?([가-힣]{1})-?([0-9]{4})/, "$1$2 $3");
}

// export function maskingBack(value) {
//   return value.replace(/(?<=.{1})([0-9]{1})([0-9]{1})\b/, "*$2");
//   // return value.replace(/(-?)([1-4]{1})([0-9]{6})\b/gi, "$1$2******");
// }

// 특수문자 제거 정규식
export function regexExp(value) {
  // eslint-disable-next-line no-useless-escape
  var reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
  return value.replace(reg, "");
}

// 공백 + 특수문자 제거 정규식
export function regexSpace(value) {
  value = regexExp(value);
  return value.replace(/ /gi, "");
}
