import { AnimatePresence, motion } from "framer-motion";
// import logo from "assets/eden-financial-logo-mobile.svg";
import { useRecoilValue } from "recoil";
import * as state from "recoil/atom";
import { useEffect } from "react";
import { cdn_url } from "utils/api/_utils";
const logo = `${cdn_url}/assets/eden-financial-logo-mobile.svg`;

function LicenseModal({ showLicenseModal, setShowLicenseModal }) {
  const windowSize = useRecoilValue(state.windowSizeAtom);

  useEffect(() => {
    if (showLicenseModal.show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showLicenseModal]);

  const closeLicense = () => {
    setShowLicenseModal((prevState) => ({
      ...prevState,
      [`show`]: false,
    }));
  };

  return (
    <AnimatePresence>
      {showLicenseModal.show && (
        <motion.section
          key="license-modal"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          style={{ height: window.innerHeight }}
          className="license-modal-container"
        >
          <motion.div
            initial={{ opcaity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "tween" }}
            style={{
              height: windowSize.width <= 758 && window.innerHeight,
              maxHeight: windowSize.width > 758 && "none",
            }}
            className="modal-box"
          >
            <div className="modal-header-logo">
              <img src={logo} alt="이든 파이낸셜" />
            </div>
            <div className="modal-header">
              <h2 className="modal-header-h2">
                {showLicenseModal.title}
                {/* 이든파이낸셜 대출모집인 등록증 */}
              </h2>
            </div>
            <div className="modal-body">
              <img
                src={require(`assets/license/license${showLicenseModal.id}.png`)}
                alt="hi"
                className="LicenseImg"
              />
            </div>
            <div onClick={closeLicense} className="modal-footer">
              <button>닫기</button>
            </div>
          </motion.div>
        </motion.section>
      )}
    </AnimatePresence>
  );
}

export default LicenseModal;
