export default function UsedFilter({
  data,
  istmOption,
  setIstmOption,
  handleBadgeState,
}) {
  const handleCreditScoreState = (event) => {
    if (istmOption.evaluator === "") {
      alert("신용 평가사를 먼저 선택해주세요.");
      event.target.blur();
    } else {
      setIstmOption((prevState) => ({
        ...prevState,
        [`creditScore`]: event.target.value,
      }));
    }
  };
  return (
    <>
      <div>
        <p className="option-title">신용 평가사</p>
        <div
          className="filter-badge-box"
          style={{ gridTemplateColumns: "repeat(2, 1fr)" }}
        >
          {data.evaluator.map(({ id, labelName }, index) => (
            <div className="filter-badge" key={index}>
              <input
                autoFocus
                id={labelName}
                value={id}
                type="radio"
                name="evaluator"
                onClick={handleBadgeState}
              />
              <label
                className={`btn ${
                  istmOption.evaluator === labelName ? "active" : "none"
                }`}
                htmlFor={labelName}
              >
                {labelName}
              </label>
            </div>
          ))}
        </div>
      </div>
      <section className="text-input-box">
        <p
          className="text-input-title"
          style={{ fontSize: "18px", fontWeight: "bold" }}
        >
          신용 점수
        </p>
        <div
          className="text-input-item"
          style={{ flexDirection: "row", alignItems: "flex-end" }}
        >
          <input
            inputMode="numeric"
            autoComplete="off"
            autoCorrect="off"
            placeholder="신용점수"
            name="creditScore"
            maxLength={3}
            onChange={handleCreditScoreState}
            value={istmOption.creditScore}
          />
          <span>점</span>
        </div>
      </section>
    </>
  );
}
