import "./HomeCreditLoan.scss";
// import bigPhone from "assets/main/2_bigPhone.png";
import LinkDomain from "../../error/LinkDomain";
import { cdn_url } from "utils/api/_utils";
const bigPhone = `${cdn_url}/assets/main/2_bigPhone.png`;

function HomeCreditLoan() {
  return (
    <section className="homecreditloan-container">
      <div className="home-creditloan-wrapper">
        <div className="textbox">
          <h3>e든든과</h3>
          <h1>
            더 나은 일상을 위해
            <br />
            금융과 IT가 만나다!
          </h1>
          <h5>모두가 바라던 바로 그 내용을 한눈에!</h5>
          {/* <Link to="/credit-loan-main">신용대출 한도 간편조회</Link> */}
          {/* <LinkDomain
            path={`${"/credit-loan-main"}`}
            title={"신용대출 한도 간편조회"}
          /> */}
        </div>
        <div className="imagebox">
          <img src={bigPhone} alt="간편 조회" />
        </div>
      </div>
    </section>
  );
}

export default HomeCreditLoan;
