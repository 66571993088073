import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Logo from "assets/eden-financial-logo.svg";
// import LogoMobile from "assets/eden-financial-logo-mobile.svg";
import "./Header.scss";
import { useResetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import * as state from "recoil/atom";
import {
  AnimatePresence,
  motion,
  useAnimation,
  useViewportScroll,
} from "framer-motion";
import { MdMenu, MdClose } from "react-icons/md";
import { useWindowSize } from "useWindowSize";
import NaviMenus from "./screens/NaviMenus";
import MobileSiteMenus from "./screens/MobileSiteMenus";
// import CertifyModal from "./screens/CertifyModal";
import LicenseModal from "../footer/screens/LicenseModal";
import { cdn_url } from "utils/api/_utils";
const Logo = `${cdn_url}/assets/eden-financial-logo.svg`;
const LogoMobile = `${cdn_url}/assets/eden-financial-logo-mobile.svg`;

export default function Header() {
  // 아톰 호출
  useWindowSize();
  const windowSize = useRecoilValue(state.windowSizeAtom);
  const [mobileNavi, setMobileNavi] = useRecoilState(state.mobileNaviState);
  const resetUserInfo = useResetRecoilState(state.userInfoState);
  const resetRevistCode = useResetRecoilState(state.revisitCodeState);

  // 이든 대출모집인증서 모달
  const [showLicenseModal, setShowLicenseModal] = useState(false);

  // 애니메이션 정의
  const headerAnimation = useAnimation();
  const mobileHeaderAnimation = useAnimation();
  const { scrollY } = useViewportScroll();

  //최초 접속시 모달창 생성
  let url = window.location.origin;
  const domain = url.includes("e-denden.com") || url.includes("localhost");
  const [showModal, setShowModal] = useState(false);

  // 인증모달 활성화시 하단 컴포넌트 스크롤 막기
  // useEffect(() => {
  //   if (showModal === true) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }
  // }, [showModal]);

  // 최초 접속 시 애니메이션
  useEffect(() => {
    if (domain === false) {
      setShowModal(true);
    }
    if (scrollY.get() > 1) {
      headerAnimation.start({
        filter: "drop-shadow(0 0 10px rgba(0, 0, 0, 0.2))",
        height: "70px",
      });
      mobileHeaderAnimation.start({
        filter: "drop-shadow(0 0 10px rgba(0, 0, 0, 0.2))",
      });
    }
  }, []);

  // 스크롤 이동 시 애니메이션
  useEffect(
    () =>
      scrollY.onChange(() => {
        if (scrollY.get() < 1) {
          headerAnimation.start({
            filter: "drop-shadow(0 0 10px rgba(0, 0, 0, 0))",
            height: "100px",
          });
          mobileHeaderAnimation.start({
            filter: "drop-shadow(0 0 10px rgba(0, 0, 0, 0))",
          });
        } else if (scrollY.get() > 1) {
          headerAnimation.start({
            filter: "drop-shadow(0 0 10px rgba(0, 0, 0, 0.2))",
            height: "70px",
          });
          mobileHeaderAnimation.start({
            filter: "drop-shadow(0 0 10px rgba(0, 0, 0, 0.2))",
          });
        }
      }),
    [scrollY]
  );

  // 최초 접속 시 애니메이션. 현재 height이 깜박이는 이슈가 있습니다.
  useEffect(() => {
    const header = document.getElementsByClassName("header-container")[0];
    if (windowSize.width <= 758) {
      header.style.height = "56px";
      header.style.filter = "none";
    }
  }, [windowSize.width]);

  // 아톰 상태 초기화 (사용자 인풋)
  const resetAtomState = () => {
    resetUserInfo();
    resetRevistCode();
    window.sessionStorage.clear(); // sessionStorage Clear
  };

  const closeMobileNavi = () => {
    setMobileNavi(false);
    document.body.style.overflow = "unset";
  };

  return (
    <>
      {/* <CertifyModal
        showModal={showModal}
        setShowModal={setShowModal}
        windowSize={windowSize}
        LogoMobile={LogoMobile}
      /> */}
      <LicenseModal
        showLicenseModal={showLicenseModal}
        setShowLicenseModal={setShowLicenseModal}
      />
      <AnimatePresence>
        {mobileNavi && (
          <>
            <motion.div
              key="modal-navi"
              initial={{ x: "66.67%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "66.67%", opacity: 0 }}
              transition={{ type: "tween", duration: 0.15 }}
              className="mobile-navi-menus-container"
              style={{ height: window.innerHeight + 1 }}
            >
              <div className="header">
                <MdClose onClick={closeMobileNavi} />
              </div>
              <div className="column-menus">
                <NaviMenus
                  closeMobileNavi={closeMobileNavi}
                  mobileNavi={mobileNavi}
                  setMobileNavi={setMobileNavi}
                  resetAtomState={resetAtomState}
                />
                <MobileSiteMenus
                  setShowLicenseModal={setShowLicenseModal}
                  mobileNavi={mobileNavi}
                  setMobileNavi={setMobileNavi}
                  closeMobileNavi={closeMobileNavi}
                />
              </div>
            </motion.div>
            <div className="mobile-navi-background" onClick={closeMobileNavi} />
          </>
        )}
      </AnimatePresence>
      <motion.div
        animate={
          windowSize.width > 758 ? headerAnimation : mobileHeaderAnimation
        }
        transition={{ type: "tween", duration: 0.1 }}
        className="header-container"
        style={{
          backgroundColor: "rgba(255,255,255,1)",
        }}
      >
        <div className="header-wrapper">
          <Link to="/">
            <img
              src={windowSize.width > 758 ? Logo : LogoMobile}
              onClick={() => resetAtomState()}
              alt="이든든"
            />
          </Link>
          <div className="navigation">
            {windowSize.width > 758 ? (
              <NaviMenus
                resetAtomState={resetAtomState}
                closeMobileNavi={closeMobileNavi}
              />
            ) : (
              <div
                className="navi-menu"
                onClick={() => {
                  setMobileNavi(true);
                  document.body.style.overflow = "hidden";
                }}
              >
                <MdMenu />
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </>
  );
}
