import React, { useState } from "react";
import { useRecoilState } from "recoil";
import * as state from "recoil/atom";
import FillCheck from "assets/creditLoan/fill-check.png";
import NoneCheck from "assets/creditLoan/none-check.png";
import ExpandBtn from "assets/creditLoan/expand_button.png";
import ContractBtn from "assets/creditLoan/contract_button.png";

export default function TermsContent({
  label,
  title,
  subTerms,
  setTermsModalShown,
  agreeEachCheck,
  setAgreeEachCheck,
  activeAccordion,
  setAcctiveAccordion,
  isAllAgree,
  setIsAllAgree,
}) {
  const [userInfo, setUserInfo] = useRecoilState(state.userInfoState);

  // 약관동의
  const [isInitialAgreed] = useState(false);

  // 약관 아코디언
  const [isInitActiveAccordion] = useState(false);

  // 각 동의서
  const switchEachAgree = async (key) => {
    console.log(key);
    let tmpObj = { ...agreeEachCheck };

    if (!isInitialAgreed) {
      tmpObj[key] = !tmpObj[key];
    } else {
      for (let tmpObjKey in tmpObj) {
        tmpObj[tmpObjKey] = true;
      }
    }
    setAgreeEachCheck(tmpObj);
    console.log(tmpObj);

    const ele = Object.values(tmpObj).every((ele) => {
      return ele;
    });

    console.log(ele);
    ele ? setIsAllAgree(true) : setIsAllAgree(false);
    setUserInfo((prevState) => ({
      ...prevState,
      [`user_agreement`]: ele ? "1" : "0",
    }));
  };

  // 각 아코디언
  const switchAccordion = (key) => {
    let tmpObj = { ...activeAccordion };

    if (!isInitActiveAccordion) {
      tmpObj[key] = !tmpObj[key];
    } else {
      for (let key in tmpObj) {
        tmpObj[key] = true;
      }
    }
    setAcctiveAccordion(tmpObj);
    console.log("아코디언", tmpObj);
  };

  // 각 아코디언 펼쳤을때 height
  const accordionHeight = () => {
    switch (true) {
      case subTerms[0].subHeading === "":
        return 36;
      case subTerms.length >= 1:
        return 62 * subTerms.length;
      default:
    }
  };

  return (
    <div className="terms-line">
      <div className="terms-title">
        <img
          src={
            userInfo.user_agreement === "1"
              ? FillCheck
              : agreeEachCheck[label] === false
              ? NoneCheck
              : FillCheck
          }
          // agreeEachCheck[label] === false ? NoneCheck : FillCheck}
          className="each-agree-check"
          alt="check"
          onClick={() => {
            switchEachAgree(label);
          }}
        />
        <div className="title-span">
          <span
            onClick={() => {
              switchAccordion(label);
            }}
          >
            {title}
          </span>
          <div
            className="terms-parentRef"
            style={{
              height: `${
                activeAccordion[label] === false ? 0 : `${accordionHeight()}px`
              }`,
            }}
          >
            {subTerms.map(({ heading, subHeading, status }, idx) => (
              <div
                className="subTerms"
                key={idx}
                onClick={() => {
                  setTermsModalShown(() => ({
                    status: true,
                    heading: heading,
                    label: label,
                  }));
                }}
              >
                <span>{heading}</span>
                <span>{subHeading}</span>
              </div>
            ))}
          </div>
        </div>
        <img
          src={activeAccordion[label] === false ? ExpandBtn : ContractBtn}
          alt="expand"
          onClick={() => {
            switchAccordion(label);
          }}
        />
      </div>
    </div>
  );
}
