// 할부, 리스
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { installmentFilter } from "components/koscom/koscom.js";
import FilterSelectStyle from "components/styledComponent/FilterSelectStyle.jsx";
import UsedFilter from "../filter/UsedFilter.jsx";
import CarSelelct from "../filter/CarSelect.jsx";
import Period from "../filter/Period.jsx";

export default function InstallmentFilter() {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [istmOption, setIstmOption] = useState({
    condition: "국산 신차",
    manufacturer: "",
    type: "",
    model: "",
    rate: "",
    period: "",
    creditScore: "",
    evaluator: "",
  });

  const [openOption, setOpenOption] = useState("");
  const [optionState, setOptionState] = useState("국산 신차");
  const lease_session = JSON.parse(window.sessionStorage.getItem("lease"));

  const switchOrder = (event) => {
    const value = event.target.parentNode.id;
    setIstmOption((prevState) => ({
      ...prevState,
      [`${value}`]: event.target.id,
    }));
  };

  const handleBadgeState = (event) => {
    setIstmOption((prevState) => ({
      ...prevState,
      [`${event.target.name}`]: event.target.id,
    }));
  };

  const onFormValid = (data) => {
    history.push("/installment/list", {
      istmOption: istmOption,
    });
    window.sessionStorage.setItem("installment", JSON.stringify(istmOption));
  };

  return (
    <>
      <div className="loanable-list-container">
        <div className="loanable-list-outer">
          <div className="outer-textbox">
            <p>할부 / 리스</p>
          </div>
        </div>
        <div className="loanable-list-inner">
          <section className="installment-lease-container">
            <div className="select-loanway">
              <Link to="/installment" className="active">
                할부
              </Link>
              <Link to={`/lease${lease_session === null ? "" : "/list"}`}>
                리스
              </Link>
            </div>
            <form
              className="filter-select-container"
              onSubmit={handleSubmit(onFormValid)}
            >
              <div style={{ zIndex: "4" }}>
                <p className="option-title">상품</p>
                <FilterSelectStyle
                  selectId="condition"
                  optionArr={installmentFilter.condition}
                  openOption={openOption}
                  setOpenOption={setOpenOption}
                  changeOptionState={switchOrder}
                  carOption={istmOption}
                  state="0"
                  modelNull={false}
                />
              </div>
              {istmOption.condition === "국산 중고차" ||
              istmOption.condition === "수입 중고차" ? (
                <UsedFilter
                  data={installmentFilter}
                  istmOption={istmOption}
                  setIstmOption={setIstmOption}
                  handleBadgeState={handleBadgeState}
                />
              ) : (
                <>
                  <CarSelelct
                    optionState={optionState}
                    data={installmentFilter}
                    filter={istmOption}
                    openOption={openOption}
                    setOpenOption={setOpenOption}
                    switchOrder={switchOrder}
                    isLease={false}
                  />
                  <div>
                    <p className="option-title">현금 구매비율</p>
                    <div className="filter-badge-box">
                      {installmentFilter.rate.map(({ labelName }, index) => (
                        <div className="filter-badge" key={index}>
                          <input
                            autoFocus
                            id={labelName}
                            value={labelName}
                            onChange={handleBadgeState}
                            type="radio"
                            name="rate"
                          />
                          <label
                            className={`btn ${
                              istmOption.rate === labelName ? "active" : "none"
                            }`}
                            htmlFor={labelName}
                          >
                            {labelName}%
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
              <Period
                istmOption={istmOption}
                installmentFilter={installmentFilter}
                handleBadgeState={handleBadgeState}
              />
              <button className={`sc-button`}>
                <span>조회하기</span>
              </button>
            </form>
          </section>
        </div>
      </div>
    </>
  );
}
