// 할부
import { Route, Switch } from "react-router-dom";
import LeaseFilter from "./screens/lease/LeaseFilter";
import LeaseBranch from "./screens/lease/LeaseBranch";
import Page404 from "components/error/Page404";

export default function Lease({ match }) {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={LeaseFilter} />
        <Route path={`${match.path}/list`} component={LeaseBranch} />
        <Route path={`${match.path}/*`} component={Page404} />
      </Switch>
    </>
  );
}
