import { useEffect } from "react";
import { useState } from "react";
import { numToKorean } from "utils/numToKorean";
import { regexNum } from "utils/regex";

export default function BuildingOthers({
  register,
  errors,
  handleChangeValue,
  houseInfo,
  getValues,
  state,
}) {
  const [optionName, setOptionName] = useState("주택 구매 가격");
  useEffect(() => {
    if (state.purpose === "전세자금") {
      setOptionName("전세 자금");
    }
  }, []);

  return (
    <>
      <section className="text-input-box">
        <p className="text-input-title">
          {optionName}
          {errors?.purchase_price ? (
            <span>{optionName}은 필수항목 입니다</span>
          ) : null}
        </p>
        <div
          className="text-input-item"
          style={{ flexDirection: "row", alignItems: "flex-end" }}
        >
          <input
            {...register("purchase_price", {
              required: true,
              onChange: handleChangeValue,
              pattern: /[0-9]/,
            })}
            inputMode="numeric"
            autoComplete="off"
            autoCorrect="off"
            placeholder={
              state.purpose === "전세자금" ? "전세 자금" : "주택가격"
            }
            name="purchase_price"
            className={`${errors?.purchase_price ? "error" : null}`}
            value={regexNum(houseInfo.purchase_price)}
          />
          <span>만원</span>
        </div>
        {getValues("purchase_price") > 0 && (
          <span className="text-sub-title" id="sub-txt-salary">
            {(getValues("purchase_price") * 10000).toLocaleString("ko-KR")} (
            {numToKorean(getValues("purchase_price") * 10000)}원)
          </span>
        )}
      </section>
    </>
  );
}
