import HouseType from "./house-group/HouseType";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import * as state from "recoil/atom";
import styleVariables from "assets/scss/_variables.scss";

export default function House_Information({ register, errors }) {
  const houseOwnershipType = useRecoilValue(state.selectHouseTypeState);
  const [userInfo, setUserInfo] = useRecoilState(state.userInfoState);

  const houseOwnershipTypepeArray = [
    {
      id: "1",
      labelName: "자가",
    },
    {
      id: "2",
      labelName: "전세",
    },
    {
      id: "3",
      labelName: "전월세",
    },
    {
      id: "4",
      labelName: "월세",
    },
    {
      id: "5",
      labelName: "배우자",
    },
    {
      id: "6",
      labelName: "기타가족소유",
    },
  ];

  return (
    <>
      <div className="select-input-box">
        <p className="select-input-title">
          주거소유형태
          {errors?.house_ownership_type ? (
            <span>{errors?.house_ownership_type?.message}</span>
          ) : null}
        </p>
        <div className="select-items">
          {houseOwnershipTypepeArray.map(({ id, labelName }) => (
            <SelectHouseType
              id={id}
              labelName={labelName}
              key={id}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              register={register}
            />
          ))}
        </div>
      </div>
      {houseOwnershipType ? (
        <HouseType register={register} errors={errors} />
      ) : null}
    </>
  );
}

function SelectHouseType({ id, labelName, userInfo, setUserInfo, register }) {
  const setHouseType = useSetRecoilState(state.selectHouseTypeState);
  const handleChangeValue = (event) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [`${event.target.name}`]: event.target.value,
    }));
  };

  const changeHouseType = (event) => {
    let houseOwnershipType = event.target.value;
    setUserInfo((prevState) => ({
      ...prevState,
      house_type: "",
      regidence_type: "",
    }));

    // switch (houseType) {
    //   case "자가":
    //   case "전세":
    //   case "전월세":
    //   case "월세":
    //     setHouseType(true);
    //     break;
    //   case "배우자":
    //   case "기타가족소유":
    //     setHouseType(false);
    //     setUserInfo((prevState) => ({
    //       ...prevState,
    //       [`house_type`]: "",
    //     }));
    //     break;
    // }
    switch (houseOwnershipType) {
      case "1":
      case "2":
      case "3":
      case "4":
        setHouseType(true);
        break;
      case "5":
      case "6":
        setHouseType(false);
        setUserInfo((prevState) => ({
          ...prevState,
          [`house_type`]: "",
        }));
        break;
      default:
    }
  };
  return (
    <div className="select-item">
      <input
        {...register("house_ownership_type", {
          required: "주거소유형태는 필수 항목입니다",
          onChange: (event) => {
            changeHouseType(event);
            handleChangeValue(event);
          },
        })}
        id={`주거소유형태-${labelName}`}
        type="radio"
        name="house_ownership_type"
        className="hidden"
        value={id}
        checked={userInfo.house_ownership_type === id}
      />
      <label
        style={{
          backgroundColor:
            userInfo.house_ownership_type === id ? styleVariables.subColor : "",
          color:
            userInfo.house_ownership_type === id
              ? styleVariables.mainColor
              : "",
          fontWeight: userInfo.house_ownership_type === id ? "bold" : "",
          border:
            userInfo.house_ownership_type === id ? "none" : "solid 1px #ccc",
        }}
        htmlFor={`주거소유형태-${labelName}`}
      >
        {labelName}
      </label>
    </div>
  );
}
