import styleVariables from "assets/scss/_variables.scss";

export default function MortageJobInfo({
  register,
  errors,
  handleChangeValue,
  moreInfo,
}) {
  const mortageJobInfoArray = [
    "직장인",
    "공무원",
    "개인사업자",
    "법인",
    "주부",
    "기타",
  ];

  return (
    <section className="select-input-box">
      <p className="select-input-title">
        직군분류
        {errors?.income_type ? <span>직군분류는 필수 항목입니다</span> : null}
      </p>
      <div className="select-items-mortage grid-three">
        {mortageJobInfoArray.map((item) => (
          <div className="select-item" key={item}>
            <input
              {...register("income_type", {
                required: true,
                onChange: handleChangeValue,
              })}
              id={item}
              type="radio"
              name="income_type"
              value={item}
              className="hidden"
              checked={moreInfo.income_type === item}
            />
            <label
              style={{
                backgroundColor:
                  moreInfo.income_type === item
                    ? styleVariables.subColor
                    : "transparent",
                color:
                  moreInfo.income_type === item
                    ? styleVariables.mainColor
                    : "#ccc",
                fontWeight: moreInfo.income_type === item ? "bold" : "",
                border:
                  moreInfo.income_type === item ? "none" : "solid 1px #ccc",
              }}
              htmlFor={item}
            >
              {item}
            </label>
          </div>
        ))}
      </div>
    </section>
  );
}
