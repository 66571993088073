import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import * as state from "recoil/atom";
// import FillCheck from "assets/creditLoan/fill-check.png";
// import NoneCheck from "assets/creditLoan/none-check.png";
import styleVariables from "assets/scss/_variables.scss";
import { regexSpace } from "utils/regex";
import { cdn_url } from "utils/api/_utils";
const FillCheck = `${cdn_url}/assets/creditLoan/fill-check.png`;
const NoneCheck = `${cdn_url}/assets/creditLoan/none-check.png`;

export default function Car_Information({ register, errors }) {
  const [userInfo, setUserInfo] = useRecoilState(state.userInfoState);
  const [isCheck, setIsCheck] = useState(false); // 차량 소유 여부 체크

  const handleChangeValue = (event) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [`${event.target.name}`]: event.target.value,
    }));
    // setValue("plate_number", userInfo.plate_number);
  };

  useEffect(() => {
    if (userInfo.plate_number.length > 1) {
      setIsCheck(true);
    }
  }, [userInfo.plate_number]);

  // console.log(userInfo.plate_number);
  // console.log(getValues("plate_number"));

  return (
    <>
      <div className="select-input-box">
        <p className="select-input-title">소유차량</p>
        <div
          className="big-select-item"
          onClick={() => setIsCheck((prev) => !prev)}
          style={{
            border: isCheck
              ? `solid 1px ${styleVariables.mainColor}`
              : "solid 1px #ccc",
            color: isCheck ? styleVariables.mainColor : "#ccc",
          }}
        >
          <span>
            <img src={isCheck ? FillCheck : NoneCheck} alt="체크" />제 명의의
            차량을 가지고 있어요
          </span>
        </div>
      </div>
      {isCheck ? (
        <div className="text-input-box">
          <p className="text-input-title">
            소유 차량 정보
            {errors?.plate_number && (
              <span>차량 정보를 올바르게 입력해주세요.</span>
            )}
          </p>
          <div className="text-input-item">
            <input
              {...register("plate_number", {
                required: true,
                // pattern: /([0-9]{2,3})+([가-힣]{1})+ ([0-9]{4})$/,
                onChange: handleChangeValue,
              })}
              placeholder="예시: 123가1234"
              autoComplete="off"
              autoCorrect="off"
              name="plate_number"
              className={`car-information-detail-number ${
                errors?.plate_number ? "error" : null
              }`}
              value={regexSpace(userInfo.plate_number)}
              maxLength="8"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
