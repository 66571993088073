import "./HomeBenefits.scss";

// import guyInThePhone from "assets/main/5_guyInThePhone.png";
// import guyInThePhoneMobile from "assets/main/5_guyInThePhone_mobile.png";
// import present from "assets/main/5_present.png";
// import calculator from "assets/main/6_calculator.png";

import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useWindowSize } from "useWindowSize";
import { useRecoilValue } from "recoil";
import { windowSizeAtom } from "recoil/atom";
import LinkDomain from "../../error/LinkDomain";
import { cdn_url } from "utils/api/_utils";

const guyInThePhone = `${cdn_url}/assets/main/5_guyInThePhone.png`;
const guyInThePhoneMobile = `${cdn_url}/assets/main/5_guyInThePhone_mobile.png`;
const present = `${cdn_url}/assets/main/5_present.png`;
const calculator = `${cdn_url}/assets/main/6_calculator.png`;

function HomeBenefits({ domain }) {
  useWindowSize();
  const windowSize = useRecoilValue(windowSizeAtom);

  return (
    <main
      className="eden-benefits-container"
      style={{ paddingTop: domain === false ? "50px" : "200px" }}
    >
      <section className="eden-benefits">
        <div className="textbox">
          <img src={present} alt="선물" />
          <h2>
            합리적인 금융
            <br />
            혜택을 누릴 수 있어요
          </h2>
          <p>
            여러 은행과 금융사의 상품을 모아서
            <br />
            금융소비자에게 가장 적합한 상품을
            <br />
            선택할 수 있게 도와드려요
          </p>
        </div>
        <div className="imagebox">
          <img
            src={windowSize.width > 758 ? guyInThePhone : guyInThePhoneMobile}
            alt="이든으로 혜택을 누릴 수 있어요"
          />
        </div>
      </section>
      <section className="eden-cal-banner">
        <img className="calloan-image" src={calculator} alt="금융계산기" />
        <div className="textbox">
          <h2>
            은행 방문 없이
            <br />
            매월 얼마나 갚아야 {windowSize.width < 758 && <br />}
            하는지 궁금하다면?
          </h2>
          {/* <Link to="/loan-interest-calculator">
            <span>금융계산기 바로가기</span>
            <BiChevronRight />
          </Link> */}
        </div>
      </section>
      <section className="eden-smart-text">
        <h2>
          똑똑한 e든든 서비스
          <br />
          지금 경험해 보세요
        </h2>
        {/* <Link to="/credit-loan-main">신용대출 한도 간편조회</Link> */}
        {/* <LinkDomain
          path={`${"/credit-loan-main"}`}
          title={"신용대출 한도 간편조회"}
        /> */}
      </section>
    </main>
  );
}

export default HomeBenefits;
