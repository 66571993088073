// 담보대출
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { koscom } from "components/koscom/koscom.js";

import RecommendedProduct from "components/creditLoan/loanList/RecommendedProduct";
import ProductCard from "components/creditLoan/loanList/ProductCard";
import { cdn_url } from "utils/api/_utils.js";
import SelectStyle from "components/styledComponent/SelectStyle";

export default function LoanList() {
  const [openOption, setOpenOption] = useState(false);
  const [optionState, setOptionState] = useState("낮은금리순");
  const optionArr = { value: ["낮은금리순", "높은한도순"] };

  useEffect(() => {
    const items = [...dataCopy];
    items.sort((a, b) => {
      return b.amount - a.amount;
    });
    items.sort((a, b) => {
      return a.interest - b.interest;
    });
    setDataCopy(items);
  }, []);

  const getData = koscom.credit;

  // 받은 데이터를 Number로 변환
  let numberizedData;
  (() => {
    numberizedData = [...getData];
    getData.map(({ interest, amount }, index) => {
      const numberizedInterest = Number(interest);
      const numberizedAmount = Number(amount.split(",").join(""));
      return (numberizedData[index] = {
        ...numberizedData[index],
        interest: numberizedInterest,
        amount: numberizedAmount,
      });
    });
  })();

  // 데이터 복사본을 state로 생성
  const [dataCopy, setDataCopy] = useState(numberizedData);

  // 최저금리 계산
  let lowestInterest;
  (() => {
    let lowest = Number(dataCopy[0].interest);
    dataCopy.map(({ interest: current }) => {
      if (Number(current) < Number(lowest)) {
        return (lowest = Number(current));
      } else {
        return Number(lowest);
      }
    });
    return (lowestInterest = lowest);
  })();

  // 최대한도 계산
  let greatestAmount;
  (() => {
    let greatest = Number(dataCopy[0].amount);
    dataCopy.map(({ amount: current }) => {
      if (Number(current) > Number(greatest)) {
        return (greatest = Number(current));
      } else {
        return Number(greatest);
      }
    });
    return (greatestAmount = greatest);
  })();

  // 셀렉트 변경 핸들링
  const switchOrder = (event) => {
    const value = event.currentTarget.id;
    const items = [...dataCopy];
    if (value === "낮은금리순") {
      items.sort((a, b) => {
        return a.interest - b.interest;
      });
    } else if (value === "높은한도순") {
      items.sort((a, b) => {
        return b.amount - a.amount;
      });
    }
    setOptionState(event.currentTarget.id);
    setDataCopy(items);
  };

  return (
    <div className="loanable-list-container">
      <div className="loanable-list-outer">
        <div className="outer-textbox">
          <p>든든 신용 대출</p>
          <div className="indicator">
            <div className="pills" />
            <div className="pills" />
            <div className="pills active" />
          </div>
        </div>
      </div>

      <div className="loanable-list-inner">
        <section className="heading">
          <h2>{"고객"} 님을</h2>
          <h2>위한 대출정보입니다.</h2>
          <img
            src={`${cdn_url}/assets/creditLoan/loan-list-results.png`}
            alt="신용대출"
          />
        </section>
        <section className="recommended-container">
          <RecommendedProduct
            data={dataCopy}
            lowestInterest={lowestInterest}
            greatestAmount={greatestAmount}
          />
        </section>
        <section className="results-container">
          <motion.div className="results-sorter">
            <div className="sorter-left">
              <h5>
                대출 총 <b>{dataCopy.length}</b>건
              </h5>
            </div>
            {/* <div className="results-select">
              <select onChange={(event) => switchOrder(event)}>
                <option value="낮은금리순">낮은금리순</option>
                <option value="높은한도순">높은한도순</option>
              </select>
              <FaCaretDown />
            </div> */}
            <SelectStyle
              optionState={optionState}
              optionArr={optionArr.value}
              openOption={openOption}
              setOpenOption={setOpenOption}
              changeOptionState={switchOrder}
            />
          </motion.div>
          <div className="results-items">
            {dataCopy.map((item, index) => (
              <ProductCard
                key={index}
                title={"신용대출"}
                lowest={false}
                greatest={false}
                bankName={item.bank_name}
                productName={item.product_name}
                interest={item.interest}
                amount={item.amount}
                productCode={item.product_code}
                sequence={item.sequence}
                data={dataCopy}
                is_progressed={item.is_progressed}
                bridge_url={item.bridge_url}
                PrnlRt={item.PrnlRt}
                ImDpAYN={item.ImDpAYN}
              />
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}
