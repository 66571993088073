import SelectStyle from "components/styledComponent/SelectStyle";
import { useState } from "react";

export default function InstallmentSort({ arr, setArr, optionArr }) {
  const [openOption, setOpenOption] = useState("");
  const [optionState, setOptionState] = useState("최저금리 낮은 순");

  const switchOrder = (event) => {
    const value = event.currentTarget.id;
    const items = [...arr];
    const lowInsterest = () => {
      items.sort((a, b) => {
        return a.lowest_interest - b.lowest_interest;
      });
    };
    const hightInsterest = () => {
      items.sort((a, b) => {
        return a.greatest_interest - b.greatest_interest;
      });
    };
    const lowRelease = () => {
      items.sort((a, b) => {
        return a.release_price - b.release_price;
      });
    };
    const highRelease = () => {
      items.sort((a, b) => {
        return b.release_price - a.release_price;
      });
    };
    if (value === "최저금리 낮은 순") {
      lowRelease();
      lowInsterest();
    } else if (value === "최고금리 낮은 순") {
      lowRelease();
      hightInsterest();
    } else if (value === "출고가 낮은 순") {
      lowInsterest();
      lowRelease();
    } else if (value === "출고가 높은 순") {
      lowInsterest();
      highRelease();
    }
    setOptionState(event.currentTarget.id);
    setArr(items);
  };

  return (
    <SelectStyle
      optionState={optionState}
      optionArr={optionArr.value}
      openOption={openOption}
      setOpenOption={setOpenOption}
      changeOptionState={switchOrder}
    />
  );
}
