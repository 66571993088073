import { motion, AnimatePresence } from "framer-motion";

function Carrier({
  register,
  errors,
  motionVariants,
  userInfo,
  handleChangeValue,
}) {
  const carrierArray = [
    {
      id: "1",
      labelName: "SKT",
    },
    {
      id: "2",
      labelName: "KT",
    },
    {
      id: "3",
      labelName: "LG U+",
    },
    {
      id: "4",
      labelName: "알뜰폰",
    },
  ];

  const SelectCarrier = ({ id, labelName, userInfo }) => {
    return (
      <div className="carrier-item">
        <input
          {...register("carrier", {
            required: "통신사는 필수 항목입니다.",
            onChange: handleChangeValue,
            value: userInfo.carrier ? userInfo.carrier : "",
          })}
          autoFocus
          id={labelName}
          value={id}
          className="carrier"
          type="radio"
          name="carrier"
        />
        <label htmlFor={labelName}>{labelName}</label>
      </div>
    );
  };
  return (
    <AnimatePresence>
      <motion.div
        className="input-container"
        initial="initial"
        animate="animate"
        variants={motionVariants}
      >
        <h3 className="input-title">
          통신사
          {errors?.carrier ? <span>{errors?.carrier?.message}</span> : null}
        </h3>
        <div className="carrier-grid">
          {carrierArray.map(({ id, labelName }, index) => (
            <SelectCarrier
              id={id}
              labelName={labelName}
              key={index}
              userInfo={userInfo}
            />
          ))}
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default Carrier;
