// const bankUrl = "https://d34xp6q6v7q38i.cloudfront.net/images/bank-images";
import { cdn_url } from "utils/api/_utils.js";
export const koscom = {
  credit: [
    {
      bank_name: "웰컴저축은행",
      product_name: "웰컴중금리대출(대환)",
      interest: "9.12",
      amount: "100,000,000",
      // url: bankUrl.Welcome,
      url: "Welcome",
      product_code: "loan1",
    },
    {
      bank_name: "다올저축은행",
      product_name: "직장인대환(나오론V)",
      interest: "8.9",
      amount: "100,000,000",
      // url: bankUrl.Daol,
      url: "Daol",
      product_code: "loan2",
    },
    {
      bank_name: "신한은행",
      product_name: "대환대출",
      interest: "5.68",
      amount: "50,000,000",
      // url: bankUrl.Shinhan,
      url: "Shinhan",
      product_code: "loan3",
    },
    {
      bank_name: "SBI저축은행",
      product_name: "참신한대출(대환)",
      interest: "5.26",
      amount: "13,000,000",
      // url: bankUrl.SBI,
      url: "SBI",
      product_code: "loan4",
    },
  ],
  loan: [
    {
      bank_name: "웰컴저축은행",
      product_name: "웰컴중금리대출(대환)",
      interest: "5.12",
      amount: "90,000,000",
      // url: bankUrl.Welcome,
      url: "Welcome",
      product_code: "loan1",
    },
    {
      bank_name: "다올저축은행",
      product_name: "직장인대환(나오론V)",
      interest: "8.9",
      amount: "100,000,000",
      // url: bankUrl.Daol,
      url: "Daol",
      product_code: "loan2",
    },
    {
      bank_name: "신한은행",
      product_name: "대환대출",
      interest: "5.68",
      amount: "50,000,000",
      // url: bankUrl.Shinhan,
      url: "Shinhan",
      product_code: "loan3",
    },
    {
      bank_name: "SBI저축은행",
      product_name: "참신한대출(대환)",
      interest: "5.26",
      amount: "3,000,000",
      // url: bankUrl.SBI,
      url: "SBI",
      product_code: "loan4",
    },
  ],
  mortage: [
    {
      bank_name: "국민은행",
      product_name: "KB주택담보대출변동 (일반자금)",
      interest: "3.5",
      amount: "60,000,000",
      // url: bankUrl.Hyundai,
      url: "kb",
      product_code: "mortage1",
    },
    {
      bank_name: "데일리펀딩",
      product_name: "아파트 담보대출",
      interest: "3.5",
      amount: "80,000,000",
      // url: bankUrl.Daily,
      url: "Daily",
      product_code: "mortage2",
    },
    {
      bank_name: "우리은행",
      product_name: "우리아파트론",
      interest: "4.4",
      amount: "55,000,000",
      // url: bankUrl.SBI,
      url: "SBI",
      product_code: "mortage3",
    },
    {
      bank_name: "현대캐피탈",
      product_name: "현대캐피탈 주택담보대출",
      interest: "3.8",
      amount: "100,000,000",
      url: "Hyundai",
      product_code: "mortage4",
    },
    {
      bank_name: "IBK기업은행",
      product_name: "IBK주택담보대출",
      interest: "5.3",
      amount: "40,000,000",
      url: "IBK",
      product_code: "mortage5",
    },
    {
      bank_name: "신한은행",
      product_name: "신한주택대출",
      interest: "3.4",
      amount: "55,000,000",
      url: "shinhan",
      product_code: "mortage6",
    },
    {
      bank_name: "오케이저축은행",
      product_name: "OK모기지론",
      interest: "7.9",
      amount: "80,000,000",
      url: "Hyundai",
      product_code: "mortage7",
    },
  ],
  housing_lease: [
    {
      bank_name: "우리은행",
      product_name: "버팀목 전세자금대출(청년)",
      interest: "1.8",
      amount: "45,600,000",
      // url: bankUrl.Woori,
      url: "Woori",
      product_code: "lease1",
    },
    {
      bank_name: "국민은행",
      product_name: "전월세 보증금 대출",
      interest: "3.83",
      amount: "222,000,000",
      // url: bankUrl.KB,
      url: "KB",
      product_code: "lease2",
    },
    {
      bank_name: "카카오뱅크",
      product_name: "전월세 보증금 대출",
      interest: "2.7",
      amount: "10,000,000",
      // url: bankUrl.Kakao,
      url: "Kakao",
      product_code: "lease3",
    },
    {
      bank_name: "SBI저축은행",
      product_name: "전월세 보증금 대출",
      interest: "3.12",
      amount: "10,000,000",
      // url: bankUrl.SBI,
      url: "SBI",
      product_code: "lease4",
    },
  ],
  gov: [
    {
      bank_name: "SBI저축은행",
      product_name: "햇살론(직장인)",
      interest: "5.12",
      amount: "100,000,000",
      // url: bankUrl.SBI,
      url: "SBI",
      product_code: "gov1",
    },
    {
      bank_name: "IBK저축은행",
      product_name: "온라인 햇살론(간편대출)",
      interest: "8.9",
      amount: "87,500,000",
      // url: bankUrl.IBK,
      url: "IBK",
      product_code: "gov2",
    },
    {
      bank_name: "웰컴저축은행",
      product_name: "햇살론",
      interest: "9.12",
      amount: "11,000,000",
      // url: bankUrl.Welcome,
      url: "Welcome",
      product_code: "gov3",
    },
    {
      bank_name: "SBI저축은행",
      product_name: "온라인 햇살론(간편대출)",
      interest: "5.26",
      amount: "3,000,000",
      // url: bankUrl.SBI,
      url: "SBI",
      product_code: "gov4",
    },
  ],
  // installment: [
  //   {
  //     bank_name: "현대캐피탈",
  //     lowest_interest: "2.0",
  //     greatest_interest: "3.6",
  //     url: "Hyundai",
  //     product_code: "installment1",
  //   },
  //   {
  //     bank_name: "국민은행",
  //     lowest_interest: "2.2",
  //     greatest_interest: "4.2",
  //     url: "KB",
  //     product_code: "installment2",
  //   },
  //   {
  //     bank_name: "우리은행",
  //     lowest_interest: "2.6",
  //     greatest_interest: "3.6",
  //     url: "Woori",
  //     product_code: "installment3",
  //   },
  //   {
  //     bank_name: "오케이저축은행",
  //     lowest_interest: "2.6",
  //     greatest_interest: "3.9",
  //     url: "OK",
  //     product_code: "installment4",
  //   },
  // ],
  // lease: [
  //   {
  //     bank_name: "현대캐피탈",
  //     lowest_amount: "281,900",
  //     greatest_amount: "320,900",
  //     url: "Hyundai",
  //     product_code: "lease1",
  //   },
  //   {
  //     bank_name: "국민은행",
  //     lowest_amount: "300,948",
  //     greatest_amount: "410,800",
  //     url: "KB",
  //     product_code: "lease2",
  //   },
  //   {
  //     bank_name: "우리은행",
  //     lowest_amount: "312,948",
  //     greatest_amount: "420,484",
  //     url: "Woori",
  //     product_code: "lease3",
  //   },
  //   {
  //     bank_name: "오케이저축은행",
  //     lowest_amount: "221,900",
  //     greatest_amount: "520,900",
  //     url: "OK",
  //     product_code: "lease4",
  //   },
  // ],
  installment: [
    {
      car_name: "아반떼",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-avante.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-hyundai2.png`,
      manufacturer: "현대",
      release_price: "24250000",
      fuel: "2.0 가솔린",
      lowest_interest: "3.2",
      greatest_interest: "27",
      product_code: "installment2",
      condition: "국산",
      rate: "20",
      type: "세단",
    },
    {
      car_name: "쏘나타",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-sonata.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-hyundai2.png`,
      manufacturer: "현대",
      release_price: "27880000",
      fuel: "2.0 디젤",
      lowest_interest: "4.7",
      greatest_interest: "30",
      product_code: "installment3",
      condition: "국산",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "그랜저",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-grandeur.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-hyundai2.png`,
      manufacturer: "현대",
      release_price: "24250000",
      fuel: "전기",
      lowest_interest: "3.4",
      greatest_interest: "28",
      product_code: "installment4",
      condition: "국산",
      rate: "10",
      type: "세단",
    },
    {
      car_name: "코나",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-kona.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-hyundai2.png`,
      manufacturer: "현대",
      release_price: "27770000",
      fuel: "전기",
      lowest_interest: "3.8",
      greatest_interest: "19",
      product_code: "installment5",
      condition: "국산",
      rate: "20",
      type: "SUV",
    },
    {
      car_name: "베뉴",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-venue.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-hyundai2.png`,
      manufacturer: "현대",
      release_price: "37380000",
      fuel: "전기",
      lowest_interest: "4.7",
      greatest_interest: "22",
      product_code: "installment6",
      condition: "국산",
      rate: "20",
      type: "SUV",
    },
    {
      car_name: "팰리세이드",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-palisade.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-hyundai2.png`,
      manufacturer: "현대",
      release_price: "38670000",
      fuel: "디젤",
      lowest_interest: "4.8",
      greatest_interest: "27",
      product_code: "installment7",
      condition: "국산",
      rate: "0",
      type: "SUV",
    },
    {
      car_name: "모닝",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-morning.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-kia2.png`,
      manufacturer: "기아",
      release_price: "25720000",
      fuel: "디젤",
      lowest_interest: "4.7",
      greatest_interest: "23",
      product_code: "installment8",
      condition: "국산",
      rate: "10",
      type: "세단",
    },
    {
      car_name: "K5",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-k5.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-kia2.png`,
      manufacturer: "기아",
      release_price: "26790000",
      fuel: "가솔린",
      lowest_interest: "3",
      greatest_interest: "21",
      product_code: "installment9",
      condition: "국산",
      rate: "0",
      type: "세단",
    },
    {
      car_name: "K8",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-k8.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-kia2.png`,
      manufacturer: "기아",
      release_price: "28740000",
      fuel: "가솔린",
      lowest_interest: "2.9",
      greatest_interest: "22",
      product_code: "installment9",
      condition: "국산",
      rate: "0",
      type: "세단",
    },
    {
      car_name: "셀토스",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-seltos.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-kia2.png`,
      manufacturer: "기아",
      release_price: "28900000",
      fuel: "가솔린",
      lowest_interest: "2.9",
      greatest_interest: "24",
      product_code: "installment9",
      condition: "국산",
      rate: "0",
      type: "SUV",
    },
    {
      car_name: "스포티지",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-sportage.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-kia2.png`,
      manufacturer: "기아",
      release_price: "32700000",
      fuel: "가솔린",
      lowest_interest: "4.5",
      greatest_interest: "25",
      product_code: "installment9",
      condition: "국산",
      rate: "0",
      type: "SUV",
    },
    {
      car_name: "쏘렌토",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-solento.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-kia2.png`,
      manufacturer: "기아",
      release_price: "29700000",
      fuel: "LPG",
      lowest_interest: "4.5",
      greatest_interest: "22.5",
      product_code: "installment9",
      condition: "국산",
      rate: "0",
      type: "SUV",
    },
    {
      car_name: "SM6",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-sm6.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-renault2.png`,
      manufacturer: "르노삼성",
      release_price: "29700000",
      fuel: "LPG",
      lowest_interest: "4.5",
      greatest_interest: "27",
      product_code: "installment9",
      condition: "국산",
      rate: "0",
      type: "세단",
    },
    {
      car_name: "QM6",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-qm6.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-renault2.png`,
      manufacturer: "르노삼성",
      release_price: "39000000",
      fuel: "LPG",
      lowest_interest: "4.5",
      greatest_interest: "26.8",
      product_code: "installment9",
      condition: "국산",
      rate: "0",
      type: "SUV",
    },
    {
      car_name: "XM3",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-xm3.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-renault2.png`,
      manufacturer: "르노삼성",
      release_price: "48200000",
      fuel: "LPG",
      lowest_interest: "4.5",
      greatest_interest: "28.5",
      product_code: "installment9",
      condition: "국산",
      rate: "0",
      type: "SUV",
    },
    {
      car_name: "3 Series",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-5series.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-bmw2.png`,
      manufacturer: "BMW",
      release_price: "67300000",
      fuel: "디젤",
      lowest_interest: "6.4",
      greatest_interest: "26.4",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "5 Series",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-3series.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-bmw2.png`,
      manufacturer: "BMW",
      release_price: "72900000",
      fuel: "디젤",
      lowest_interest: "6.8",
      greatest_interest: "28.7",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "X3",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-x3.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-bmw2.png`,
      manufacturer: "BMW",
      release_price: "87400000",
      fuel: "디젤",
      lowest_interest: "7.1",
      greatest_interest: "29.6",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
    {
      car_name: "X5",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-x5.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-bmw2.png`,
      manufacturer: "BMW",
      release_price: "86900000",
      fuel: "디젤",
      lowest_interest: "7",
      greatest_interest: "30.8",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
    {
      car_name: "E-Class",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-k8.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-benz.png`,
      manufacturer: "벤츠",
      release_price: "88800000",
      fuel: "디젤",
      lowest_interest: "6.9",
      greatest_interest: "26.5",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "C-Class",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-sm6.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-benz.png`,
      manufacturer: "벤츠",
      release_price: "87400000",
      fuel: "디젤",
      lowest_interest: "6.8",
      greatest_interest: "26.8",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "GLA",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-gla.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-benz.png`,
      manufacturer: "벤츠",
      release_price: "88600000",
      fuel: "디젤",
      lowest_interest: "6.6",
      greatest_interest: "31",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
    {
      car_name: "GLB",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-xm3.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-benz.png`,
      manufacturer: "벤츠",
      release_price: "89900000",
      fuel: "디젤",
      lowest_interest: "6.1",
      greatest_interest: "30",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
    {
      car_name: "S60",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-s60.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-volvo2.png`,
      manufacturer: "볼보",
      release_price: "97400000",
      fuel: "디젤",
      lowest_interest: "6.2",
      greatest_interest: "30.2",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "V60",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-xc90.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-volvo2.png`,
      manufacturer: "볼보",
      release_price: "96600000",
      fuel: "디젤",
      lowest_interest: "5.4",
      greatest_interest: "32.6",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "XC40",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-xc40.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-volvo2.png`,
      manufacturer: "볼보",
      release_price: "98800000",
      fuel: "디젤",
      lowest_interest: "5.1",
      greatest_interest: "31",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
    {
      car_name: "XC60",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-xc60.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-volvo2.png`,
      manufacturer: "볼보",
      release_price: "89400000",
      fuel: "디젤",
      lowest_interest: "7.5",
      greatest_interest: "30.8",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
    {
      car_name: "XC90",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-v60.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-volvo2.png`,
      manufacturer: "볼보",
      release_price: "86900000",
      fuel: "디젤",
      lowest_interest: "7.1",
      greatest_interest: "31",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
    {
      car_name: "A4",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-a4.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-audi.png`,
      manufacturer: "아우디",
      release_price: "92000000",
      fuel: "디젤",
      lowest_interest: "6.8",
      greatest_interest: "30.8",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "A6",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-a6.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-audi.png`,
      manufacturer: "아우디",
      release_price: "98800000",
      fuel: "디젤",
      lowest_interest: "6.7",
      greatest_interest: "32.4",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "Q5",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-q5.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-audi.png`,
      manufacturer: "아우디",
      release_price: "89700000",
      fuel: "디젤",
      lowest_interest: "7",
      greatest_interest: "31",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
    {
      car_name: "Q7",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-q7.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-audi.png`,
      manufacturer: "아우디",
      release_price: "88200000",
      fuel: "디젤",
      lowest_interest: "8",
      greatest_interest: "34",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
  ],
  lease: [
    {
      car_name: "아반떼",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-avante.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-hyundai2.png`,
      manufacturer: "현대",
      lowest_price: "324000",
      greatest_price: "376000",
      release_price: "24250000",
      fuel: "2.0 가솔린",
      product_code: "installment2",
      condition: "국산",
      rate: "20",
      type: "세단",
    },
    {
      car_name: "쏘나타",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-sonata.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-hyundai2.png`,
      manufacturer: "현대",
      lowest_price: "286000",
      greatest_price: "355000",
      release_price: "27880000",
      fuel: "2.0 디젤",
      product_code: "installment3",
      condition: "국산",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "그랜저",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-grandeur.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-hyundai2.png`,
      manufacturer: "현대",
      lowest_price: "297000",
      greatest_price: "369000",
      release_price: "24250000",
      fuel: "전기",
      product_code: "installment4",
      condition: "국산",
      rate: "10",
      type: "세단",
    },
    {
      car_name: "코나",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-kona.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-hyundai2.png`,
      manufacturer: "현대",
      lowest_price: "306000",
      greatest_price: "359000",
      release_price: "27770000",
      fuel: "전기",
      product_code: "installment5",
      condition: "국산",
      rate: "20",
      type: "SUV",
    },
    {
      car_name: "베뉴",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-venue.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-hyundai2.png`,
      manufacturer: "현대",
      lowest_price: "298000",
      greatest_price: "366000",
      release_price: "37380000",
      fuel: "전기",
      product_code: "installment6",
      condition: "국산",
      rate: "20",
      type: "SUV",
    },
    {
      car_name: "팰리세이드",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-palisade.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-hyundai2.png`,
      manufacturer: "현대",
      lowest_price: "317000",
      greatest_price: "388000",
      release_price: "38670000",
      fuel: "디젤",
      product_code: "installment7",
      condition: "국산",
      rate: "0",
      type: "SUV",
    },
    {
      car_name: "모닝",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-morning.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-kia2.png`,
      manufacturer: "기아",
      lowest_price: "325000",
      greatest_price: "367000",
      release_price: "25720000",
      fuel: "디젤",
      product_code: "installment8",
      condition: "국산",
      rate: "10",
      type: "세단",
    },
    {
      car_name: "K5",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-k5.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-kia2.png`,
      manufacturer: "기아",
      lowest_price: "294000",
      greatest_price: "342000",
      release_price: "26790000",
      fuel: "가솔린",
      product_code: "installment9",
      condition: "국산",
      rate: "0",
      type: "세단",
    },
    {
      car_name: "K8",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-k8.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-kia2.png`,
      manufacturer: "기아",
      lowest_price: "290000",
      greatest_price: "399000",
      release_price: "28740000",
      fuel: "가솔린",
      product_code: "installment9",
      condition: "국산",
      rate: "0",
      type: "세단",
    },
    {
      car_name: "셀토스",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-seltos.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-kia2.png`,
      manufacturer: "기아",
      lowest_price: "294000",
      greatest_price: "366000",
      release_price: "27800000",
      fuel: "가솔린",
      product_code: "installment9",
      condition: "국산",
      rate: "0",
      type: "SUV",
    },
    {
      car_name: "스포티지",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-sportage.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-kia2.png`,
      manufacturer: "기아",
      lowest_price: "298000",
      greatest_price: "368000",
      release_price: "32700000",
      fuel: "가솔린",
      product_code: "installment9",
      condition: "국산",
      rate: "0",
      type: "SUV",
    },
    {
      car_name: "쏘렌토",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-solento.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-kia2.png`,
      manufacturer: "기아",
      lowest_price: "297000",
      greatest_price: "378000",
      release_price: "29800000",
      fuel: "LPG",
      product_code: "installment9",
      condition: "국산",
      rate: "0",
      type: "SUV",
    },
    {
      car_name: "SM6",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-sm6.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-renault2.png`,
      manufacturer: "르노삼성",
      lowest_price: "298000",
      greatest_price: "356000",
      release_price: "29700000",
      fuel: "LPG",
      product_code: "installment9",
      condition: "국산",
      rate: "0",
      type: "세단",
    },
    {
      car_name: "QM6",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-qm6.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-renault2.png`,
      manufacturer: "르노삼성",
      lowest_price: "310000",
      greatest_price: "398000",
      release_price: "39000000",
      fuel: "LPG",
      product_code: "installment9",
      condition: "국산",
      rate: "0",
      type: "SUV",
    },
    {
      car_name: "XM3",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-xm3.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-renault2.png`,
      manufacturer: "르노삼성",
      lowest_price: "312000",
      greatest_price: "392000",
      release_price: "48200000",
      fuel: "LPG",
      product_code: "installment9",
      condition: "국산",
      rate: "0",
      type: "SUV",
    },
    {
      car_name: "3 Series",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-5series.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-bmw2.png`,
      manufacturer: "BMW",
      lowest_price: "378000",
      greatest_price: "419000",
      release_price: "67300000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "5 Series",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-3series.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-bmw2.png`,
      manufacturer: "BMW",
      lowest_price: "380000",
      greatest_price: "452000",
      release_price: "72900000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "X3",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-x3.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-bmw2.png`,
      manufacturer: "BMW",
      lowest_price: "382000",
      greatest_price: "456000",
      release_price: "87400000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
    {
      car_name: "X5",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-x5.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-bmw2.png`,
      manufacturer: "BMW",
      lowest_price: "388000",
      greatest_price: "474000",
      release_price: "86900000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
    {
      car_name: "E-Class",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-k8.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-benz.png`,
      manufacturer: "벤츠",
      lowest_price: "392000",
      greatest_price: "477000",
      release_price: "88800000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "C-Class",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-sm6.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-benz.png`,
      manufacturer: "벤츠",
      lowest_price: "399000",
      greatest_price: "456000",
      release_price: "87400000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "GLA",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-gla.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-benz.png`,
      manufacturer: "벤츠",
      lowest_price: "408000",
      greatest_price: "488000",
      release_price: "88600000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
    {
      car_name: "GLB",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-xm3.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-benz.png`,
      manufacturer: "벤츠",
      lowest_price: "410000",
      greatest_price: "469000",
      release_price: "89900000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
    {
      car_name: "S60",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-s60.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-volvo2.png`,
      manufacturer: "볼보",
      lowest_price: "398000",
      greatest_price: "480000",
      release_price: "97400000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "V60",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-xc90.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-volvo2.png`,
      manufacturer: "볼보",
      lowest_price: "399000",
      greatest_price: "488000",
      release_price: "96600000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "XC40",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-xc40.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-volvo2.png`,
      manufacturer: "볼보",
      lowest_price: "407000",
      greatest_price: "487000",
      release_price: "98800000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
    {
      car_name: "XC60",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-xc60.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-volvo2.png`,
      manufacturer: "볼보",
      lowest_price: "409000",
      greatest_price: "498000",
      release_price: "89400000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
    {
      car_name: "XC90",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-v60.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-volvo2.png`,
      manufacturer: "볼보",
      lowest_price: "420000",
      greatest_price: "500000",
      release_price: "86900000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
    {
      car_name: "A4",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-a4.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-audi.png`,
      manufacturer: "아우디",
      lowest_price: "441000",
      greatest_price: "519000",
      release_price: "92000000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "A6",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-a6.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-audi.png`,
      manufacturer: "아우디",
      lowest_price: "440000",
      greatest_price: "508000",
      release_price: "98800000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "세단",
    },
    {
      car_name: "Q5",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-q5.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-audi.png`,
      manufacturer: "아우디",
      lowest_price: "450000",
      greatest_price: "512000",
      release_price: "89700000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
    {
      car_name: "Q7",
      car_img: `${cdn_url}/images/installment-or-lease/car-img-q7.png`,
      car_logo: `${cdn_url}/images/installment-or-lease/logo-audi.png`,
      manufacturer: "아우디",
      lowest_price: "480000",
      greatest_price: "520000",
      release_price: "88200000",
      fuel: "디젤",
      product_code: "installment10",
      condition: "수입",
      rate: "30",
      type: "SUV",
    },
  ],
};

export const installmentFilter = {
  condition: [
    {
      id: "1",
      labelName: "국산 신차",
      manufacture: [
        {
          id: "1",
          labelName: "현대",
          img: `${cdn_url}/images/installment-or-lease/logo-hyundai.svg`,
        },
        {
          id: "2",
          labelName: "기아",
          img: `${cdn_url}/images/installment-or-lease/logo-kia.svg`,
        },
        {
          id: "3",
          labelName: "르노삼성",
          img: `${cdn_url}/images/installment-or-lease/logo-renault.svg`,
        },
        {
          id: "4",
          labelName: "쉐보레",
          img: `${cdn_url}/images/installment-or-lease/logo-shevrolet.svg`,
        },
      ],
    },
    { id: "2", labelName: "국산 중고차" },
    {
      id: "3",
      labelName: "수입 신차",
      manufacture: [
        {
          id: "1",
          labelName: "BMW",
          img: `${cdn_url}/images/installment-or-lease/logo-bmw.svg`,
        },
        {
          id: "2",
          labelName: "벤츠",
          img: `${cdn_url}/images/installment-or-lease/logo-benz.svg`,
        },
        {
          id: "3",
          labelName: "볼보",
          img: `${cdn_url}/images/installment-or-lease/logo-volvo.svg`,
        },
        {
          id: "4",
          labelName: "아우디",
          img: `${cdn_url}/images/installment-or-lease/logo-audi.svg`,
        },
      ],
    },
    { id: 4, labelName: "수입 중고차" },
  ],
  type: [{ labelName: "세단" }, { labelName: "SUV" }],
  evaluator: [
    { id: "1", labelName: "NICE" },
    { id: "2", labelName: "KCB" },
  ],
  rate: [
    { id: "1", labelName: "10" },
    { id: "2", labelName: "20" },
    { id: "3", labelName: "30" },
  ],
  period: [
    { id: "1", labelName: "12개월" },
    { id: "2", labelName: "24개월" },
    { id: "3", labelName: "36개월" },
    { id: "4", labelName: "48개월" },
    { id: "5", labelName: "60개월" },
  ],
  car: [
    {
      condition: "국산 신차",
      manufacturer: "현대",
      type: "세단",
      labelName: "그랜저",
    },
    {
      condition: "국산 신차",
      manufacturer: "현대",
      type: "세단",
      labelName: "소나타",
    },
    {
      condition: "국산 신차",
      manufacturer: "현대",
      type: "세단",
      labelName: "아반떼",
    },
    {
      condition: "국산 신차",
      manufacturer: "현대",
      type: "SUV",
      labelName: "펠리세이드",
    },
    {
      condition: "국산 신차",
      manufacturer: "현대",
      type: "SUV",
      labelName: "베뉴",
    },
    {
      condition: "국산 신차",
      manufacturer: "현대",
      type: "SUV",
      labelName: "코나",
    },
    {
      condition: "국산 신차",
      manufacturer: "기아",
      type: "세단",
      labelName: "모닝",
    },
    {
      condition: "국산 신차",
      manufacturer: "기아",
      type: "세단",
      labelName: "K5",
    },
    {
      condition: "국산 신차",
      manufacturer: "기아",
      type: "세단",
      labelName: "K8",
    },
    {
      condition: "국산 신차",
      manufacturer: "기아",
      type: "SUV",
      labelName: "셀토스",
    },
    {
      condition: "국산 신차",
      manufacturer: "기아",
      type: "SUV",
      labelName: "스포티지",
    },
    {
      condition: "국산 신차",
      manufacturer: "기아",
      type: "SUV",
      labelName: "쏘렌토",
    },
    {
      condition: "국산 신차",
      manufacturer: "르노삼성",
      type: "세단",
      labelName: "SM6",
    },
    {
      condition: "국산 신차",
      manufacturer: "르노삼성",
      type: "SUV",
      labelName: "QM6",
    },
    {
      condition: "국산 신차",
      manufacturer: "르노삼성",
      type: "SUV",
      labelName: "XM3",
    },
    {
      condition: "국산 신차",
      manufacturer: "쉐보레",
      type: "세단",
      labelName: "말리부",
    },
    {
      condition: "국산 신차",
      manufacturer: "쉐보레",
      type: "SUV",
      labelName: "이쿼녹스",
    },
    {
      condition: "수입 신차",
      manufacturer: "BMW",
      type: "세단",
      labelName: "3 Series",
    },
    {
      condition: "수입 신차",
      manufacturer: "BMW",
      type: "세단",
      labelName: "5 Series",
    },
    {
      condition: "수입 신차",
      manufacturer: "BMW",
      type: "SUV",
      labelName: "X3",
    },
    {
      condition: "수입 신차",
      manufacturer: "BMW",
      type: "SUV",
      labelName: "X5",
    },
    {
      condition: "수입 신차",
      manufacturer: "벤츠",
      type: "세단",
      labelName: "C-Class",
    },
    {
      condition: "수입 신차",
      manufacturer: "벤츠",
      type: "세단",
      labelName: "E-Class",
    },
    {
      condition: "수입 신차",
      manufacturer: "벤츠",
      type: "SUV",
      labelName: "GLA",
    },
    {
      condition: "수입 신차",
      manufacturer: "벤츠",
      type: "SUV",
      labelName: "GLB",
    },
    {
      condition: "수입 신차",
      manufacturer: "볼보",
      type: "세단",
      labelName: "S60",
    },
    {
      condition: "수입 신차",
      manufacturer: "볼보",
      type: "세단",
      labelName: "V60",
    },
    {
      condition: "수입 신차",
      manufacturer: "볼보",
      type: "SUV",
      labelName: "XC40",
    },
    {
      condition: "수입 신차",
      manufacturer: "볼보",
      type: "SUV",
      labelName: "XC60",
    },
    {
      condition: "수입 신차",
      manufacturer: "볼보",
      type: "SUV",
      labelName: "XC90",
    },
    {
      condition: "수입 신차",
      manufacturer: "아우디",
      type: "세단",
      labelName: "A4",
    },
    {
      condition: "수입 신차",
      manufacturer: "아우디",
      type: "세단",
      labelName: "A6",
    },
    {
      condition: "수입 신차",
      manufacturer: "아우디",
      type: "SUV",
      labelName: "Q5",
    },
    {
      condition: "수입 신차",
      manufacturer: "아우디",
      type: "SUV",
      labelName: "Q7",
    },
  ],
};
export const leaseFilter = {
  condition: [
    {
      id: 1,
      labelName: "국산차",
      manufacture: [
        {
          id: 1,
          labelName: "현대",
          img: `${cdn_url}/images/installment-or-lease/logo-hyundai.svg`,
        },
        {
          id: 2,
          labelName: "기아",
          img: `${cdn_url}/images/installment-or-lease/logo-kia.svg`,
        },
        {
          id: 3,
          labelName: "르노삼성",
          img: `${cdn_url}/images/installment-or-lease/logo-renault.svg`,
        },
        {
          id: 4,
          labelName: "쉐보레",
          img: `${cdn_url}/images/installment-or-lease/logo-shevrolet.svg`,
        },
      ],
    },
    {
      id: 2,
      labelName: "수입차",
      manufacture: [
        {
          id: 1,
          labelName: "BMW",
          img: `${cdn_url}/images/installment-or-lease/logo-bmw.svg`,
        },
        {
          id: 2,
          labelName: "벤츠",
          img: `${cdn_url}/images/installment-or-lease/logo-benz.svg`,
        },
        {
          id: 3,
          labelName: "볼보",
          img: `${cdn_url}/images/installment-or-lease/logo-volvo.svg`,
        },
        {
          id: 4,
          labelName: "아우디",
          img: `${cdn_url}/images/installment-or-lease/logo-audi.svg`,
        },
      ],
    },
  ],
  type: [{ labelName: "세단" }, { labelName: "SUV" }],
  evaluator: [{ labelName: "NICE" }, { labelName: "KCB" }],
  rate: [{ labelName: "10" }, { labelName: "20" }, { labelName: "30" }],
  period: [
    { labelName: "12개월" },
    { labelName: "24개월" },
    { labelName: "36개월" },
    { labelName: "48개월" },
    { labelName: "60개월" },
  ],
  car: [
    {
      condition: "국산차",
      manufacturer: "현대",
      type: "세단",
      labelName: "그랜저",
    },
    {
      condition: "국산차",
      manufacturer: "현대",
      type: "세단",
      labelName: "소나타",
    },
    {
      condition: "국산차",
      manufacturer: "현대",
      type: "세단",
      labelName: "아반떼",
    },
    {
      condition: "국산차",
      manufacturer: "현대",
      type: "SUV",
      labelName: "펠리세이드",
    },
    {
      condition: "국산차",
      manufacturer: "현대",
      type: "SUV",
      labelName: "베뉴",
    },
    {
      condition: "국산차",
      manufacturer: "현대",
      type: "SUV",
      labelName: "코나",
    },
    {
      condition: "국산차",
      manufacturer: "기아",
      type: "세단",
      labelName: "모닝",
    },
    {
      condition: "국산차",
      manufacturer: "기아",
      type: "세단",
      labelName: "K5",
    },
    {
      condition: "국산차",
      manufacturer: "기아",
      type: "세단",
      labelName: "K8",
    },
    {
      condition: "국산차",
      manufacturer: "기아",
      type: "SUV",
      labelName: "셀토스",
    },
    {
      condition: "국산차",
      manufacturer: "기아",
      type: "SUV",
      labelName: "스포티지",
    },
    {
      condition: "국산차",
      manufacturer: "기아",
      type: "SUV",
      labelName: "쏘렌토",
    },
    {
      condition: "국산차",
      manufacturer: "르노삼성",
      type: "세단",
      labelName: "SM6",
    },
    {
      condition: "국산차",
      manufacturer: "르노삼성",
      type: "SUV",
      labelName: "QM6",
    },
    {
      condition: "국산차",
      manufacturer: "르노삼성",
      type: "SUV",
      labelName: "XM3",
    },
    {
      condition: "국산차",
      manufacturer: "쉐보레",
      type: "세단",
      labelName: "말리부",
    },
    {
      condition: "국산차",
      manufacturer: "쉐보레",
      type: "SUV",
      labelName: "이쿼녹스",
    },
    {
      condition: "수입차",
      manufacturer: "BMW",
      type: "세단",
      labelName: "3 Series",
    },
    {
      condition: "수입차",
      manufacturer: "BMW",
      type: "세단",
      labelName: "5 Series",
    },
    {
      condition: "수입차",
      manufacturer: "BMW",
      type: "SUV",
      labelName: "X3",
    },
    {
      condition: "수입차",
      manufacturer: "BMW",
      type: "SUV",
      labelName: "X5",
    },
    {
      condition: "수입차",
      manufacturer: "벤츠",
      type: "세단",
      labelName: "C-Class",
    },
    {
      condition: "수입차",
      manufacturer: "벤츠",
      type: "세단",
      labelName: "E-Class",
    },
    {
      condition: "수입차",
      manufacturer: "벤츠",
      type: "SUV",
      labelName: "GLA",
    },
    {
      condition: "수입차",
      manufacturer: "벤츠",
      type: "SUV",
      labelName: "GLB",
    },
    {
      condition: "수입차",
      manufacturer: "볼보",
      type: "세단",
      labelName: "S60",
    },
    {
      condition: "수입차",
      manufacturer: "볼보",
      type: "세단",
      labelName: "V60",
    },
    {
      condition: "수입차",
      manufacturer: "볼보",
      type: "SUV",
      labelName: "XC40",
    },
    {
      condition: "수입차",
      manufacturer: "볼보",
      type: "SUV",
      labelName: "XC60",
    },
    {
      condition: "수입차",
      manufacturer: "볼보",
      type: "SUV",
      labelName: "XC90",
    },
    {
      condition: "수입차",
      manufacturer: "아우디",
      type: "세단",
      labelName: "A4",
    },
    {
      condition: "수입차",
      manufacturer: "아우디",
      type: "세단",
      labelName: "A6",
    },
    {
      condition: "수입차",
      manufacturer: "아우디",
      type: "SUV",
      labelName: "Q5",
    },
    {
      condition: "수입차",
      manufacturer: "아우디",
      type: "SUV",
      labelName: "Q7",
    },
  ],
};
