import { useState } from "react";
import { Link } from "react-router-dom";
import { siteLink, footerLink } from "utils/siteLink";
import { BsChevronDown } from "react-icons/bs";
import FTLogo1 from "assets/footer/footer-logo1.svg";
import FTLogo2 from "assets/footer/footer-logo2.svg";
import FTLogo3 from "assets/footer/footer-logo3.svg";
import FTLogo4 from "assets/footer/footer-logo4-color.svg";

export default function MobileSiteMenus({
  mobileNavi,
  setMobileNavi,
  setShowLicenseModal,
  closeMobileNavi,
}) {
  const [showAccordion, setShowAccordion] = useState(false);
  const [showLicenseAccor, setShowLicenseAccor] = useState(false);

  function showLicense(event) {
    setShowLicenseModal((prevState) => ({
      ...prevState,
      [`id`]: event.target.value,
      [`title`]: event.target.id,
      [`show`]: true,
    }));
    closeMobileNavi();
  }

  const lscList = [
    {
      id: "4",
      name: "한국핀테크 산업협회",
      heading: "한국핀테크 산업협회 회원증",
      img: FTLogo4,
    },
    {
      id: "3",
      name: "벤처기업 혁신유형",
      heading: "벤처기업 확인증",
      img: FTLogo3,
    },
    {
      id: "1",
      name: "기업부설연구소",
      heading: "기업부설연구소 인정서",
      img: FTLogo1,
    },
    {
      id: "2",
      name: "대출모집인 등록",
      heading: "대출모집인 등록증",
      img: FTLogo2,
    },
  ];

  return (
    <>
      {mobileNavi && (
        <div className="navi-site-menu">
          <section className="navi-item-list">
            <div className="navi-item-notice-area">
              <div
                className="navi-item-license"
                onClick={() => setShowLicenseAccor(!showLicenseAccor)}
              >
                <span>기업인증서</span>
                <BsChevronDown
                  style={{ transform: showLicenseAccor && "rotate(360deg)" }}
                />
              </div>
              <ul
                className="navi-item-license-group"
                style={{
                  height: showLicenseAccor ? "144px" : 0,
                }}
              >
                {lscList.map((item, idx) => (
                  <div className="license-btn" key={idx}>
                    <button
                      id={item.heading}
                      value={item.id}
                      onClick={showLicense}
                    />
                    <div>
                      <img src={item.img} alt={item.name} />
                    </div>
                    <span>{item.name}</span>
                  </div>
                ))}
              </ul>
              {/* <Link to="/partnership" onClick={closeMobileNavi}>
                사업제휴 문의
              </Link> */}
              {footerLink.map((link, idx) => (
                <a
                  href={link.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={closeMobileNavi}
                  key={idx}
                >
                  {link.name}
                </a>
              ))}
            </div>
          </section>
          <section className="navi-item-site">
            <div
              className={
                showAccordion ? "navi-item-site-open" : "navi-item-site-button"
              }
              onClick={() => setShowAccordion(!showAccordion)}
            >
              <p>관련 사이트</p>
              <div className="header-arrow" />
            </div>
            <div
              className="navi-item-site-accordion"
              style={{
                top: showAccordion ? "-210px" : 0,
              }}
            >
              {siteLink.map((link, idx) => (
                <a
                  key={idx}
                  href={link.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.name}
                </a>
              ))}
            </div>
          </section>
        </div>
      )}
    </>
  );
}
