import React, { useEffect } from "react";
import { RecoilRoot } from "recoil";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import Header from "./components/TemplatePages/header/Header";
import Main from "./components/main/Main";
import MortageLoan from "components/koscom/MortageLoan";
import Installment from "components/koscom/installmentOrLease/Installment";
import Footer from "./components/TemplatePages/footer/Footer";
import Page404 from "./components/error/Page404";
import "./reset.css";
import SelfCertification from "components/creditLoan/self-certification/Self_Certification";
import Introduction from "./components/introduction/Introduction";
import MoreMortage from "components/creditLoan/more-mortage/MoreMortage";
import MoreInformation from "components/creditLoan/more-infomation/More_Information";
import LoanList from "components/creditLoan/loanList/LoanList";
import LoanProducts from "components/loanProducts/LoanProducts";
import InstallmentOrLease from "components/koscom/installmentOrLease/legacy/InstallmentOrLease";
import Lease from "components/koscom/installmentOrLease/Lease";
import MoreMortageHouseInfo from "components/creditLoan/more-mortage-house/MoreMortageHouseInfo";

const ScrollToTop = withRouter(({ location }) => {
  useEffect(() => {
    // 라우터 변경될 때 마다 윈도우 스크롤 top으로 이동
    window.scrollTo(0, 0);
  }, [location]);
  return <div />;
});

function App() {
  return (
    <RecoilRoot>
      <Header />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/introduction" component={Introduction} />
        <Route
          exact
          path="/credit-certification"
          render={() => {
            return <SelfCertification how="신용" />;
          }}
        />
        <Route
          exact
          path="/mortage-certification"
          render={() => {
            return <SelfCertification how="담보" />;
          }}
        />
        <Route
          exact
          path="/ism-certification"
          render={() => {
            return <SelfCertification how="할부" />;
          }}
        />
        <Route
          exact
          path="/mortage-certification/more"
          component={MoreMortage}
        />
        <Route
          exact
          path="/mortage-certification/house-info"
          component={MoreMortageHouseInfo}
        />
        <Route
          exact
          path="/credit-certification/more"
          component={MoreInformation}
        />
        <Route path="/loan-products" component={LoanProducts} />
        {/* 대출상세 */}
        <Route path="/credit-loan" component={LoanList} /> {/* 신용대출 */}
        <Route path="/mortage-loan" component={MortageLoan} /> {/* 담보대출 */}
        {/* 할부, 리스 */}
        <Route path="/installment-or-lease" component={InstallmentOrLease} />
        <Route path="/installment" component={Installment} />
        <Route path="/lease" component={Lease} />
        <Route exact path="/404" component={Page404} />
        <Route path="*">
          <Redirect to={{ pathname: "/404" }} />
        </Route>
      </Switch>
      <Footer />
    </RecoilRoot>
  );
}

export default App;
