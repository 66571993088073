export default function Agreement_Edenden() {
  // [필수]개인(신용)정보 수집·이용•제공동의서(이든파이낸셜)
  return (
    <div className="agreement-container">
      <section>
        <p>2022.02.11</p>
        <b>[주식회사 이든파이낸셜] 귀중</b>
        <span>
          주식회사 이든파이낸셜(이하“이든파이낸셜”)에서 수행하는 대출조건
          비교서비스와 관련하여 이든파이낸셜이 귀하의 개인(신용)정보를
          수집·이용하거나 제3자에게 제공하고자 하는 경우에는 「신용정보의 이용
          및 보호에 관한 법률」, 「개인정보보호법」 등 관계법령에 따라 본인의
          동의를 얻어야합니다.
        </span>
      </section>
      <span>
        이 동의서를 통해 귀하의 동의를 받고자 하는 금융기관은 아래와 같습니다.
        <br />
        ㈜다올저축은행, ㈜키움저축은행, ㈜애큐온캐피탈
      </span>
      <section>
        <h3>1. 수집·이용에 대한 사항</h3>
        <h4 className="list-title">1-1. 수집·이용 목적 </h4>
        <ul>
          <li>
            (1) 고객과 금융기관들의 대출거래를 위한 이든파이낸셜
            대출조건비교서비스의 제공
          </li>
          <li>(2) 금융사고 조사, 분쟁해결, 민원처리</li>
        </ul>
        <h4 className="list-title">1-2. 보유 및 이용기간 </h4>
        <ul>
          <li>(1) 일반개인정보는 동의한 날로부터 최대 1년까지</li>
          <li>(2) 신용능력정보는 동의한 날로부터 최대 6영업일까지</li>
        </ul>
        <span>
          ※ 위 보유기간에서의 (금융)거래 종료일이란 “제공받는 자와 거래중인 모든
          계약(여·수 신, 내·외국환, 카드 및 제3자 담보제공 등) 해지 및
          서비스(대여금고, 보호예수, 외국환거 래지정, 인터넷뱅킹 포함
          전자금융거래 등)가 종료된 날”을 말합니다.
          <br />※ (금융)거래 종료일 후에는 금융사고 조사, 분쟁해결, 민원처리,
          법령상 의무이행을 위한 목적으로만 보유·이용됩니다.
        </span>
        <h4 className="list-title">1-3. 거부 권리 및 불이익 </h4>
        <ul>
          <li>
            (1) 귀하는 동의를 거부하실 수 있습니다. 위 개인(신용)정보의
            수집·이용은 서비스이용을 위 한 필수적 사항이므로, 동의하지 않으실
            경우 이든파이낸셜 대출조건비교서비스의 이용이 불가능합니다.
          </li>
        </ul>
        <h4 className="list-title">1-4. 수집·이용 항목 </h4>
        <ul>
          <li>
            (1) 고유식별정보 고유식별정보(주민등록번호, 외국인등록번호,
            여권번호, 운전면허번호)
          </li>
          <li>(2) 개인(신용)정보</li>
          <li className="list-2depth">
            ⦁ 일반개인정보- 성명, 주소, 휴대전화번호, 자택전화번호, 전자우편
            <br />⦁ 신용능력정보- 재산,채무,소득의 총액, 납세실적, 건강보험료,
            국민연급가입/납입정보, 직장, 입사년월
          </li>
        </ul>
      </section>
      <section className="last-section">
        <h3>2. 제공에 관한 사항 </h3>
        <h4 className="list-title">2-1. 제공받는 자 </h4>
        <ul>
          <li>(1) 제공받는 자 (추후 업데이트예정)</li>
        </ul>
        <h4 className="list-title">2-2. 제공받는 자의 이용목적 </h4>
        <ul>
          <li>(1) 이든파이낸셜 대출조건비교서비스 연동에 관한 업무</li>
          <li>(2) (금융) 거래관계의 설정 여부 판단</li>
          <li>(3) (금융) 거래관계의 설정, 유지, 이행, 관리</li>
          <li>(4) 금융사고 조사, 분쟁해결, 민원 처리</li>
          <li>(5) 법령상 의무이행 - 본인인증</li>
        </ul>
        <h4 className="list-title">2-3. 보유 및 이용기간 </h4>
        <ul>
          <li>
            (1) 귀하의 개인(신용)정보는 제공된 날로부터 동의 철회시 또는 제공된
            목적을 달성할 때까지 보유·이용됩니다. <br />
            단, 제공된 목적 달성 후에는 금융사고 조사, 분쟁해결, 민원처리 및 법
            령상 의무이행만을 위하여 보유·이용됩니다.
          </li>
        </ul>
        <h4 className="list-title">2-4. 거부 권리 및 불이익 </h4>
        <ul>
          <li>
            (1) 귀하는 동의를 거부하실 수 있습니다. 위 개인(신용)정보의
            수집·이용은 서비스이용을 위 한 필수적 사항이므로, 동의하지 않으실
            경우 이든파이낸셜 대출조건비교서비스의 이용이 불가능합니다.
          </li>
        </ul>
        <h4 className="list-title">2-5. 제공 항목 </h4>
        <ul>
          <li>
            (1) 고유식별정보 고유식별정보(주민등록번호, 외국인등록번호,
            여권번호, 운전면허번호)
          </li>
          <li>
            (2) 개인(신용)정보
            <ul className="list-2depth">
              <li>⦁ 일반개인정보</li>
              <li>
                - 국내거소신고번호, 성명, 주소, 전자우편주소, 휴대전화번호,
                자택·직장번호, 국적, 직장, 입사년월
              </li>
              <li>⦁ 신용능력정보</li>
              <li>
                - 재산,채무,소득의 총액, 납세실적, 건강보험료,
                국민연급가입/납입정보, 입사년월
              </li>
            </ul>
          </li>
        </ul>
      </section>
    </div>
  );
}
