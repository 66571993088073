export function validate(user_rrn_front, user_rrn_back) {
  var arrNum1 = []; // 주민번호 앞자리숫자 6개를 담을 배열
  var arrNum2 = []; // 주민번호 뒷자리숫자 7개를 담을 배열

  for (var i = 0; i < user_rrn_front.length; i++) {
    arrNum1[i] = user_rrn_front.charAt(i);
  } // 주민번호 앞자리를 배열에 순서대로 담는다.

  // eslint-disable-next-line no-redeclare
  for (var i = 0; i < user_rrn_back.length; i++) {
    arrNum2[i] = user_rrn_back.charAt(i);
  } // 주민번호 뒷자리를 배열에 순서대로 담는다.

  var tempSum = 0;

  // eslint-disable-next-line no-redeclare
  for (var i = 0; i < user_rrn_front.length; i++) {
    tempSum += arrNum1[i] * (2 + i);
  } // 주민번호 검사방법을 적용하여 앞 번호를 모두 계산하여 더함

  // eslint-disable-next-line no-redeclare
  for (var i = 0; i < user_rrn_back.length - 1; i++) {
    if (i >= 2) {
      tempSum += arrNum2[i] * i;
    } else {
      tempSum += arrNum2[i] * (8 + i);
    }
  } // 같은방식으로 앞 번호 계산한것의 합에 뒷번호 계산한것을 모두 더함

  // eslint-disable-next-line eqeqeq
  if ((11 - (tempSum % 11)) % 10 != arrNum2[6]) {
    // console.log("올바른 주민번호가 아닙니다.");
    return false;
  } else {
    // console.log("올바른 주민등록번호 입니다.");
    return true;
  }
}
