import "./HomePhone.scss";
// import headphone from "assets/main/3_headphone.png";
import { useRecoilValue } from "recoil";
import { windowSizeAtom } from "recoil/atom";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import { cdn_url } from "utils/api/_utils";
const headphone = `${cdn_url}/assets/main/3_headphone.png`;

function HomePhone() {
  const windowSize = useRecoilValue(windowSizeAtom);
  return (
    <section className="homephone-container">
      <div className="homephone-wrapper">
        <div className="heading-text">
          <img src={headphone} alt="고객센터 전화" />
          <h2>
            친절한 상담과
            <br />
            빠른 문제해결!
          </h2>
        </div>
        <div className="homephone-number">
          <div className="number-box">
            <h2>
              고객센터{" "}
              {windowSize.width > 1200 || windowSize.width < 758 ? (
                <br />
              ) : null}
              상담안내
            </h2>
            <div className="number-text">
              <h1>
                {windowSize.width <= 1024 ? (
                  <a href="tel:062-710-1600"> 062.710.1600 </a>
                ) : (
                  "062.710.1600"
                )}
              </h1>
              <p>평일 09:00 ~ 18:00 | 주말 공휴일 휴무</p>
              {/* <Link className="homephone-link" to="/notice/inquiry">
                <span>1:1 문의 바로가기</span> <BiChevronRight />
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomePhone;
