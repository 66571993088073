import { axios } from "../../../utils/api/_utils";

// 통신사
export const carrierArray = [
  {
    id: "1",
    labelName: "SKT",
  },
  {
    id: "2",
    labelName: "KT",
  },
  {
    id: "3",
    labelName: "LG U+",
  },
  {
    id: "4",
    labelName: "SKT 알뜰폰",
  },
  // {
  //   id: "5",
  //   labelName: "KT 알뜰폰",
  // },
  // {
  //   id: "6",
  //   labelName: "LG U+ 알뜰폰",
  // },
];

// 약관
export const TermsContentArray = [
  {
    label: "이든든",
    title: "e든든 필수 항목 모두 동의 (필수)",
    subTerms: [
      {
        heading: "개인(신용)정보 수집·이용 제공 동의서",
        subHeading: "",
        status: true,
      },
    ],
  },
  {
    label: "금융기관",
    title: "금융기관 필수 항목 모두 동의 (필수)",
    subTerms: [
      {
        heading: "개인(신용)정보 수집·이용·제공 동의서",
        subHeading: "[ 금융기관 | 여신금융거래 ]",
        status: true,
      },
      {
        heading: "개인(신용)정보 수집·이용·제공·조회 동의서",
        subHeading: "[ 금융기관 | 여신금융거래 ]",
        status: true,
      },
      {
        heading: "개인(신용)정보 제3자 제공 동의서",
        subHeading: "[ 금융기관 | 여신금융거래 ]",
        status: true,
      },
    ],
  },
  {
    label: "서민금융진흥원",
    title: "서민금융진흥원 필수 항목 모두 동의 (필수)",
    subTerms: [
      {
        heading: "개인(신용)정보 수집·이용·제공 동의서",
        subHeading: "[서민금융진흥원]",
        status: true,
      },
      {
        heading: "개인(신용)정보 수집·이용·제공·조회 동의서",
        subHeading: "[서민금융진흥원]",
        status: true,
      },
    ],
  },
  {
    label: "서울보증보험",
    title: "서울보증보험 필수 항목 모두 동의 (필수)",
    subTerms: [
      {
        heading: "계약 체결이행 등을 위한 필수 동의서",
        subHeading: "[서울보증보험]",
        status: true,
      },
    ],
  },
];

// 본인인증 페이지 : 대출 한도 조회
export const SelfLoanLimitCheck = async (data) => {
  const result = await axios.post("/loan/certification", data);

  console.log("/certification 결과", result);
  return {
    success: result.data.success,
    headers: result.headers.authorization,
    message: result.data.message,
  };
};

// 재방문 여부 확인
export const RevisitCheck = async (data) => {
  const result = await axios.post("/loan/check", data);

  console.log("/RevisitCheck 결과", result);
  return { success: result.data.success, code: result.data.code };
};

// 인증 문자 발송
export const Sendotp = async (data) => {
  const result = await axios.post("/auth", data);

  console.log("/auth 결과", result);
  return {
    success: result.data.success,
    uid: result.data.uid,
  };
};

// 인증 문자 확인
export const BringOtp = async (data) => {
  const result = await axios.post("/auth/otp", data);

  console.log("/Bring Otp 결과", result);
  return {
    success: result.data.success,
    headers: result.headers.authorization,
    message: result.data.message,
  };
};

// 테스트
export const TestStatus = async (uid) => {
  const result = await axios.post("/loan/status", uid);

  console.log("/loan/status 결과", result);
  return {
    success: result.data.success,
    data: result.data.list,
  };
};
