export default function Agreement_Bank3() {
  // [필수] 개인(신용)정보 제3자 제공 동의서
  return (
    <div className="agreement-container">
      <section>
        <p>2022.02.11</p>
        <b>[이든파이낸셜 제휴 금융사] 귀중</b>
        <span>
          ※ 주식회사 이든파이낸셜의 서비스를 통한(금융)거래와 관련하여 고객이
          선택한 제휴 금융사가 본인의 개인(신용)정보를 제3자에게 제공하고자 하는
          경우에는「신용정보의 이용 및 보호에 관한법률」,「개인정보보호법」 등
          관계 법령에 따라 본인의 동의가 필요합니다.
        </span>
      </section>
      <section>
        <h3>1. 제공에 관한 사항</h3>
        <h4 className="list-title">1-1. 제공받는 자 </h4>
        <ul>
          <li>
            이든파이낸셜 등 각 금융사와 모집위탁계약을 체결한 자, 계약체결 및
            이행등에 필요한 업무를 위탁받은자(업무수탁자와 관련한 상세한 내용은
            각 금융사의 홈페이지 개인정보 처리방침을 참고하시기 바랍니다.)
          </li>
        </ul>
        <h4 className="list-title">1-2. 제공받는 자의 이용 목적 </h4>
        <ul>
          <li>
            (1) 본 동의서 징구를 포함한 ‘내 대출 한도’ 서비스 업무처리·대출
            모집관련 업무의성과 측정 및 실적 정산등
          </li>
        </ul>
        <h4 className="list-title">1-3. 보유 및 이용기간 </h4>
        <ul>
          <li>
            (1) 계약체결시: 대출모집 관련 업무처리, 성과측정 및 실적정산 등
            이용목적 달성시까지 보유·이용(실적 정산 완료일로부터 최대2개월)
            <br />
            (2) 계약미체결시: 정보제공일로부터 최대3개월
          </li>
        </ul>
        <span>
          ※ 단,신용정보 제공·조회 동의의 효력기간 종료후에는 금융사고 조사,
          분쟁해결, 미원처리 및 법령상 의무이행만 우하여 보유·이용됩니다.
        </span>
        <h4 className="list-title">1-4. 거부 권리 및 불이익 </h4>
        <ul>
          <li>
            (1) 귀하는 동의를 거부하실 수 있습니다. 다만, 위 개인(신용)정보
            제공에 관한 동의는 “(금융)거래 계약의 체결 및 이행을 위한” 필수적
            사항이므로, 위 사항에 동의하셔야만 (금융)거래관계의 설정 및 유지가
            가능합니다.
          </li>
        </ul>
      </section>
      <section className="last-section">
        <h3>2. 제공 항목 </h3>
        <h4 className="list-title">2-1. 개인(신용)정보 </h4>
        <ul>
          <li>
            (1) 성명,주소,전화번호,국적,전자우편,연계정보(CI),전자금융거래시
            수집정보,신용거래정보 등 대출모집업무 제휴 버시스 과정에서 생성되
            고객정보
          </li>
          <li>
            (2) 신용거래정보 (금융)거래설정 내역정보,금융회사의 대출가능정보 및
            대출실행정보(대출신청정보,대출승인번호,대출금리,대출기간,대출실행일
            등)
          </li>
        </ul>
      </section>
    </div>
  );
}
