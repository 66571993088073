import React, { useEffect } from "react";
import "../loanProd.scss";
import colorVariables from "assets/scss/_variables.scss";
import { useRecoilValue } from "recoil";
import { windowSizeAtom } from "recoil/atom";
import { Link, useHistory } from "react-router-dom";
// import { LoanApplication } from "components/creditLoan/loanList/LoanListFuntion";
import { calculator } from "utils/calculator";
import { cdn_url } from "utils/api/_utils";
import { BankImgCdnMapping } from "utils/bankImgMapping";
import Page404 from "components/error/Page404";
const MoneyIcon1 = `${cdn_url}/assets/loanProduct/money.png`;
const MoneyIcon2 = `${cdn_url}/assets/loanProduct/money2.png`;
const Graph = `${cdn_url}/assets/loanProduct/graph.png`;

export default function EmptyProducts({ loanInfo, detailInfo, how }) {
  const { bank_name, product_name, interest, amount, bridge_url, sequence } = {
    ...loanInfo,
  };

  const windowSize = useRecoilValue(windowSizeAtom);
  const history = useHistory();

  const LoanSubmitButton = () => {
    return (
      <button
        className={`heading-button ${windowSize.width <= 758 && "mobile"}`}
        style={{ backgroundColor: colorVariables.mainColor }}
        // onClick={() => window.open(`${bridge_url}`, "_blank")}
        onClick={() => {
          RequestLoan();
        }}
      >
        대출신청하기
      </button>
    );
  };

  // 가승인 조회 후 대출신청
  const RequestLoan = () => {
    // LoanApplication({ sequence }).then((result) => {
    //   if (result.success) {
    //     // 대출신청 완료되었다는 모달창 필요합니다.
    //     if (window.confirm("대출신청이 완료되었습니다.") === true) {
    //       history.push({
    //         pathname: "/loan-status",
    //         state: { how: how },
    //       });
    //     }
    //   } else {
    //     alert("오류가 발생하였습니다. 다시 한 번 시도해주시기 바랍니다.");
    //     // history.push("/credit-loan-main");
    //   }
    // });
    alert("대출신청이 완료되었습니다.");
    history.push("/credit-loan");
  };
  const firstMonthInterest = Math.round(
    calculator.calcFA(amount, 5, interest)[0].interest
  ).toLocaleString();

  return (
    <>
      {detailInfo == undefined || detailInfo === [] ? (
        <Page404 />
      ) : (
        <>
          <main className="loan-product-container">
            <div className="loan-product-wrapper">
              <section className="loan-card-container">
                <div className="card-heading">
                  <img
                    src={`${cdn_url}/images/bank-images/${BankImgCdnMapping(
                      bank_name
                    )}.svg`}
                    className="bank-logo"
                    alt="bank"
                  />
                  <div className="heading-text">
                    <div className="bank-name">{bank_name}</div>
                    <h3 className="bank-desc">{product_name}</h3>
                  </div>
                  {/* {windowSize.width > 758 && bridge_url ? (
                  <LoanSubmitButton />
                ) : (
                  <></>
                )} */}
                  {windowSize.width > 758 && <LoanSubmitButton />}
                </div>
                <div className="card-body">
                  <div className="body-text">
                    <div className="body-circle">
                      <img src={MoneyIcon1} alt="interest-rate" />
                    </div>
                    <div className="body-number">
                      <h3 className="title">금리</h3>
                      <h2 className="content">{interest}%</h2>
                    </div>
                  </div>
                  <div className="body-text">
                    <div className="body-circle">
                      <img src={Graph} alt="interest-rate" />
                    </div>
                    <div className="body-number">
                      <h3 className="title">한도</h3>
                      <h2 className="content">{amount.toLocaleString()}원</h2>
                    </div>
                  </div>
                </div>
                <div className="interest-box">
                  <section>
                    <div className="icon-text-div">
                      <div className="icon-div">
                        <img src={MoneyIcon2} alt="" />
                      </div>
                      <div className="interest-text">
                        <p>예상 첫달 이자</p>
                        <h4>{firstMonthInterest}원</h4>
                        {windowSize.width > 758 && (
                          <span>
                            *예금액은 상환방식과 개월수에 따라 상이할 수
                            있습니다.
                          </span>
                        )}
                      </div>
                    </div>
                    {windowSize.width <= 758 && (
                      <span>
                        *예금액은 상환방식과 개월수에 따라 상이할 수 있습니다.
                      </span>
                    )}
                  </section>
                  {/* <Link
                    to="/loan-interest-calculator"
                    className="calculator-btn"
                  >
                    <div className="calculator-circle">
                      <img src={CalculatorImg} alt="Calcultaor" />
                    </div>
                    <span>대출이자계산기</span>
                  </Link> */}
                </div>
              </section>
              <section className="loan-products-container">
                <div className="container-heading">
                  <h1>이든파이낸셜 제휴 대출상품정보</h1>
                </div>
                <div className="descs">
                  <div className="additional-desc-box">
                    <h3>
                      이든파이낸셜과 제휴하여 판매 가능한 대출상품 정보 입력
                      (대출한도비교서비스, 추천상품(광고, 리스트형))
                    </h3>
                    <ul>
                      {/* <li>정보 입력..</li> */}
                      {detailInfo.입력조건.map((data, idx) => (
                        <li key={idx}>{data}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="desc-lists">
                    <h3 className="desc-heading">
                      신청대상 (신청대상을 작성해주세요)
                    </h3>
                    <ul>
                      {/* <li>신청대상 입력...</li> */}
                      {detailInfo.신청대상.map((data, idx) => (
                        <li key={idx}>{data}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="desc-tables">
                    <h3 className="desc-heading">
                      대출조건 (노란색 영역은 기입해주세요)
                    </h3>
                    <table className="desc-table">
                      <thead>
                        <tr>
                          <th className="loan-table-thead-th">구분</th>
                          <th className="loan-table-thead-th">내용</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th className="loan-table-tbody-th">상품명</th>
                          <td className="loan-table-tbody-row">
                            {product_name}
                          </td>
                        </tr>
                        <tr>
                          <th className="loan-table-tbody-th">대출금리</th>
                          <td className="loan-table-tbody-row">
                            {detailInfo.대출금리.map((data, idx) => (
                              <ul key={idx}>
                                <li>{data}</li>
                              </ul>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <th className="loan-table-tbody-th">대출한도</th>
                          <td className="loan-table-tbody-row">
                            {detailInfo.대출한도.map((data, idx) => (
                              <ul key={idx}>
                                <li>{data}</li>
                              </ul>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <th className="loan-table-tbody-th">대출기간</th>
                          <td className="loan-table-tbody-row">
                            {detailInfo?.대출기간.map((data, idx) => (
                              <ul key={idx}>
                                <li>{data}</li>
                              </ul>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <th className="loan-table-tbody-th">우대금리</th>
                          <td className="loan-table-tbody-row">
                            {detailInfo.우대금리.map((data, idx) => (
                              <ul key={idx}>
                                <li>{data}</li>
                              </ul>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <th className="loan-table-tbody-th">금리유형</th>
                          <td className="loan-table-tbody-row">
                            {detailInfo.금리유형.map((data, idx) => (
                              <ul key={idx}>
                                <li>{data}</li>
                              </ul>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <th className="loan-table-tbody-th">상환방법</th>
                          <td className="loan-table-tbody-row">
                            {detailInfo.상환방법.map((data, idx) => (
                              <ul key={idx}>
                                <li>{data}</li>
                              </ul>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <th className="loan-table-tbody-th">이자납부시기</th>
                          <td className="loan-table-tbody-row">
                            {detailInfo.이자납부시기.map((data, idx) => (
                              <ul key={idx}>
                                <li>{data}</li>
                              </ul>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <th className="loan-table-tbody-th">인지세</th>
                          <td className="loan-table-tbody-row">
                            {detailInfo.인지세.map((data, idx) => (
                              <ul key={idx}>
                                <li>{data}</li>
                              </ul>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <th className="loan-table-tbody-th">
                            중도상환수수료
                          </th>
                          <td className="loan-table-tbody-row">
                            {detailInfo.중도상환수수료.map((data, idx) => (
                              <ul key={idx}>
                                <li>{data}</li>
                              </ul>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <th className="loan-table-tbody-th">연체금리</th>
                          <td className="loan-table-tbody-row">
                            {detailInfo.연체금리.map((data, idx) => (
                              <ul key={idx}>
                                <li>{data}</li>
                              </ul>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <th className="loan-table-tbody-th">준비서류</th>
                          <td className="loan-table-tbody-row">
                            {detailInfo.준비서류.map((data, idx) => (
                              <ul key={idx}>
                                <li>{data}</li>
                              </ul>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <th className="loan-table-tbody-th">부대비용</th>
                          <td className="loan-table-tbody-row">
                            {detailInfo.부대비용.map((data, idx) => (
                              <ul key={idx}>
                                <li>{data}</li>
                              </ul>
                            ))}
                          </td>
                        </tr>
                        <tr>
                          <th className="loan-table-tbody-th">정부지원정책</th>
                          <td className="loan-table-tbody-row">
                            {detailInfo.정부지원정책.map((data, idx) => (
                              <ul key={idx}>
                                <li>{data}</li>
                              </ul>
                            ))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="desc-lists">
                    <h3 className="desc-heading">유의사항</h3>
                    {detailInfo.유의사항.map((data, idx) => (
                      <ul key={idx}>
                        <li>{data}</li>
                      </ul>
                    ))}
                  </div>
                </div>
                <div className="review">
                  <p>
                    {bank_name} 준법감시인 심의필 {detailInfo.은행사심의필}
                  </p>
                  <p>저축은행중앙회 심의필 {detailInfo.중앙회심의필}</p>
                </div>
              </section>
              {windowSize.width <= 758 && <LoanSubmitButton />}
            </div>
          </main>
        </>
      )}
    </>
  );
}
