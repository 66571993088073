import { useState } from "react";
import { siteLink } from "utils/siteLink";

export default function RelatedSite() {
  const [showSite, setShowSite] = useState(false);

  return (
    <>
      <div
        className="footer-related-site"
        style={{ borderTop: showSite && "none" }}
        onClick={() => setShowSite(!showSite)}
      >
        관련사이트
        <div
          className="arrow"
          style={{ transform: showSite ? "rotate(180deg)" : "" }}
        />
      </div>
      <div
        className="related-accordion"
        style={{
          height: showSite ? "225px" : 0,
          padding: showSite ? "16px 16px 20px 16px" : 0,
          gap: showSite ? "16px" : "6px",
        }}
      >
        {siteLink.map((link, idx) => (
          <a
            key={idx}
            href={link.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.name}
          </a>
        ))}
      </div>
    </>
  );
}
