import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "components/koscom/installmentOrLease/slick.css";
import "components/koscom/installmentOrLease/slick-theme.css";
import CarDetail from "../carList/CarDetail";
import CarList from "../carList/CarList";
import ReplayIcon from "assets/koscom/replay.png";
import { koscom } from "components/koscom/koscom.js";

export default function LeaseBranch({ match, location }) {
  let pathname = useLocation().pathname;
  let leaseOption = useLocation().state?.leaseOption;
  const istm_session = JSON.parse(window.sessionStorage.getItem("installment"));
  const lease_session = JSON.parse(window.sessionStorage.getItem("lease"));
  const [leaseResult, setLeaseResult] = useState({
    condition: "",
    manufacturer: "",
    type: "",
    model: "",
    rate: "",
    period: "",
  });

  useEffect(() => {
    if (leaseOption !== undefined) {
      setLeaseResult(leaseOption);
    } else {
      if (lease_session !== null) {
        setLeaseResult(lease_session);
      }
    }
  }, []);

  return (
    <>
      <div className="loanable-list-container">
        <div className="loanable-list-outer">
          <div className="outer-textbox">
            <p>할부 / 리스</p>
          </div>
        </div>
        <div className="loanable-list-inner">
          <section className="installment-lease-container">
            <div className="select-loanway">
              <Link to={`/installment${istm_session !== null ? "/list" : ""}`}>
                할부
              </Link>
              <Link to="#" className="active">
                리스
              </Link>
            </div>
            <div
              className="result-filter"
              style={{
                boxShadow:
                  pathname.includes("detail") && "0px 4px 4px rgb(0 0 0 / 5%)",
              }}
            >
              <Link
                className="filter-icon"
                to="/lease"
                onClick={() => {
                  window.sessionStorage.removeItem("lease");
                }}
              >
                <span>다시조회</span>
                <img src={ReplayIcon} alt="filter" />
              </Link>
              <LeaseBadges settings={settings} leaseResult={leaseResult} />
            </div>
            <Switch>
              <Route
                exact
                path={`${match.path}`}
                component={() => (
                  <CarList
                    filter={leaseResult}
                    data={koscom.lease}
                    isLease={true}
                  />
                )}
              />
              <Route
                path={`${match.path}/detail`}
                component={() => (
                  <CarDetail location={location} isLease={true} />
                )}
              />
            </Switch>
          </section>
        </div>
      </div>
    </>
  );
}

const LeaseBadges = ({ settings, leaseResult }) => (
  <Slider {...settings}>
    <div className="badge">
      {leaseResult.condition !== "" ? leaseResult.condition : "국산차"}
    </div>
    <div className="badge">
      <span>
        {leaseResult.manufacturer !== ""
          ? leaseResult.manufacturer
          : "전체 차량"}
      </span>
      {leaseResult.type !== "" && <span> {leaseResult.type}</span>}
      {leaseResult.model !== "" && <span> {leaseResult.model}</span>}
    </div>
    {leaseResult.rate !== "" && (
      <div className="badge">현금구매비율 / {leaseResult.rate}%</div>
    )}
    {leaseResult.period !== "" && (
      <div className="badge">이용기간 / {leaseResult.period}</div>
    )}
  </Slider>
);

const settings = {
  dots: false,
  infinite: false,
  autoplay: false,
  slidesToScroll: 1,
  slidesToShow: 2,
  className: "badges",
  draggable: true,
  variableWidth: true,
  arrows: false,
};
