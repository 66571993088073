import { useEffect } from "react";
import { numToKorean } from "utils/numToKorean";
import { regexDate, regexNum } from "utils/regex";

export default function MortageOffice({
  register,
  errors,
  handleChangeValue,
  moreInfo,
  getValues,
}) {
  useEffect(() => {
    if (getValues("join_date") !== undefined) {
    }
  });
  return (
    <>
      <section className="text-input-box">
        <p className="text-input-title">
          연소득 (배우자포함)
          {errors?.salary ? <span>연소득을 올바르게 입력해주세요</span> : null}
        </p>
        <div
          className="text-input-item"
          style={{ flexDirection: "row", alignItems: "flex-end" }}
        >
          <input
            {...register("salary", {
              required: true,
              onChange: handleChangeValue,
              pattern: /[0-9]/,
            })}
            inputMode="numeric"
            autoComplete="off"
            autoCorrect="off"
            placeholder="연소득"
            name="salary"
            maxLength={8}
            className={`${errors?.salary ? "error" : null}`}
            value={regexNum(moreInfo.salary)}
          />
          <span>만원</span>
        </div>
        {getValues("salary") > 0 && (
          <span className="text-sub-title" id="sub-txt-salary">
            {(getValues("salary") * 10000).toLocaleString("ko-KR")} (
            {numToKorean(getValues("salary") * 10000)}원)
          </span>
        )}
      </section>
      <section className="text-input-box">
        <p className="text-input-title">
          입사연월일
          {errors?.join_date ? (
            <span>입사연월일 형식을 올바르게 입력해주세요</span>
          ) : null}
        </p>
        <div
          className="text-input-item"
          style={{ flexDirection: "row", alignItems: "flex-end" }}
        >
          <input
            {...register("join_date", {
              required: true,
              onChange: handleChangeValue,
              pattern: /[0-9]/,
              // pattern: /^[0-9]{4}.[0-9]{2}.[0-9]{2}/,
            })}
            inputMode="numeric"
            autoComplete="off"
            autoCorrect="off"
            placeholder="2020.01.10"
            name="join_date"
            maxLength={8}
            className={`${errors?.join_date ? "error" : null}`}
            value={regexDate(moreInfo.join_date)}
          />
        </div>
      </section>
    </>
  );
}
