import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import CarProductCard from "./CarProductCard";
import LeaseSort from "../filter/LeaseSort";
import InstallmentSort from "../filter/InstallmentSort";
import CarProductCardSkeleton from "./CarProductCardSkeleton";

export default function CarList({ filter, data, isLease }) {
  const [isResult, setIsResult] = useState([]);
  const istmOptionArr = {
    value: [
      "최저금리 낮은 순",
      "최고금리 낮은 순",
      "출고가 낮은 순",
      "출고가 높은 순",
    ],
  };
  const leaseOptionArr = {
    value: [
      "최저가 낮은 순",
      "최고가 낮은 순",
      "출고가 낮은 순",
      "출고가 높은 순",
    ],
  };
  useEffect(() => {
    let items = [...data];
    items.sort((a, b) => {
      return a.release_price - b.release_price;
    });
    items.sort((a, b) => {
      return Number(a.lowest_interest) - Number(b.lowest_interest);
    });
    if (filter.condition !== undefined) {
      if (filter.condition.includes("수입") === true) {
        items = items.filter(function (currentValue) {
          return currentValue.condition.includes("수입");
        });
      } else {
        items = items.filter(function (currentValue) {
          return currentValue.condition.includes("국산");
        });
      }
    }
    if (filter.manufacturer !== "") {
      items = items.filter(function (currentValue) {
        return currentValue.manufacturer === filter.manufacturer;
      });
    }
    if (filter.type !== "") {
      items = items.filter(function (currentValue) {
        return currentValue.type === filter.type;
      });
    }
    if (filter.model !== "") {
      items = items.filter(function (currentValue) {
        return currentValue.car_name === filter.model;
      });
    }
    setIsResult(items);
  }, []);

  return (
    <>
      <motion.div
        className="results-sorter-installment"
        style={{
          boxShadow: "0px 4px 4px rgb(0 0 0 / 5%)",
        }}
      >
        <div className="sorter-left">
          <h5>
            총 <b>{isResult.length}</b>건
          </h5>
        </div>
        {isLease ? (
          <LeaseSort
            arr={isResult}
            setArr={setIsResult}
            isDetail={false}
            optionArr={leaseOptionArr}
          />
        ) : (
          <InstallmentSort
            arr={isResult}
            setArr={setIsResult}
            optionArr={istmOptionArr}
          />
        )}
      </motion.div>
      <section className="car-card-box">
        {isResult.length < 1
          ? [1, 2, 3, 4, 5].map((index) => (
              <CarProductCardSkeleton key={index} />
            ))
          : isResult.map((item, index) => (
              <CarProductCard key={index} item={item} isLease={isLease} />
            ))}
      </section>
    </>
  );
}
