import React, { useState } from "react";
import { useRecoilState } from "recoil";
import * as state from "recoil/atom";
// import edenLogo from "assets/eden-financial-logo-mobile.svg";
import { useForm } from "react-hook-form";
import { cdn_url } from "utils/api/_utils";
import BuildingAddress from "./screens/BuildingAddress";
import BuildingType from "./screens/BuildingType";
import BuildingOthers from "./screens/BuildingOthers";
import { useHistory, useLocation } from "react-router-dom";
const edenLogo = `${cdn_url}/assets/eden-financial-logo-mobile.svg`;

export default function MoreMortageHouseInfo() {
  const history = useHistory();
  const location = useLocation();
  const [isResult, setIsResult] = useState(false);
  const [userInfo, setUserInfo] = useRecoilState(state.userInfoState);
  const [houseInfo, setHouseInfo] = useState({
    building_type: "1",
    apartment_name: "",
    address: "",
    floor: "",
    flat_number: "",
    purchase_price: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const onFormValid = (data) => {
    console.log(data);
    setIsResult(true);
    history.push("/mortage-loan");
  };

  const onFormInvalid = (data) => {
    alert("입력항목을 확인해주세요");
    console.log(data);
  };

  const handleChangeValue = (event) => {
    setHouseInfo((prevState) => ({
      ...prevState,
      [`${event.target.name}`]: event.target.value,
    }));
  };

  return (
    <form
      className="more-information-container"
      onSubmit={handleSubmit(onFormValid, onFormInvalid)}
    >
      <div className="more-information-outer">
        <img src={edenLogo} alt="e-든든" />
        <div className="outer-textbox">
          <p>든든 담보대출 조회</p>
          <div className="indicator">
            <div className="pills" />
            <div className="pills" />
            <div className="pills active" />
            <div className="pills" />
          </div>
        </div>
      </div>
      <div className="more-information-inner">
        <div className="heading">
          <h2>주택 정보를 입력해주세요</h2>
          <p>정확한 금리 조회를 위한 필수 정보입니다.</p>
        </div>
        <BuildingType
          register={register}
          houseInfo={houseInfo}
          setHouseInfo={setHouseInfo}
          setValue={setValue}
        />
        <BuildingAddress
          register={register}
          errors={errors}
          handleChangeValue={handleChangeValue}
          houseInfo={houseInfo}
          getValues={getValues}
        />
        <BuildingOthers
          register={register}
          errors={errors}
          handleChangeValue={handleChangeValue}
          houseInfo={houseInfo}
          getValues={getValues}
          state={location.state.state}
        />
        <button className="confirm-btn">조회하기</button>
      </div>
    </form>
  );
}
