import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import $ from "jquery";
import { regexBackNum, regexNum } from "utils/regex";
import { useRecoilState } from "recoil";
import { userInfoState } from "recoil/atom";

function UserNumber({ register, errors, motionVariants, setValue }) {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  // const [backArray, setBackArray] = useState([]);
  const [backArray, setBackArray] = useState(userInfo.user_rrn_back.split(""));
  const user_rrn_front = userInfo.user_rrn_front;
  const user_rrn_back = userInfo.user_rrn_back;
  const user_rrn_back2 = userInfo.user_rrn_back2;

  const handleFrontValue = (event) => {
    setValue("user_rrn_front", regexNum(event.target.value));
    setUserInfo((prevState) => ({
      ...prevState,
      [`user_rrn_front`]: event.target.value,
    }));
  };

  const handleBackValue = () => {
    setUserInfo((prevState) => ({
      ...prevState,
      [`user_rrn_back2`]: regexBackNum($("input[name=user_rrn_back]").val()),
    }));
  };

  useEffect(() => {
    if (user_rrn_front?.length > 5) {
      document.getElementById("user_rrn_back").focus();
    }
  }, [user_rrn_front]);

  useEffect(() => {
    // user_rrn_back2의 맨 끝 숫자를 backArray 배열에 추가한다
    // delete키를 누를시 *이 추가되기 때문에 맨끝이 *이 아닐때만 으로 조건을 붙입니다
    if (user_rrn_back2.substr(-1) !== "*") {
      setBackArray((backArray) => [
        ...backArray,
        regexBackNum(user_rrn_back2.substr(-1)),
      ]);
    }

    // 값을 수정할때
    // delete키로 한개씩 지우거나, 드래그하여 지울 경우
    if (user_rrn_back.length > user_rrn_back2.length) {
      let differ = user_rrn_back.length - user_rrn_back2.length;
      setBackArray(backArray.slice(0, backArray.length - differ));
    }
  }, [user_rrn_back2]);

  useEffect(() => {
    setUserInfo((prevState) => ({
      ...prevState,
      [`user_rrn_back`]: regexBackNum(backArray.join("")),
    }));
  }, [backArray]);

  // 마스킹 처리하여 input value로 return
  function backInputValueNum() {
    // eslint-disable-next-line no-extend-native
    String.prototype.replaceAt = function (index, character) {
      return (
        this.substr(0, index) +
        character +
        this.substr(index + character.length)
      );
    };
    if (user_rrn_back2.length > 2) {
      return regexBackNum(
        user_rrn_back2.replaceAt(user_rrn_back2.length - 2, "*")
      );
    }
    return regexBackNum(user_rrn_back2);
  }

  function onBlurBackInput() {
    if (user_rrn_back2.length === 7) {
      setUserInfo((prevState) => ({
        ...prevState,
        [`user_rrn_back2`]: user_rrn_back2.substr(0, 1).concat("******"),
      }));
    }
  }

  useEffect(() => {
    // drag하여 delete키를 누르지 않고, 바로 값을 입력해 수정할 때
    // delete키를 누를시 배열에 *이 추가되기 때문에 *가 아닐때 라는 조건 추가
    if (
      user_rrn_back.length === user_rrn_back2.length &&
      user_rrn_back.substr(-1) !== user_rrn_back2.substr(-1) &&
      user_rrn_back2.substr(-1) !== "*"
    ) {
      setBackArray(backArray.slice(0, backArray.length - 1));
      setBackArray((backArray) => [
        ...backArray,
        regexBackNum(user_rrn_back2.substr(-1)),
      ]);
    }
    // 맨 끝자리만 드래그해서 바로 수정할 시
    else if (user_rrn_back.length > user_rrn_back2.length) {
      setBackArray(backArray.slice(0, backArray.length - 2));
      setBackArray((backArray) => [
        ...backArray,
        regexBackNum(user_rrn_back2.substr(-1)),
      ]);
    }
  }, [user_rrn_back]);

  return (
    <AnimatePresence>
      <motion.div
        className="input-container"
        initial="initial"
        animate="animate"
        variants={motionVariants}
      >
        <p className="input-title">
          주민등록번호
          {errors?.user_rrn_front || errors?.user_rrn_back ? (
            <span>올바른 형식의 주민등록번호를 입력해주세요</span>
          ) : null}
        </p>
        <div className="user-number">
          <div className="input-box">
            <input
              {...register("user_rrn_front", {
                required: true,
                onChange: handleFrontValue,
                // value: userInfo.user_rrn_front ? userInfo.user_rrn_front : "",
              })}
              autoFocus
              inputMode="numeric"
              autoComplete="off"
              autoCorrect="off"
              placeholder="주민등록번호"
              className={`input-text ${errors?.user_rrn_front ? "error" : ""}`}
              name="user_rrn_front"
              maxLength="6"
            />
          </div>
          <span style={{ margin: "10px" }}>ㅡ</span>
          <div className="input-box">
            <input
              {...register("user_rrn_back", {
                required: true,
                onChange: handleBackValue,
              })}
              inputMode="numeric"
              autoComplete="off"
              autoCorrect="off"
              className={`input-text ${errors?.user_rrn_back ? "error" : ""}`}
              name="user_rrn_back"
              id="user_rrn_back"
              onBlur={onBlurBackInput}
              maxLength="7"
              value={backInputValueNum()}
            />
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default UserNumber;
