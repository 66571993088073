import { TiArrowSortedDown } from "react-icons/ti";
import styled from "styled-components";
import colorVariables from "assets/scss/_variables.scss";

const SelectBox = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  transition: 0.3s;
`;

const Select = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 8px;
  z-index: 1;
  background-color: white;
  align-items: center;
  padding: 0 24px;
  border-bottom-left-radius: ${(props) => props.open === props.id && 0};
  border-bottom-right-radius: ${(props) => props.open === props.id && 0};
  span {
    font-weight: bold;
  }
`;

const Option = styled.ul`
  display: flex;
  flex-direction: ${(props) => (props.state === "1" ? "row" : "column")};
  position: absolute;
  top: ${(props) => (props.open === props.id ? "58px" : "40px")};
  background-color: white;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 0;
  background-color: white;
  transition: 0.3s;
  li {
    display: flex;
    align-items: center;
    flex-direction: ${(props) => props.state === "1" && "column"};
    width: ${(props) => props.state === "1" && "25%"};
    height: ${(props) => (props.open === props.id ? "inherit" : "0")};
    color: ${(props) => props.state === "1" && "#ccc"};
    font-size: ${(props) => props.state === "1" && "12px"};
    padding: ${(props) => (props.open === props.id ? "16px 24px" : "0px 24px")};
    border-bottom: ${(props) =>
      props.state === "1"
        ? "none"
        : props.open === props.id
        ? "1px solid #ccc"
        : "none"};
    list-style: none;
    transition: 0.3s;
    position: relative;
    &:last-child {
      border-bottom: none;
    }
  }
`;

const LogoBox = styled.div`
  display: flex;
  width: 88px;
  height: 88px;
  border-radius: 44px;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  background-color: ${colorVariables.mainLightColor};
`;

const DummyList = styled.div`
  position: absolute;
  background: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default function FilterSelectStyle({
  changeOptionState,
  openOption,
  setOpenOption,
  optionArr,
  selectId,
  carOption,
  state,
  modelNull,
}) {
  return (
    <SelectBox onClick={() => setOpenOption(openOption === "" ? selectId : "")}>
      <Select open={openOption} id={selectId}>
        <span>
          {carOption[selectId] === "" ? "선택해 주세요" : carOption[selectId]}
        </span>
        <TiArrowSortedDown
          style={{
            transform: openOption === selectId && "rotate(-180deg)",
            transition: "all 0.3s ease 0s",
          }}
        />
      </Select>
      <Option open={openOption} id={selectId} state={state}>
        {optionArr.map((data, idx) =>
          state === "1" ? (
            <li key={idx} id={selectId}>
              <DummyList id={data.labelName} onClick={changeOptionState} />
              <LogoBox>
                <img
                  src={data.img}
                  alt="logo"
                  style={{ height: openOption !== selectId && "0" }}
                />
              </LogoBox>
              {data.labelName}
            </li>
          ) : (
            <li
              key={idx}
              id={data.labelName}
              value={selectId}
              style={{ pointerEvents: modelNull && "none" }}
              onClick={changeOptionState}
            >
              {data.labelName}
            </li>
          )
        )}
      </Option>
    </SelectBox>
  );
}
