import { useRecoilState } from "recoil";
import styleVariables from "assets/scss/_variables.scss";
import * as state from "recoil/atom";

export default function HouseType({ register, errors }) {
  const regidenceTypeArray = [
    {
      id: "1",
      labelName: "아파트",
    },
    {
      id: "2",
      labelName: "단독",
    },
    {
      id: "3",
      labelName: "빌라/연립",
    },
    {
      id: "4",
      labelName: "기타",
    },
  ];
  return (
    <>
      <div className="select-input-box">
        <p className="select-input-title">
          주거형태
          {errors?.regidence_type ? (
            <span>{errors?.regidence_type?.message}</span>
          ) : null}
        </p>
        <div className="select-items">
          {regidenceTypeArray.map(({ id, labelName }) => (
            <SelectHouseType
              id={id}
              key={id}
              labelName={labelName}
              register={register}
            />
          ))}
        </div>
      </div>
    </>
  );
}

function SelectHouseType({ id, labelName, register }) {
  const [userInfo, setUserInfo] = useRecoilState(state.userInfoState);

  const handleChangeValue = (event) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [`${event.target.name}`]: event.target.value,
    }));
  };

  return (
    <div className="select-item">
      <input
        {...register("regidence_type", {
          required: "주거형태는 필수 항목입니다",
          onChange: handleChangeValue,
        })}
        id={`주거형태-${labelName}`}
        type="radio"
        name="regidence_type"
        className="hidden"
        value={id}
        checked={userInfo.regidence_type === id}
      />
      <label
        style={{
          backgroundColor:
            userInfo.regidence_type === id ? styleVariables.subColor : "",
          color: userInfo.regidence_type === id ? styleVariables.mainColor : "",
          fontWeight: userInfo.regidence_type === id ? "bold" : "",
          border: userInfo.regidence_type === id ? "none" : "solid 1px #ccc",
        }}
        htmlFor={`주거형태-${labelName}`}
      >
        {labelName}
      </label>
    </div>
  );
}
