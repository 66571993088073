import { regexNum } from "utils/regex";

export default function CreditScore({
  register,
  errors,
  handleChangeValue,
  moreInfo,
}) {
  return (
    <section className="text-input-box">
      <p className="text-input-title">
        내 신용점수
        {errors?.credit_score ? (
          <span>신용점수를 올바르게 입력해주세요</span>
        ) : null}
      </p>
      <div
        className="text-input-item"
        style={{ flexDirection: "row", alignItems: "flex-end" }}
      >
        <input
          {...register("credit_score", {
            required: true,
            onChange: handleChangeValue,
            pattern: /[0-9]/,
            max: 1000,
          })}
          inputMode="numeric"
          autoComplete="off"
          autoCorrect="off"
          placeholder="신용점수"
          name="credit_score"
          maxLength={4}
          className={`${errors?.credit_score ? "error" : null}`}
          value={regexNum(moreInfo.credit_score)}
        />
      </div>
    </section>
  );
}
