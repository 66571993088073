import React from "react";
import "./Introduction.scss";
// import edenlogo from "assets/introduction/intro-edenlogo.png";
// import heroBgImg from "assets/introduction/intro-hero.png";
// import roundBgImg from "assets/introduction/roundBackground.png";
// import roundBgImgMobile from "assets/introduction/roundBackground_mobile.png";
// import prizeIcon from "assets/introduction/intro-prize-icon.png";
// import companyIcon from "assets/introduction/intro-company-icon.png";
// import handshakeIcon from "assets/introduction/intro-handshake-icon.png";
import { BsChevronDown } from "react-icons/bs";
import { useRecoilValue } from "recoil";
import * as state from "recoil/atom";
import { cdn_url } from "utils/api/_utils";
const edenlogo = `${cdn_url}/assets/introduction/intro-edenlogo.png`;
const heroBgImg = `${cdn_url}/assets/introduction/intro-hero.png`;
const roundBgImg = `${cdn_url}/assets/introduction/roundBackground.png`;
const roundBgImgMobile = `${cdn_url}/assets/introduction/roundBackground_mobile.png`;
const prizeIcon = `${cdn_url}/assets/introduction/intro-prize-icon.png`;
const companyIcon = `${cdn_url}/assets/introduction/intro-company-icon.png`;
const handshakeIcon = `${cdn_url}/assets/introduction/intro-handshake-icon.png`;

export default function Introduction() {
  const windowSize = useRecoilValue(state.windowSizeAtom);

  function scrollToIntroduction() {
    window.scrollTo({
      top: windowSize.height - 70,
      behavior: "smooth",
    });
  }
  return (
    <main className="intro-container">
      <section
        className="intro-hero"
        style={{
          background: `linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url(${heroBgImg})`,
        }}
      >
        <img src={edenlogo} alt="이든든" />
        <h1>
          <b>최선의 선택</b>이 되어줄
          <br />
          <b>든든한 길잡이</b>가 {windowSize.width < 758 && <br />}되겠습니다.
        </h1>
        <BsChevronDown onClick={scrollToIntroduction} />
      </section>
      <section className="intro-introduction">
        <img
          className="intro-introduction-background"
          src={windowSize.width > 758 ? roundBgImg : roundBgImgMobile}
          alt="back"
        />
        <div className="introduction-heading">
          <p>전도유망한 핀테크 기업</p>
          <h2>이든파이낸셜</h2>
          <h4>성실, 가치창조, 고객만족</h4>
          {windowSize.width > 758 ? (
            <h4>기업철학을 토대로 기업가치를 높여 나가려 합니다.</h4>
          ) : (
            <h4>
              기업철학을 토대로 기업가치를
              <br />
              높여 나가려 합니다.
            </h4>
          )}
        </div>

        <div className="introduction-cards">
          <div className="card">
            <img src={prizeIcon} alt="인증된 기업" />
            <h5>
              신생 업체로서는 전국에서
              <br />
              유일하게 금융감독원의
              <br />
              엄격한 심사를 통과한 기업
            </h5>
          </div>
          <div className="card">
            <img src={companyIcon} alt="전도유망한 기업" />
            <h5>
              금융전문가들과 IT 구성원들이
              <br />
              함께하며 성장하고 있는
              <br />
              전도유망한 핀테크 기업
            </h5>
          </div>
          <div className="card">
            <img src={handshakeIcon} alt="믿을 수 있는 기업" />
            <h5>
              금융소비자의 합리적인 금융상품
              <br />
              선택을 돕고 싶은 마음으로
              <br />
              여러 은행, 여러 금융사와 협력
            </h5>
          </div>
        </div>
      </section>

      <section className="intro-history">
        <h2>History</h2>
        <div className="history-contents">
          <section>
            <div className="year-box">
              <h3>2022</h3>
              <h4>핀테크 기업 성장</h4>
            </div>
            <div className="month-boxes">
              <div className="month-box">
                <h4>01</h4>
                <p>
                  알고리즘 시스템 구축 및<br />
                  핀테크 사업 돌입
                </p>
              </div>
              <div className="month-box" style={{ alignItems: "center" }}>
                <h4>03</h4>
                <p>벤처기업 혁신성장유형 인증</p>
              </div>
              <div className="month-box">
                <h4>04</h4>
                <div>
                  <p>기업부설 연구소 설립 </p>
                  <p>
                    한국핀테크산업협회 KORFIN{" "}
                    {windowSize.width <= 1024 && <br />}
                    회원사 등록
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="year-box">
              <h3>2021</h3>
              <h4>온라인 모집법인 등록</h4>
            </div>
            <div className="month-boxes">
              {/* <div className="month-box">
                <h4 className="month-name">08</h4>
                <p>KB캐피탈 실적 상승</p>
              </div> */}
              <div className="month-box">
                <h4>12</h4>
                <p>
                  금융감독원 인증
                  <br />
                  온라인 모집법인 등록
                </p>
              </div>
            </div>
          </section>
          <section>
            <div className="year-box">
              <h3>2020</h3>
              <h4>이든파이낸셜 설립</h4>
            </div>
            <div className="month-boxes">
              <div className="month-box" style={{ alignItems: "center" }}>
                <h4>01</h4>
                <p>이든파이낸셜 설립</p>
              </div>
              <div className="month-box">
                <h4>02</h4>
                <p>
                  오프라인 모집 법인 <br />
                  KB캐피탈과 업무계약
                </p>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section className="intro-emptyspace"></section>
    </main>
  );
}
