import SelectStyle from "components/styledComponent/SelectStyle";
import { useState } from "react";

export default function LeaseSort({ arr, setArr, isDetail, optionArr }) {
  const [openOption, setOpenOption] = useState("");
  const [optionState, setOptionState] = useState(
    isDetail ? "리스료 최저가 순" : "최저가 낮은 순"
  );

  const switchOrderList = (event) => {
    const value = event.currentTarget.id;
    const items = [...arr];
    const lowPrice = () => {
      items.sort((a, b) => {
        return a.lowest_price - b.lowest_price;
      });
    };
    const highPrice = () => {
      items.sort((a, b) => {
        return a.greatest_price - b.greatest_price;
      });
    };
    const lowRelease = () => {
      items.sort((a, b) => {
        return a.release_price - b.release_price;
      });
    };
    const highRelease = () => {
      items.sort((a, b) => {
        return b.release_price - a.release_price;
      });
    };
    if (value === "최저가 낮은 순") {
      lowRelease();
      lowPrice();
    } else if (value === "최고가 낮은 순") {
      lowRelease();
      highPrice();
    } else if (value === "출고가 낮은 순") {
      lowPrice();
      lowRelease();
    } else if (value === "출고가 높은 순") {
      lowPrice();
      highRelease();
    }
    setOptionState(event.currentTarget.id);
    setArr(items);
  };

  const switchOrderDetail = (event) => {
    const value = event.currentTarget.id;
    const items = [...arr];
    if (value === "리스료 최저가 순") {
      items.sort((a, b) => {
        return a.month_price - b.month_price;
      });
    } else if (value === "리스료 최고가 순") {
      items.sort((a, b) => {
        return b.month_price - a.month_price;
      });
    }
    setOptionState(event.currentTarget.id);
    setArr(items);
  };

  return (
    <SelectStyle
      optionState={optionState}
      optionArr={optionArr.value}
      openOption={openOption}
      setOpenOption={setOpenOption}
      changeOptionState={
        isDetail === true ? switchOrderDetail : switchOrderList
      }
    />
  );
}
