export const calculator = {
  /* 만기 일시 상환 */
  calcBP: (loanAmount, yearToRepay, interestAYear) => {
    if (loanAmount <= 0 || yearToRepay <= 0 || interestAYear <= 0) {
      console.log("[ERROR] invalid parameter received");
      return;
    }

    const allMonthArr = Array.from(
      { length: yearToRepay * 12 - 2 },
      (_, items) => items + 2
    );

    // 매달 동일한 이자 계산
    const interest = Math.round(
      (loanAmount * interestAYear * yearToRepay) / (yearToRepay * 12 * 100)
    );

    // 첫 달 오브젝트
    const firstMonth = {
      monthNow: 1,
      interest,
      principal: 0,
      monthlyPayment: interest,
    };

    // 첫 달과 마지막 달을 제외한 오브젝트 배열
    const everyMonth = allMonthArr.map((monthNow) => {
      return {
        monthNow,
        interest,
        principal: 0,
        monthlyPayment: interest,
      };
    });

    // 마지막 달 오브젝트
    const lastMonth = {
      monthNow: yearToRepay * 12,
      interest: interest,
      principal: loanAmount,
      monthlyPayment: loanAmount + interest,
    };
    return [firstMonth, ...everyMonth, lastMonth];
  },

  /* 원금 균등 상환 */
  calcEPP: (loanAmount, yearToRepay, interestAYear) => {
    if (loanAmount <= 0 || yearToRepay <= 0 || interestAYear <= 0) {
      console.log("[ERROR] invalid parameter received");
      return;
    }

    // 2번째 달부터 마지막 달까지의 숫자가 담긴 배열 생성
    const allMonthArr = Array.from(
      { length: yearToRepay * 12 - 1 },
      (_, items) => items + 2
    );

    // 매달 동일한 원금 계산
    const principal = loanAmount / (yearToRepay * 12);

    // 첫 달 오브젝트
    const firstMonth = {
      monthNow: 1,
      interest: Math.round((principal / 100) * interestAYear * yearToRepay),
      principal: Math.round(principal),
      monthlyPayment:
        Math.round(principal) +
        Math.round((principal / 100) * interestAYear * yearToRepay),
    };

    // 첫 달 제외한 모든 달 오브젝트 배열
    const everyMonth = allMonthArr.map((month) => {
      const monthPast = month - 1;
      const interest =
        (loanAmount - principal * monthPast) * (interestAYear / 12 / 100);
      const monthlyPayment = Math.round(interest) + Math.round(principal);
      return {
        monthNow: month,
        interest: Math.round(interest),
        principal: Math.round(principal),
        monthlyPayment: Math.round(monthlyPayment),
      };
    });
    return [firstMonth, ...everyMonth];
  },

  /* 원금 균등 상환 (거치식) */
  calcEPPH: (loanAmount, yearToRepay, interestAYear, yearToHold) => {
    if (
      loanAmount <= 0 ||
      yearToRepay <= 0 ||
      interestAYear <= 0 ||
      yearToHold <= 0 ||
      yearToRepay <= yearToHold
    ) {
      console.log("[ERROR] invalid parameter received");
      return;
    }
    const holdingMonthArr = Array.from(
      { length: yearToHold * 12 },
      (_, items) => items + 1
    );
    const allMonthArr = Array.from(
      { length: (yearToRepay - yearToHold) * 12 },
      (_, items) => items + yearToHold * 12 + 1
    );

    // 거치 기간과 상관없이 대출 받은 금액을 단순히 상환개월수 만큼 나눠놓은 금액
    const originalPrincipal = loanAmount / (yearToRepay * 12);

    // 거치 기간 동안의 오브젝트 배열
    const holdingMonth = holdingMonthArr.map((monthNow) => {
      const interest = Math.round(
        (originalPrincipal / 100) * interestAYear * yearToRepay
      );
      return {
        monthNow,
        interest,
        monthlyPayment: interest,
        principal: 0,
      };
    });

    // 거치기간이 지난 만큼을 제외하고 계산한 한 달 원금
    const principal = loanAmount / (yearToRepay * 12 - yearToHold * 12);

    // 거치기간이 지난 이후의 오브젝트 배열
    const everyMonth = allMonthArr.map((monthNow, index) => {
      const monthPast = index;
      const interest =
        (loanAmount - principal * monthPast) * (interestAYear / 12 / 100);
      const monthlyPayment = Math.round(interest) + Math.round(principal);
      return {
        monthNow,
        interest: Math.round(interest),
        monthlyPayment: Math.round(monthlyPayment),
        principal: Math.round(principal),
      };
    });
    return [...holdingMonth, ...everyMonth];
  },

  /* 원리금 균등 상환 */
  calcFA: (loanAmount, yearToRepay, interestAYear) => {
    if (loanAmount <= 0 || yearToRepay <= 0 || interestAYear <= 0) {
      console.log("[ERROR] invalid parameter received");
      return;
    }

    const allMonthArr = Array.from(
      { length: yearToRepay * 12 - 1 },
      (_, items) => items + 2
    );

    // 남은 상환 햇수
    const restYear = yearToRepay;

    // 이자율을 계산에 용이한 형태로 변환
    const interestAsNum = interestAYear / 100 / 12;

    // 매달 동일한 납부금 계산
    const denominator = Math.pow(1 + interestAsNum, restYear * 12) - 1;
    const numerator =
      loanAmount * interestAsNum * Math.pow(1 + interestAsNum, restYear * 12);
    const monthlyPayment = numerator / denominator;

    // 첫 달 오브젝트
    const firstMonth = {
      monthNow: 1,
      interest: loanAmount * interestAsNum,
      principal: monthlyPayment - loanAmount * interestAsNum,
      monthlyPayment: monthlyPayment,
    };

    // 첫 달 제외한 모든 달 오브젝트 배열
    let accuPrincipal = firstMonth.principal;

    const everyMonth = allMonthArr.map((month) => {
      const restPaymentPast = loanAmount - accuPrincipal;
      const interest = restPaymentPast * interestAsNum;
      const principal = monthlyPayment - interest;
      const newMonth = {
        monthNow: month,
        interest: interest,
        principal: principal,
        monthlyPayment: monthlyPayment,
      };
      accuPrincipal = accuPrincipal + principal;
      return newMonth;
    });
    return [firstMonth, ...everyMonth];
  },

  /* 원리금 균등 상환 (거치식) */
  calcFAH: (loanAmount, yearToRepay, interestAYear, yearToHold = 0) => {
    // 매개변수 에러 핸들링
    if (
      loanAmount <= 0 ||
      yearToRepay <= 0 ||
      interestAYear <= 0 ||
      yearToHold <= 0 ||
      yearToRepay <= yearToHold
    ) {
      console.log("[ERROR] invalid parameter received");
      return;
    }

    const holdingMonthArr = Array.from(
      { length: yearToHold * 12 },
      (_, items) => items + 1
    );
    const allMonthArr = Array.from(
      { length: (yearToRepay - yearToHold) * 12 },
      (_, items) => items + yearToHold * 12 + 1
    );

    // 남은 상환 햇수
    const restYear = yearToRepay - yearToHold;

    // 이자율을 계산에 용이한 형태로 변환
    const interestAsNum = interestAYear / 100 / 12;

    // 매달 동일한 납부금 계산
    const denominator = Math.pow(1 + interestAsNum, restYear * 12) - 1;
    const numerator =
      loanAmount * interestAsNum * Math.pow(1 + interestAsNum, restYear * 12);
    const monthlyPayment = numerator / denominator;

    // 거치기간 오브젝트 배열
    const holdingMonth = holdingMonthArr.map((monthNow) => {
      const interest = Math.round(loanAmount * interestAsNum);
      return {
        monthNow,
        interest,
        principal: 0,
        monthlyPayment: interest,
      };
    });

    // 거치기간 이후의 오브젝트 배열
    let accuPrincipal = 0;
    const everyMonth = allMonthArr.map((monthNow) => {
      const restPaymentPast = loanAmount - accuPrincipal;
      const interest = restPaymentPast * interestAsNum;
      const principal = monthlyPayment - interest;
      const newMonth = {
        monthNow,
        interest: Math.round(interest),
        principal: Math.round(principal),
        monthlyPayment: Math.round(monthlyPayment),
      };
      accuPrincipal = accuPrincipal + principal;
      return newMonth;
    });
    return [...holdingMonth, ...everyMonth];
  },
};
