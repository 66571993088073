import _axios from "axios";

export const API_HOST = process.env.REACT_APP_CURRENT_API_SERVER_URL;
export const CDN_API_HOST = process.env.REACT_APP_CDN_API_SERVER_URL; // CDN 주소

// console.log("API_HOST >> ", API_HOST)
// console.log("API_HOST >> ", process.env.REACT_APP_Now_Mode);

const util = {
  get axios() {
    const instanceAxios = _axios.create({
      baseURL: API_HOST,
      // baseURL: MARU_API_HOST,
    });
    return instanceAxios;
  },
};

export const cdn_url = CDN_API_HOST;
export const axios = util.axios;
export default util;
