import { useRecoilState } from "recoil";
import * as state from "recoil/atom";
import { regexBusinessNum } from "utils/regex";
import $ from "jquery";
import styleVariables from "assets/scss/_variables.scss";

export default function Business({ register, errors, maxJoinMonth }) {
  const [userInfo, setUserInfo] = useRecoilState(state.userInfoState);
  const handleChangeValue = (event) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [`${event.target.name}`]: event.target.value,
      [`business_number`]: regexBusinessNum(
        $("input[name=business_number]").val()
      ),
    }));
  };

  const businessTypeArray = [
    {
      id: "1",
      labelName: "제조업",
    },
    {
      id: "2",
      labelName: "도소매업",
    },
    {
      id: "3",
      labelName: "운수업",
    },
    {
      id: "4",
      labelName: "기타",
    },
  ];

  return (
    <>
      <div className="text-input-box">
        <p className="text-input-title">
          사업자명
          {errors?.job_name ? (
            <span>사업자명은 필수 입력 항목입니다</span>
          ) : null}
        </p>
        <div className="text-input-item">
          <input
            {...register("job_name", {
              required: true,
              onChange: handleChangeValue,
            })}
            autoComplete="off"
            autoCorrect="off"
            placeholder="사업자명 입력"
            name="job_name"
            className={`job-information-company ${
              errors?.job_name ? "error" : null
            }`}
            value={userInfo.job_name}
          />
        </div>
      </div>
      <div className="text-input-box">
        <p className="text-input-title">
          사업자 번호
          {errors?.business_number ? (
            <span>{errors?.business_number?.message}</span>
          ) : null}
        </p>
        <div className="text-input-item">
          <input
            {...register("business_number", {
              required: "사업자 번호는 필수 입력 항목입니다",
              onChange: handleChangeValue,
            })}
            autoComplete="off"
            autoCorrect="off"
            placeholder="사업자 번호 입력"
            name="business_number"
            maxLength="10"
            className={`job-information-company ${
              errors?.business_number ? "error" : null
            }`}
            value={userInfo.business_number}
          />
        </div>
      </div>
      <div className="text-input-box">
        <p className="text-input-title">
          개업연월
          {errors?.join_year || errors?.join_month ? (
            <span>개업연월을 올바르게 입력해주세요</span>
          ) : null}
        </p>
        <div className="text-input-item" style={{ gap: "25px" }}>
          <section>
            <input
              {...register("join_year", {
                required: true,
                onChange: handleChangeValue,
                pattern: /[0-9]/,
                max: new Date().getFullYear(),
                min: new Date().getFullYear() - 100,
              })}
              autoComplete="off"
              autoCorrect="off"
              placeholder="연도(yyyy)"
              name="join_year"
              maxLength="4"
              className={`job-information-company ${
                errors?.join_year ? "error" : null
              }`}
              value={userInfo.join_year}
            />
            <span>년</span>
          </section>
          <section>
            <input
              {...register("join_month", {
                required: true,
                onChange: handleChangeValue,
                max: maxJoinMonth(),
                min: 1,
              })}
              autoComplete="off"
              autoCorrect="off"
              placeholder="월(mm)"
              name="join_month"
              maxLength="2"
              className={`job-information-company ${
                errors?.join_month ? "error" : null
              }`}
              value={userInfo.join_month}
            />
            <span>월</span>
          </section>
        </div>
      </div>
      <div className="select-input-box">
        <p className="select-input-title">
          업종
          {errors?.business_type ? (
            <span>{errors?.business_type?.message}</span>
          ) : null}
        </p>
        <div className="select-items">
          {businessTypeArray.map(({ id, labelName }) => (
            <SelectBusinessType
              id={id}
              labelName={labelName}
              key={id}
              userInfo={userInfo}
              handleChangeValue={handleChangeValue}
              register={register}
            />
          ))}
        </div>
      </div>
    </>
  );
}

function SelectBusinessType({
  id,
  labelName,
  userInfo,
  handleChangeValue,
  register,
}) {
  return (
    <div className="select-item">
      <input
        {...register("business_type", {
          required: "업종은 필수 입력 항목입니다",
          onChange: handleChangeValue,
        })}
        id={`업종-${labelName}`}
        type="radio"
        name="business_type"
        className="hidden"
        value={id}
        checked={userInfo.business_type === id}
      />
      <label
        style={{
          backgroundColor:
            userInfo.business_type === id
              ? styleVariables.subColor
              : "transparent",
          color:
            userInfo.business_type === id ? styleVariables.mainColor : "#ccc",
          fontWeight: userInfo.business_type === id ? "bold" : "",
          border: userInfo.business_type === id ? "none" : "solid 1px #ccc",
        }}
        htmlFor={`업종-${labelName}`}
      >
        {labelName}
      </label>
    </div>
  );
}
