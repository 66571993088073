export default function Agreement_Kinfa2() {
  // 개인정보 수집·이용·제공·조회 동의서(서민금융진흥원)
  return (
    <div className="agreement-container">
      <section>
        <b>[서민금융진흥원] 귀중</b>
        <span>
          서민금융진흥원(이하 ‘진흥원’이라 합니다.)과의 신용보증약정 또는
          채권관리 등과 관련하여 진흥원이 본인의 개인(신용)정보를
          수집·이용하거나 제3자에게 제공하고자 하는 경우에는 「개인정보 보호법」
          제15조제1항제1호, 제17조제1항제1호, 제24조제1항제1호, 제24조의2,
          「신용정보의 이용 및 보호에 관한 법률」 제15조제2항, 제32조제1항,
          제33조 및 제34조에 따라 본인의 동의를 얻어야 합니다. 이에 본인은
          진흥원이 아래의 내용과 같이 본인의 개인(신용)정보를
          수집·이용·제공·조회하는 것에 동의합니다.
        </span>
      </section>
      <section>
        <h3>1. 수집·이용에 관한 사항</h3>
        <h4 className="list-title">1-1. 수집·이용 목적</h4>
        <ul>
          <li>(1) (금융)거래와 관련한 개인(신용)정보 조회</li>
          <li>(2) 신용보증 거래관계의 설정 여부 판단 및 채권관리, 사후관리</li>
        </ul>
        <h4 className="list-title">1-2. 보유 및 이용기간</h4>
        <ul>
          <li>
            (1) 수집·이용에 관한 동의일로부터 신용정보 제공·조회 동의의 효력
            기간까지 보유·이용됩니다.
            <br />※ 단, 신용정보 제공·조회 동의의 효력 기간 종료 후에는 금융사고
            조사, 분쟁해결, 민원처리 및 법령상 의무이행을 위한 목적으로만
            보유·이용됩니다.
          </li>
        </ul>
        <h4 className="list-title">1-3. 거부 권리 및 불이익</h4>
        <ul>
          <li>
            (1) 위 개인(신용)정보의 수집·이용 동의를 거부하실 수 있습니다. 다만,
            위 개인(신용)정보 수집·이용에 관한 동의는 “(금융)거래계약의 체결 및
            이행을 위한” 필수적 사항이므로, 위 사항에 동의하지 않으실 경우
            신용보증 등 거래관계의 설정 또는 유지가 불가합니다.
          </li>
        </ul>
        <h4 className="list-title">1-4. 수집·이용 항목</h4>
        <ul>
          <li>
            (1) 고유식별정보(주민등록번호, 외국인등록번호, 여권번호,
            운전면허번호)
          </li>
          <li>
            (2) 개인(신용)정보
            <ul className="list-2depth">
              <li>⦁ 일반개인정보</li>
              <li>- 성명, 주소, 생년월일, 유‧무선 전화번호</li>
            </ul>
          </li>
        </ul>
      </section>
      <section className="last-section">
        <h3>2. 제공에 관한 사항 </h3>
        <h4 className="list-title">2-1. 제공·조회 대상기관</h4>
        <ul>
          <li>(1) 종합신용정보집중기관 : 한국신용정보원</li>
          <li>(2) 신용정보회사 : 코리아크레딧뷰로(주), NICE평가정보(주)</li>
        </ul>
        <h4 className="list-title">2-2. 제공·조회의 이용 목적</h4>
        <ul>
          <li>(1) (금융)거래와 관련한 개인(신용)정보 조회</li>
          <li>(2) 신용보증 거래관계의 설정여부 판단, 채권관리, 사후관리</li>
        </ul>
        <h4 className="list-title">2-3. 조회동의의 효력기간</h4>
        <ul>
          <li>
            (1) 조회 결과 귀하와의 신용보증 등 금융거래가 개시되는 경우에는
            금융거래 종료일까지 제공·조회 동의의 효력이 유지됩니다.
            <br />
            ※다만, 조회 결과 귀하가 신청한 신용보증이 거절된 경우에는 그
            시점부터 동의의 효력은 소멸합니다.
            <br />
            거래 종료일이란 계약상 일련의 과정(보증, 구상권 회수 등) 및
            서비스(전자금융거래 등)가 종료한 날 중 가장 나중에 도래한 사유를
            기준으로 판단한 날을 말합니다.
          </li>
        </ul>
        <h4 className="list-title">2-4. 거부 권리 및 불이익</h4>
        <ul>
          <li>
            (1) 위 개인(신용)정보의 제공·조회 동의를 거부하실 수 있습니다. 다만,
            위 개인(신용)정보 제공·조회에 관한 동의는 “(금융)거래계약의 체결 및
            이행을 위한” 필수적 사항이므로, 위 사항에 동의하지 않으실 경우
            신용보증 등 거래관계의 설정 또는 유지가 불가합니다.
          </li>
        </ul>
        <h4 className="list-title">2-5. 제공·조회 항목</h4>
        <ul>
          <li>
            (1) 고유식별정보(주민등록번호, 외국인등록번호, 여권번호,
            운전면허번호)
          </li>
          <li>
            (2) 개인(신용)정보
            <ul className="list-2depth">
              <li>⦁ 일반개인정보</li>
              <li>
                - 성명, 주소, 생년월일, 이메일, 유‧무선 전화번호, 성별, 국적,
                직업
              </li>
              <li>⦁ 신용거래정보</li>
              <li>
                - 대출(현금서비스 포함), 채무보증, 신용카드(체크카드 포함),
                당좌(가계당좌)예금 개설내역 등
              </li>
              <li>⦁ 신용도 판단정보</li>
              <li>
                - 연체‧대위변제‧대지급‧부도‧금융질서 문란 및 관련인 등록과
                관련된 정보 등
              </li>
              <li>⦁ 신용능력정보</li>
              <li>- 재산·채무·소득의 총액, 납세실적 등</li>
              <li>⦁ 공공정보</li>
              <li>
                - 법원 및 공공기관의 재판‧결정 또는 체납 등의 공공정보,
                신용회복지원정보, 개인신용평점(등급)등 기타 개인신용정보
              </li>
            </ul>
          </li>
        </ul>
        <span style={{ paddingTop: "30px" }}>
          ※ 신용정보회사를 통하여 귀하의 개인(신용)정보를 조회한 기록은 타
          금융기관 등에 제공될 수 있으며, 이에 따라 귀하의 신용점수가 하락할 수
          있음을 알려드립니다.
        </span>
      </section>
    </div>
  );
}
