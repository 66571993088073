// 할부, 리스
import React, { useEffect, useState } from "react";
// import { koscom } from "../koscom.js";
// import CarInfo from "./screens/carInfo/CarInfo.jsx";
// import FilterModal from "./screens/filterModal/FilterModal.jsx";

// import InstallmentFilter from "./screens/filter/InstallmentFilter.jsx";
// import LeaseFilter from "./screens/filter/LeaseFilter.jsx";
import { Route, Switch, useHistory } from "react-router-dom";
import CarInquire from "components/koscom/installmentOrLease/legacy/CarInquire";

function InstallmentOrLease({ match, location }) {
  const history = useHistory();
  const [isLease, setIsLease] = useState(false);
  const [isSearch, setIsSearch] = useState({
    istm: false,
    lease: false,
  });

  const [istmOption, setIstmOption] = useState({
    condition: "국산 신차",
    manufacturer: "",
    type: "",
    model: "",
    rate: "",
    period: "",
    creditScore: "",
    evaluator: "",
  });

  const [leaseOption, setLeaseOption] = useState({
    condition: "국산차",
    manufacturer: "",
    type: "",
    model: "",
    rate: "",
    period: "",
    creditScore: "",
    evaluator: "",
  });
  const filter_inst = JSON.parse(window.sessionStorage.getItem("installment"));
  const filter_lease = JSON.parse(window.sessionStorage.getItem("lease"));
  useEffect(() => {
    if (filter_inst !== null) {
      setIsSearch((prevState) => ({
        ...prevState,
        [`istm`]: true,
      }));
    }
    if (filter_lease !== null) {
      setIsSearch((prevState) => ({
        ...prevState,
        [`lease`]: true,
      }));
    }
  }, []);

  console.log(isSearch);
  console.log(isLease);

  // useEffect(() => {
  //   if (isLease) {
  //     setCarOption((prevState) => ({
  //       ...prevState,
  //       [`condition`]: "국산차",
  //     }));
  //   } else {
  //     setCarOption((prevState) => ({
  //       ...prevState,
  //       [`condition`]: "국산 신차",
  //     }));
  //   }
  // }, [isLease]);

  // useEffect(() => {
  //   setCarOption((prevState) => ({
  //     ...prevState,
  //     [`condition`]: prevState.condition,
  //     [`manufacturer`]: "",
  //     [`type`]: "",
  //     [`model`]: "",
  //     [`rate`]: "",
  //     [`period`]: "",
  //     [`creditScore`]: "",
  //     [`evaluator`]: "",
  //   }));
  // }, [carOption.condition]);

  function clearFilterOption() {
    if (isLease === false) {
      return setIstmOption({
        condition: "국산 신차",
        manufacturer: "",
        type: "",
        model: "",
        rate: "",
        period: "",
        creditScore: "",
        evaluator: "",
      });
    } else {
      return setLeaseOption({
        condition: "국산차",
        manufacturer: "",
        type: "",
        model: "",
        rate: "",
        period: "",
      });
    }
  }

  console.log(isSearch);

  return (
    <>
      <div className="loanable-list-container">
        <div className="loanable-list-outer">
          <div className="outer-textbox">
            <p>할부 / 리스</p>
          </div>
        </div>
        <div className="loanable-list-inner">
          <section className="installment-lease-container">
            <div className="select-loanway">
              <button
                className={!isLease ? "active" : ""}
                onClick={() => {
                  clearFilterOption();
                  isSearch.istm === true
                    ? history.push("/installment-or-lease/list")
                    : history.push("/installment-or-lease");
                  setIsLease(false);
                }}
              >
                할부
              </button>
              <button
                className={isLease ? "active" : ""}
                onClick={() => {
                  clearFilterOption();
                  isSearch.lease === true
                    ? history.push("/installment-or-lease/list")
                    : history.push("/installment-or-lease");
                  setIsLease(true);
                }}
              >
                리스
              </button>
            </div>
            <Switch>
              {/* <Route
                exact
                path="/installment-or-lease"
                component={() =>
                  isLease ? (
                    <LeaseFilter
                      leaseOption={leaseOption}
                      setLeaseOption={setLeaseOption}
                      setIsSearch={setIsSearch}
                    />
                  ) : (
                    <InstallmentFilter
                      istmOption={istmOption}
                      setIstmOption={setIstmOption}
                      setIsSearch={setIsSearch}
                    />
                  )
                }
              /> */}
              <Route
                exact
                path="/installment-or-lease/*"
                component={() => (
                  <CarInquire
                    isLease={isLease}
                    match={match}
                    location={location}
                    setIsSearch={setIsSearch}
                    clearFilterOption={clearFilterOption}
                  />
                )}
              />
            </Switch>
          </section>
        </div>
      </div>
    </>
  );
}

export default React.memo(InstallmentOrLease);
