import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import EmptyProducts from "./products/EmptyProducts";
import loanDetail from "../../loanDetail.json";
import Page404 from "components/error/Page404";

export default function LoanProducts({ match, location }) {
  const loanInfo = location.state.loanListArray.filter((data) => {
    // 대출조회마다 시퀀스가 달라짐으로 인해 해당 상품의 코드번호로 검색합니다.
    // return data.sequence === location.state.sequence;
    return data.product_code === location.state.productCode;
  });

  const loanDetailInfo = loanDetail.filter((data) => {
    // 대출조회마다 시퀀스가 달라짐으로 인해 해당 상품의 코드번호로 검색합니다.
    // if (data.sequence === location.state.sequence) {
    //   return data;
    // } else return null;
    if (data.productCode === location.state.productCode) {
      return data;
    } else return null;
  });
  return (
    <>
      <Switch>
        <Route path={`${location.pathname}`}>
          <EmptyProducts
            loanInfo={loanInfo[0]}
            detailInfo={loanDetailInfo[0]}
            how={location.state.how}
          />
        </Route>
      </Switch>
    </>
  );
}
