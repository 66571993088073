// 할부
import { Route, Switch } from "react-router-dom";
import InstallmentFilter from "./screens/installment/InstallmentFilter";
import InstallmentBranch from "./screens/installment/InstallmentBranch";
import Page404 from "components/error/Page404";

export default function Installment({ match, location }) {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={InstallmentFilter} />
        <Route path={`${match.path}/list`} component={InstallmentBranch} />
        <Route path={`${match.path}/*`} component={Page404} />
      </Switch>
    </>
  );
}
