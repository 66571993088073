import React from "react";
import { useRecoilState } from "recoil";
import styleVariables from "assets/scss/_variables.scss";
import * as state from "recoil/atom";
import { regexNum } from "utils/regex";
import { numToKorean } from "utils/numToKorean";

export default function Loan_Purpose({ register, errors, getValues }) {
  const [userInfo, setUserInfo] = useRecoilState(state.userInfoState);

  const handleChangeValue = (event) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [`${event.target.name}`]: event.target.value,
    }));
  };

  const loanPurposeArray = [
    "생활비",
    "자동차구입",
    "투자",
    "대환대출",
    "사업자금",
    "기타",
  ];

  function onBlurInput() {
    document.getElementById("sub-txt")?.setAttribute("style", "color: #999");
  }

  function onFocusInput() {
    document
      .getElementById("sub-txt")
      ?.setAttribute("style", `color: ${styleVariables.mainColor}`);
  }

  return (
    <>
      <div className="select-input-box">
        <p className="select-input-title">
          대출목적
          {errors?.purpose ? <span>{errors?.purpose?.message}</span> : null}
        </p>
        <div className="select-items">
          {loanPurposeArray.map((item) => (
            <SelectPurpose
              id={item}
              key={item}
              userInfo={userInfo}
              handleChangeValue={handleChangeValue}
              register={register}
            />
          ))}
        </div>
      </div>
      <div className="text-input-box">
        <p className="text-input-title">
          대출 희망 금액
          {errors?.amount ? <span>{errors?.amount?.message}</span> : null}
        </p>
        <div className="text-input-item" style={{ flexDirection: "row" }}>
          <input
            {...register("amount", {
              required: "대출 희망 금액은 필수 항목입니다",
              onChange: handleChangeValue,
              onBlur: onBlurInput,
              pattern: { value: /[0-9]/, message: "숫자만 입력할 수 있습니다" },
            })}
            inputMode="numeric"
            autoComplete="off"
            autoCorrect="off"
            placeholder="대출 희망 금액 입력"
            onFocus={onFocusInput}
            name="amount"
            maxLength="6"
            value={regexNum(userInfo.amount)}
            className={errors?.amount ? "error" : null}
          />
          <span>만원</span>
        </div>
        {getValues("amount") > 0 && (
          <span className="text-sub-title" id="sub-txt">
            {(getValues("amount") * 10000).toLocaleString("ko-KR")} (
            {numToKorean(getValues("amount") * 10000)}원)
          </span>
        )}
      </div>
    </>
  );
}

function SelectPurpose({ id, userInfo, handleChangeValue, register }) {
  return (
    <div className="select-item">
      <input
        {...register("purpose", {
          required: "대출목적은 필수 항목입니다",
          onChange: handleChangeValue,
        })}
        id={id}
        type="radio"
        name="purpose"
        value={id}
        className="hidden"
        checked={userInfo.purpose === id}
      />
      <label
        style={{
          backgroundColor:
            userInfo.purpose === id ? styleVariables.subColor : "transparent",
          color: userInfo.purpose === id ? styleVariables.mainColor : "#ccc",
          fontWeight: userInfo.purpose === id ? "bold" : "",
          border: userInfo.purpose === id ? "none" : "solid 1px #ccc",
        }}
        htmlFor={id}
      >
        {id}
      </label>
    </div>
  );
}
