import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { cdn_url } from "utils/api/_utils.js";
import { BankImgCdnMapping } from "utils/bankImgMapping";
import CarProductCard from "./CarProductCard";
import InstallmentSort from "../filter/InstallmentSort";
import LeaseSort from "../filter/LeaseSort";

export default function CarDetail({ location, isLease }) {
  // const location = useLocation();
  const history = useHistory();
  const itemInfo = location.state?.item;

  const [selectBankState, setSelectBankState] = useState("1");
  const [carDetail, setCarDetail] = useState([]);
  const [bankArr, setBankArr] = useState([]);

  useEffect(() => {
    setCarDetail(itemInfo);
    if (isLease === true) {
      setBankArr(leaseBank);
    } else {
      setBankArr(istmBank);
    }
  }, []);

  const istmBank = [
    {
      id: "1",
      bank: "SBI저축은행",
      bank_name: "SBI저축은행",
      greatest_interest: "16.8",
      lowest_interest: "3.3",
    },
    {
      id: "2",
      bank: "다올저축은행",
      bank_name: "다올저축은행",
      greatest_interest: "17.9",
      lowest_interest: "3.4",
    },
    {
      id: "3",
      bank: "카카오뱅크",
      bank_name: "카카오 뱅크",
      greatest_interest: "19.2",
      lowest_interest: "3.8",
    },
    {
      id: "4",
      bank: "웰컴저축은행",
      bank_name: "웰컴 저축은행",
      greatest_interest: "17.2",
      lowest_interest: "4.5",
    },
  ];

  const leaseBank = [
    {
      id: "1",
      bank: "SBI저축은행",
      bank_name: "SBI저축은행",
      month_price: "310000",
    },
    {
      id: "2",
      bank: "다올저축은행",
      bank_name: "다올저축은행",
      month_price: "325000",
    },
    {
      id: "3",
      bank: "카카오뱅크",
      bank_name: "카카오 뱅크",
      month_price: "321000",
    },
    {
      id: "4",
      bank: "웰컴저축은행",
      bank_name: "웰컴 저축은행",
      month_price: "321000",
    },
  ];

  const istmOptionArr = {
    value: ["최저금리 낮은 순", "최고금리 낮은 순"],
  };
  const leaseOptionArr = {
    value: ["리스료 최저가 순", "리스료 최고가 순"],
  };

  console.log(selectBankState);
  return (
    <>
      <section className="car-card-box">
        <CarProductCard item={carDetail} isLease={isLease} />
      </section>
      <motion.div className="results-sorter">
        <div className="sorter-left">
          <h5>
            총 <b>{bankArr.length}</b>건
          </h5>
        </div>
        {isLease ? (
          <LeaseSort
            arr={bankArr}
            setArr={setBankArr}
            isDetail={true}
            optionArr={leaseOptionArr}
          />
        ) : (
          <InstallmentSort
            arr={bankArr}
            setArr={setBankArr}
            optionArr={istmOptionArr}
          />
        )}
      </motion.div>
      <section className="car-detail-bank">
        {isLease ? (
          <>
            <div className="detail-head-lease">
              <span>은행명</span>
              <span className="center">월 리스료</span>
            </div>
            <ul className="detail-list">
              {bankArr.map((item, index) => (
                <li className="lease-li" key={index}>
                  <div
                    className="dummy"
                    id={item.id}
                    onClick={(e) => {
                      setSelectBankState(e.target.id);
                    }}
                  />
                  <div className="bank-name">
                    {selectBankState === item.id && (
                      <div className="selected">
                        <div />
                      </div>
                    )}
                    <img
                      src={`${cdn_url}/images/bank-images/${BankImgCdnMapping(
                        item.bank
                      )}.svg`}
                      alt="bank"
                    />
                    <span>{item.bank_name}</span>
                  </div>
                  <span className="center first" style={{ fontWeight: "bold" }}>
                    월 {Number(item.month_price).toLocaleString()}원
                  </span>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <>
            <div className="detail-head istm">
              <span>은행명</span>
              <span className="center">최고금리</span>
              <span className="center">최저금리</span>
            </div>
            <ul className="detail-list">
              {bankArr.map((item, index) => (
                <li className="istm-li" key={index}>
                  <div
                    className="dummy"
                    id={item.id}
                    onClick={(e) => {
                      setSelectBankState(e.target.id);
                    }}
                  />
                  <div className="bank-name">
                    {selectBankState === item.id && (
                      <div className="selected">
                        <div />
                      </div>
                    )}
                    <img
                      src={`${cdn_url}/images/bank-images/${BankImgCdnMapping(
                        item.bank
                      )}.svg`}
                      alt="bank"
                    />
                    <span>{item.bank_name}</span>
                  </div>
                  <span className="center first">
                    {item.greatest_interest}%
                  </span>
                  <span className="center last">{item.lowest_interest}%</span>
                </li>
              ))}
            </ul>
          </>
        )}
        <div className="car-detail-btns">
          <button
            onClick={() => {
              history.goBack();
            }}
          >
            목록보기
          </button>
          <button
            onClick={() => {
              alert(`${selectBankState}에 신청 요청`);
            }}
          >
            신청하기
          </button>
        </div>
      </section>
    </>
  );
}
