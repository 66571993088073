import { useEffect, useState } from "react";
import FilterSelectStyle from "components/styledComponent/FilterSelectStyle";

export default function CarSelelct({
  optionState,
  data,
  filter,
  openOption,
  setOpenOption,
  switchOrder,
  isLease,
}) {
  const optionNull = [{ labelName: "제조사/차종을 선택해주세요" }];
  const [installModel, setInstallModel] = useState([]);
  useEffect(() => {
    let items = [...data.car];
    items = items.filter(function (currentValue) {
      return currentValue.condition.includes(filter.condition);
    });
    if (filter.manufacturer !== "") {
      items = items.filter(function (currentValue) {
        return currentValue.manufacturer.includes(filter.manufacturer);
      });
    }
    if (filter.type !== "") {
      items = items.filter(function (currentValue) {
        return currentValue.type.includes(filter.type);
      });
    }
    setInstallModel(items);
  }, [filter]);

  return (
    <>
      <div style={{ zIndex: "3" }}>
        <p className="option-title">제조사</p>
        <FilterSelectStyle
          selectId="manufacturer"
          optionState={optionState}
          optionArr={
            data.condition[
              isLease === false
                ? filter.condition === "국산 신차"
                  ? 0
                  : 2
                : filter.condition === "국산차"
                ? 0
                : 1
            ].manufacture
          }
          openOption={openOption}
          setOpenOption={setOpenOption}
          changeOptionState={switchOrder}
          carOption={filter}
          state="1"
          modelNull={false}
        />
      </div>
      <div style={{ zIndex: "2" }}>
        <p className="option-title">차종</p>
        <FilterSelectStyle
          selectId="type"
          optionState={optionState}
          optionArr={data.type}
          openOption={openOption}
          setOpenOption={setOpenOption}
          changeOptionState={switchOrder}
          carOption={filter}
          state="0"
          modelNull={false}
        />
      </div>
      <div style={{ zIndex: "1" }}>
        <p className="option-title">모델</p>
        <FilterSelectStyle
          selectId="model"
          optionState={optionState}
          optionArr={
            filter.manufacturer === "" || filter.type === ""
              ? optionNull
              : installModel
          }
          modelNull={
            filter.manufacturer === "" || filter.type === "" ? true : false
          }
          openOption={openOption}
          setOpenOption={setOpenOption}
          changeOptionState={switchOrder}
          carOption={filter}
          state="0"
        />
      </div>
    </>
  );
}
