import React from "react";
import { useRecoilState } from "recoil";
import styleVariables from "assets/scss/_variables.scss";
import * as state from "recoil/atom";
import { regexNum } from "utils/regex";

export default function Office({ register, errors, maxJoinMonth, incomeType }) {
  const [userInfo, setUserInfo] = useRecoilState(state.userInfoState);

  const handleChangeValue = (event) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [`${event.target.name}`]: event.target.value,
    }));
  };

  const jobNameArray = [
    {
      id: "1",
      labelName: "정규직",
    },
    {
      id: "2",
      labelName: "연봉계약직",
    },
    {
      id: "3",
      labelName: "계약직",
    },
    // {
    //   id: "4",
    //   labelName: "프리랜서",
    // },
    {
      id: "5",
      labelName: "파견직",
    },
    {
      id: "6",
      labelName: "일용직",
    },
    {
      id: "7",
      labelName: "기타",
    },
  ];
  return (
    <>
      <div className="text-input-box">
        <p className="text-input-title">
          직장명
          {errors?.job_name ? <span>직장명은 필수 항목입니다</span> : null}
        </p>
        <div className="text-input-item">
          <input
            {...register("job_name", {
              required: true,
              onChange: handleChangeValue,
            })}
            autoComplete="off"
            autoCorrect="off"
            placeholder="직장명 입력"
            name="job_name"
            className={`job-information-company ${
              errors?.job_name ? "error" : null
            }`}
            value={userInfo.job_name}
          />
        </div>
      </div>
      <div className="text-input-box">
        <p className="text-input-title">
          입사연월
          {errors?.join_year || errors?.join_month ? (
            <span>입사연월을 올바르게 입력해주세요</span>
          ) : null}
        </p>
        <div
          className="text-input-item"
          style={{ gap: "25px", flexDirection: "row" }}
        >
          <section>
            <input
              {...register("join_year", {
                required: true,
                onChange: handleChangeValue,
                pattern: /[0-9]/,
                max: new Date().getFullYear(),
                min: new Date().getFullYear() - 100,
              })}
              inputMode="numeric"
              autoComplete="off"
              autoCorrect="off"
              placeholder="연도(yyyy)"
              maxLength="4"
              name="join_year"
              className={`job-information-company ${
                errors?.join_year ? "error" : null
              }`}
              value={regexNum(userInfo.join_year)}
            />
            <span>년</span>
          </section>
          <section>
            <input
              {...register("join_month", {
                required: true,
                onChange: handleChangeValue,
                max: maxJoinMonth(),
                min: 1,
              })}
              inputMode="numeric"
              autoComplete="off"
              autoCorrect="off"
              placeholder="월(mm)"
              maxLength="2"
              name="join_month"
              className={`job-information-company ${
                errors?.join_month ? "error" : null
              }`}
              value={regexNum(userInfo.join_month)}
            />
            <span>월</span>
          </section>
        </div>
      </div>

      {/* {       incomeType !== "6" ? */}
      {incomeType !== "6" && (
        <div className="select-input-box">
          <p className="select-input-title">
            고용형태
            {errors?.employment_type ? (
              <span>{errors?.employment_type?.message}</span>
            ) : null}
          </p>
          <div className="select-items">
            {jobNameArray.map(({ id, labelName }) => (
              <SelectJobType
                id={id}
                labelName={labelName}
                key={id}
                userInfo={userInfo}
                handleChangeValue={handleChangeValue}
                register={register}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

function SelectJobType({
  id,
  labelName,
  userInfo,
  handleChangeValue,
  register,
}) {
  return (
    <div className="select-item">
      <input
        {...register("employment_type", {
          required: "고용형태는 필수 항목입니다",
          onChange: handleChangeValue,
        })}
        id={`고용형태-${labelName}`}
        type="radio"
        name="employment_type"
        className="hidden"
        value={id}
        checked={userInfo.employment_type === id}
      />
      <label
        style={{
          backgroundColor:
            userInfo.employment_type === id
              ? styleVariables.subColor
              : "transparent",
          color:
            userInfo.employment_type === id ? styleVariables.mainColor : "#ccc",
          fontWeight: userInfo.employment_type === id ? "bold" : "",
          border: userInfo.employment_type === id ? "none" : "solid 1px #ccc",
        }}
        htmlFor={`고용형태-${labelName}`}
      >
        {labelName}
      </label>
    </div>
  );
}
