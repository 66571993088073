import { regexNum } from "utils/regex";

export default function BuildingAddress({
  register,
  errors,
  handleChangeValue,
  houseInfo,
}) {
  return (
    <section className="text-input-box">
      <p
        className="text-input-title"
        style={{ fontWeight: "bold", fontSize: "18px" }}
      >
        부동산 주소
      </p>
      {houseInfo.building_type === "1" && (
        <div className="text-input-item" style={{ alignItems: "flex-start" }}>
          <p className="input-sub-title">
            아파트 이름
            {errors?.apartment_name ? (
              <span>아파트 이름은 필수항목 입니다</span>
            ) : null}
          </p>
          <input
            {...register("apartment_name", {
              required: true,
              onChange: handleChangeValue,
            })}
            autoComplete="off"
            autoCorrect="off"
            placeholder="롯데캐슬"
            name="apartment_name"
            className={`job-information-company ${
              errors?.apartment_name ? "error" : null
            }`}
            value={houseInfo.apartment_name}
          />
        </div>
      )}
      <div className="text-input-item" style={{ alignItems: "flex-start" }}>
        <p className="input-sub-title">
          주소
          {errors?.address ? <span>주소는 필수항목 입니다</span> : null}
        </p>
        <input
          {...register("address", {
            required: true,
            onChange: handleChangeValue,
          })}
          autoComplete="off"
          autoCorrect="off"
          placeholder="서울특별시 강남구 도로명 00-0"
          name="address"
          className={`small-ph ${errors?.address ? "error" : null}`}
          value={houseInfo.address}
        />
      </div>
      <div className="text-input-item" style={{ alignItems: "flex-start" }}>
        <p className="input-sub-title">
          층/평수
          {errors?.floor || errors?.flat_number ? (
            <span>층/평수는 필수항목 입니다</span>
          ) : null}
        </p>
        <div
          className="text-input-item"
          style={{ gap: "25px", flexDirection: "row" }}
        >
          <section>
            <input
              {...register("floor", {
                required: true,
                onChange: handleChangeValue,
                pattern: /[0-9]/,
              })}
              inputMode="numeric"
              autoComplete="off"
              autoCorrect="off"
              placeholder="1"
              name="floor"
              maxLength={3}
              className={`small-ph ${errors?.floor ? "error" : null}`}
              value={regexNum(houseInfo.floor)}
            />
            <span>층</span>
          </section>
          <section>
            <input
              {...register("flat_number", {
                required: true,
                onChange: handleChangeValue,
                pattern: /[0-9]/,
              })}
              inputMode="numeric"
              autoComplete="off"
              autoCorrect="off"
              placeholder="60"
              maxLength={6}
              name="flat_number"
              className={`small-ph ${errors?.flat_number ? "error" : null}`}
              value={regexNum(houseInfo.flat_number)}
            />
            <span>평</span>
          </section>
        </div>
      </div>
    </section>
  );
}
