// CDN 은행사 이미지 매핑
export const BankImgCdnMapping = (bank_img) => {
  switch (bank_img) {
    case "데일리펀딩":
      return "Daily";
    case "다올저축은행":
      return "Daol";
    case "현대캐피탈":
      return "Hyundai";
    case "IBK기업은행":
      return "IBK";
    case "국민은행":
      return "KB";
    case "카카오뱅크":
      return "Kakao";
    case "SBI저축은행":
      return "SBI";
    case "신한은행":
      return "Shinhan";
    case "웰컴저축은행":
      return "Welcome";
    case "우리은행":
      return "Woori";
    case "오케이저축은행":
      return "OK";
    case "키움저축은행":
      return "Kiwoom";
    case "동원제일저축은행":
      return "Dongwon";
    case "테스트은행":
      return "Test";
    case "테스트저축은행":
      return "Test";
    default:
  }
};
