import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styleVariables from "assets/scss/_variables.scss";
import * as state from "recoil/atom";
import Business from "./job-group/Business";
import Office from "./job-group/Office";
import { regexNum } from "utils/regex";
import { numToKorean } from "utils/numToKorean";

export default function Job_Information({
  register,
  errors,
  setValue,
  getValues,
}) {
  const [incomeType, setIncomeType] = useState("");
  const [userInfo, setUserInfo] = useRecoilState(state.userInfoState);

  useEffect(() => {
    setIncomeType(userInfo.income_type);
  }, []);

  const handleChangeValue = (event) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [`${event.target.name}`]: event.target.value,
    }));
  };

  const handleIncomeTypeValue = (event) => {
    setIncomeType(event.target.value);

    setUserInfo((prevState) => ({
      ...prevState,
      [`income_type`]: event.target.value,
    }));
    if (event.target.value === "6") {
      setUserInfo((prevState) => ({
        ...prevState,
        [`income_type`]: "4",
        [`employment_type`]: "4",
      }));
    }
  };

  const incomeTypeArray = [
    {
      id: "1",
      index: "1",
      labelName: "직장인",
    },
    {
      id: "2",
      index: "2",
      labelName: "공무원",
    },
    {
      id: "5",
      index: "5",
      labelName: "개인사업자",
    },
    {
      id: "6",
      index: "1",
      labelName: "프리랜서",
    },
    {
      id: "3",
      index: "3",
      labelName: "주부",
    },
    {
      id: "4",
      index: "4",
      labelName: "기타",
    },
  ];

  const date = new Date();
  const maxJoinMonth = () => {
    if (date.getFullYear() === Number(userInfo.join_year)) {
      return date.getMonth() + 1;
    } else {
      return 12;
    }
  };

  function employmentTypeComponent() {
    switch (incomeType) {
      case "1":
      case "2":
      case "6":
        return (
          <Office
            register={register}
            errors={errors}
            maxJoinMonth={maxJoinMonth}
            incomeType={incomeType}
          />
        );
      case "5":
        return (
          <Business
            register={register}
            errors={errors}
            maxJoinMonth={maxJoinMonth}
          />
        );
      default:
    }
  }

  function onBlurInput() {
    document
      .getElementById("sub-txt-salary")
      ?.setAttribute("style", "color: #999");
  }

  function onFocusInput() {
    document
      .getElementById("sub-txt-salary")
      ?.setAttribute("style", `color: ${styleVariables.mainColor}`);
  }

  return (
    <>
      <div className="text-input-box">
        <p className="text-input-title">
          연소득
          {errors?.salary ? <span>{errors?.salary?.message}</span> : null}
        </p>
        <div className="text-input-item" style={{ flexDirection: "row" }}>
          <input
            {...register("salary", {
              required: "연소득은 필수 항목입니다",
              pattern: { value: /[0-9]/, message: "숫자만 입력할 수 있습니다" },
              onBlur: onBlurInput,
              onChange: handleChangeValue,
            })}
            inputMode="numeric"
            autoComplete="off"
            autoCorrect="off"
            placeholder="연소득(세전) 입력"
            name="salary"
            maxLength="6"
            value={regexNum(userInfo.salary)}
            onFocus={onFocusInput}
            className={errors?.salary ? "error" : null}
          />
          <span>만원</span>
        </div>
        {getValues("salary") > 0 && (
          <span className="text-sub-title" id="sub-txt-salary">
            {(getValues("salary") * 10000).toLocaleString("ko-KR")} (
            {numToKorean(getValues("salary") * 10000)}원)
          </span>
        )}
      </div>
      <div className="select-input-box">
        <p className="select-input-title">
          직군분류
          {errors?.income_type ? (
            <span>{errors?.income_type?.message}</span>
          ) : null}
        </p>
        <div className="select-items">
          {incomeTypeArray.map(({ id, labelName }) => (
            <SelectIncome
              id={id}
              labelName={labelName}
              key={id}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              handleChangeValue={handleIncomeTypeValue}
              register={register}
              setValue={setValue}
              incomeType={incomeType}
            />
          ))}
        </div>
      </div>
      {employmentTypeComponent()}
      {/* {incomeType === "1" && (
        <Office register={register} errors={errors} />
      )}
      {incomeType === "5" && (
        <Business register={register} errors={errors}  />
      )} */}
    </>
  );
}

function SelectIncome({
  id,
  labelName,
  index,
  userInfo,
  setUserInfo,
  handleChangeValue,
  register,
  setValue,
  incomeType,
}) {
  const changeJobType = (event) => {
    setUserInfo((prevState) => ({
      ...prevState,
      business_type: "",
      business_number: "",
      employment_type: "",
      job_name: "",
      join_year: "",
      join_month: "",
    }));
  };

  const onIncomeTypeChanges = (event) => {
    changeJobType(event);
    handleChangeValue(event);
    setValue("job_name", "");
    setValue("join_year", "");
    setValue("join_month", "");
    setValue("business_number", "");
    setValue("business_type", "");
  };
  return (
    <div className="select-item">
      <input
        {...register("income_type", {
          required:
            userInfo.income_type === "" ? "직군분류는 필수 항목입니다" : null,
          onChange: (event) => onIncomeTypeChanges(event),
        })}
        id={`직군분류-${labelName}`}
        type="radio"
        name="income_type"
        className="hidden"
        value={id}
        checked={userInfo.income_type === index}
        readOnly
      />
      <label
        style={{
          backgroundColor:
            incomeType === id ? styleVariables.subColor : "transparent",
          color: incomeType === id ? styleVariables.mainColor : "#ccc",
          fontWeight: incomeType === id ? "bold" : "",
          border: incomeType === id ? "none" : "solid 1px #ccc",
        }}
        htmlFor={`직군분류-${labelName}`}
      >
        {labelName}
      </label>
    </div>
  );
}
