import { motion, useAnimation, useViewportScroll } from "framer-motion";
import { useEffect } from "react";

function BottomIndicator() {
  const { scrollY } = useViewportScroll();
  const indicatorAnimation = useAnimation();

  useEffect(() => {
    if (scrollY.get() > 1) {
      indicatorAnimation.start({
        bottom: "-26px",
      });
    }
  }, []);

  useEffect(
    () =>
      scrollY.onChange(() => {
        if (scrollY.get() < 1) {
          indicatorAnimation.start({
            bottom: "-41px",
          });
        } else if (scrollY.get() > 1) {
          indicatorAnimation.start({
            bottom: "-26px",
          });
        }
      }),
    [scrollY]
  );

  return (
    <motion.div
      className="bottom-indicator"
      transition={{ type: "tween", duration: 0.1 }}
      animate={indicatorAnimation}
      style={{ bottom: scrollY.get() < 1 ? "-41px" : "-26px" }}
    />
  );
}

export default BottomIndicator;
