import { atom } from "recoil";

// 개발
export const windowSizeAtom = atom({
  key: "windowSizeAtom",
  default: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
});

// 사용자 정보
export const userInfoState = atom({
  key: "userInfoState",
  default: {
    // user_name: "", // 고객 이름
    // user_rrn_front: "", // 고객 주민등록번호(앞)
    user_rrn_back: "", // 고객 주민등록번호(뒤)
    user_rrn_back2: "", // 고객 주민등록번호(뒤)
    // user_phone: "", // 고객 휴대폰 번호
    // carrier: "", // 통신사
    user_agreement: "", // 약관동의여부
    purpose: "", // 대출 목적
    amount: "", // 대출 희망 금액
    salary: "", // 연간 소득
    income_type: "", // 직군분류
    employment_type: "", // 고용형태
    house_ownership_type: "", // 주거 소유 형태
    job_name: "", // 직장명
    join_year: "", // 현 직장 입사년도
    join_month: "", // 입사월
    plate_number: "", // 소유 차량 정보
    business_type: "", // 개인사업자 업종
    business_number: "", // 사업자 번호
    health_insurance_type: "", // 가입자구분
    regidence_type: "", // 주거 형태
  },
});

// export const selectOfficeState = atom({
//   key: "selectOfficeState",
//   default: "",
// });

export const selectHouseTypeState = atom({
  key: "selectHouseTypeState",
  default: false,
});

export const loanListState = atom({
  key: "",
  default: {},
});

export const mobileNaviState = atom({
  key: "mobileNaviState",
  default: false,
});

// 사업제휴문의
export const partnershipInfo = atom({
  key: "partnershipInfoState",
  default: {
    storeName: "",
    ceoName: "",
    managerName: "",
    tel: "",
    content: "",
    otp: "",
  },
});

// 재방문 여부 코드
export const revisitCodeState = atom({
  key: "revisitCodeState",
  default: "",
});

// 문자인증 otp Token
export const tokenUidState = atom({
  key: "tokenUidState",
  default: "",
});
