import { regexNum } from "utils/regex";
import styleVariables from "assets/scss/_variables.scss";

export default function MortageFamilyInfo({
  register,
  errors,
  handleChangeValue,
  moreInfo,
}) {
  const marriageArray = ["미혼", "신혼", "기혼"];
  const houseArray = ["무주택", "1주택", "2주택 이상"];
  const childrenArray = ["없음", "1명", "2명 이상"];
  return (
    <>
      <section className="select-input-box">
        <p className="select-input-title">
          결혼 여부
          {errors?.marriage ? <span>결혼 여부는 필수 항목입니다</span> : null}
        </p>
        <div className="select-items-mortage grid-three">
          {marriageArray.map((item) => (
            <div className="select-item" key={item}>
              <input
                {...register("marriage", {
                  required: true,
                  onChange: handleChangeValue,
                })}
                id={item}
                type="radio"
                name="marriage"
                value={item}
                className="hidden"
                checked={moreInfo.marriage === item}
              />
              <label
                style={{
                  backgroundColor:
                    moreInfo.marriage === item
                      ? styleVariables.subColor
                      : "transparent",
                  color:
                    moreInfo.marriage === item
                      ? styleVariables.mainColor
                      : "#ccc",
                  fontWeight: moreInfo.marriage === item ? "bold" : "",
                  border:
                    moreInfo.marriage === item ? "none" : "solid 1px #ccc",
                }}
                htmlFor={item}
              >
                {item}
              </label>
            </div>
          ))}
        </div>
      </section>
      <section className="select-input-box">
        <p className="select-input-title">
          보유중인 주택 (배우자 소유 포함)
          {errors?.house ? <span>보유주택은 필수 항목입니다</span> : null}
        </p>
        <div className="select-items-mortage grid-three">
          {houseArray.map((item) => (
            <div className="select-item" key={item}>
              <input
                {...register("house", {
                  required: true,
                  onChange: handleChangeValue,
                })}
                id={item}
                type="radio"
                name="house"
                value={item}
                className="hidden"
                checked={moreInfo.house === item}
              />
              <label
                style={{
                  backgroundColor:
                    moreInfo.house === item
                      ? styleVariables.subColor
                      : "transparent",
                  color:
                    moreInfo.house === item ? styleVariables.mainColor : "#ccc",
                  fontWeight: moreInfo.house === item ? "bold" : "",
                  border: moreInfo.house === item ? "none" : "solid 1px #ccc",
                }}
                htmlFor={item}
              >
                {item}
              </label>
            </div>
          ))}
        </div>
      </section>
      <section className="select-input-box">
        <p className="select-input-title">
          자녀 수
          {errors?.children ? <span>자녀수는 필수 항목입니다</span> : null}
        </p>
        <div className="select-items-mortage grid-three">
          {childrenArray.map((item) => (
            <div className="select-item" key={item}>
              <input
                {...register("children", {
                  required: true,
                  onChange: handleChangeValue,
                })}
                id={item}
                type="radio"
                name="children"
                value={item}
                className="hidden"
                checked={moreInfo.children === item}
              />
              <label
                style={{
                  backgroundColor:
                    moreInfo.children === item
                      ? styleVariables.subColor
                      : "transparent",
                  color:
                    moreInfo.children === item
                      ? styleVariables.mainColor
                      : "#ccc",
                  fontWeight: moreInfo.children === item ? "bold" : "",
                  border:
                    moreInfo.children === item ? "none" : "solid 1px #ccc",
                }}
                htmlFor={item}
              >
                {item}
              </label>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
