import { AnimatePresence, motion } from "framer-motion";
import AgreementEdenden from "./terms-content-data/AgreementEdenden";
import AgreementBank from "./terms-content-data/AgreementBank";
import AgreementBank2 from "./terms-content-data/AgreementBank2";
import AgreementBank3 from "./terms-content-data/AgreementBank3";
import AgreementKinfa from "./terms-content-data/AgreementKinfa";
import AgreementKinfa2 from "./terms-content-data/AgreementKinfa2";
import AgreementInsurance from "./terms-content-data/AgreementInsurance";
import "./terms-content-data/AgreementContent.scss";
// import logo from "assets/eden-financial-logo-mobile.svg";
import { cdn_url } from "utils/api/_utils";
const logo = `${cdn_url}/assets/eden-financial-logo-mobile.svg`;

export default function TermsModal({
  status,
  heading,
  setTermsModalShown,
  label,
  windowSize,
}) {
  const bankSwitch = () => {
    switch (heading) {
      case "개인(신용)정보 수집·이용·제공 동의서":
        return <AgreementBank />;
      case "개인(신용)정보 수집·이용·제공·조회 동의서":
        return <AgreementBank2 />;
      case "개인(신용)정보 제3자 제공 동의서":
        return <AgreementBank3 />;
      default:
        return null;
    }
  };
  const kinfaSwitch = () => {
    switch (heading) {
      case "개인(신용)정보 수집·이용·제공 동의서":
        return <AgreementKinfa />;
      case "개인(신용)정보 수집·이용·제공·조회 동의서":
        return <AgreementKinfa2 />;
      default:
        return null;
    }
  };
  return (
    <AnimatePresence>
      {status ? (
        <motion.div
          key="modal"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="terms-modal-container"
          style={{ height: window.innerHeight }}
        >
          <motion.div
            initial={{ opcaity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "tween" }}
            className="modal-box"
            style={{
              height: windowSize.width > 758 ? "80vh" : window.innerHeight,
            }}
          >
            <div className="modal-header-logo">
              <img src={logo} alt="이든 파이낸셜" />
            </div>
            <div className="modal-header">
              <h2 className="modal-header-h2">{heading}</h2>
            </div>
            <div className="modal-body" style={{ display: "block" }}>
              {label === "이든든" ? <AgreementEdenden /> : ""}
              {label === "금융기관" ? bankSwitch() : ""}
              {label === "서민금융진흥원" ? kinfaSwitch() : ""}
              {label === "서울보증보험" ? <AgreementInsurance /> : ""}
            </div>
            <div className="modal-footer">
              <button
                className="terms-bottom-btn"
                onClick={() => setTermsModalShown(false)}
              >
                닫기
              </button>
            </div>
          </motion.div>
          <div
            className="modal-background"
            onClick={() => setTermsModalShown(false)}
          />
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
