import { TiArrowSortedDown } from "react-icons/ti";
import styled from "styled-components";

const SelectBox = styled.div`
  display: flex;
  font-size: 15px;
  height: 48px;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 12px;
  cursor: pointer;
`;

const Select = styled.div`
  display: flex;
  gap: 8px;
`;

const Option = styled.ul`
  position: absolute;
  top: 48px;
  background-color: white;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 8px;
  z-index: 1;
  li {
    padding: 16px 0;
    text-align: center;
    border-bottom: 1px solid #ccc;
    list-style: none;
    &:last-child {
      border-bottom: none;
    }
  }
`;

export default function SelectOption({
  optionState,
  changeOptionState,
  openOption,
  setOpenOption,
  optionArr,
}) {
  return (
    <SelectBox onClick={() => setOpenOption(!openOption)}>
      <Select>
        <span>{optionState}</span>
        <TiArrowSortedDown
          style={{
            transform: openOption && "rotate(-180deg)",
            transition: "all 0.3s ease 0s",
          }}
        />
      </Select>
      {openOption && (
        <Option>
          {optionArr.map((data, idx) => (
            <li key={idx} id={data} onClick={changeOptionState}>
              {data}
            </li>
          ))}
        </Option>
      )}
    </SelectBox>
  );
}
