import { motion, AnimatePresence } from "framer-motion";

function UserPhone({
  userInfo,
  register,
  errors,
  motionVariants,
  handleChangeValue,
  formCounter,
}) {
  return (
    <AnimatePresence>
      <motion.div
        className="input-container"
        initial="initial"
        animate="animate"
        variants={motionVariants}
      >
        <h3 className="input-title">
          휴대폰번호
          {errors?.user_phone && <span>{errors?.user_phone?.message}</span>}
        </h3>
        <div className="input-box">
          <input
            {...register("user_phone", {
              required: "휴대폰 번호는 필수 입력 항목입니다.",
              onChange: handleChangeValue,
              pattern: {
                value:
                  formCounter > 1 &&
                  /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})/,
                message: "올바른 휴대폰 번호 형식이 아닙니다",
              },
            })}
            autoFocus
            name="user_phone"
            inputMode="numeric"
            autoComplete="off"
            autoCorrect="off"
            placeholder="'-' 빼고 입력"
            defaultValue={userInfo.user_phone}
            className={`input-text ${errors?.user_phone ? "error" : ""}`}
            maxLength="13"
          />
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default UserPhone;
