import styleVariables from "assets/scss/_variables.scss";

export default function BuildingType({ houseInfo, setHouseInfo, setValue }) {
  function SelectHIType({ id, labelName }) {
    const hiTypeButton = {
      backgroundColor:
        houseInfo.building_type === id ? styleVariables.subColor : "",
      color: houseInfo.building_type === id ? styleVariables.mainColor : "",
      fontWeight: houseInfo.building_type === id ? "bold" : "",
      border: houseInfo.building_type === id ? "none" : "solid 1px #ccc",
    };

    return (
      <div className="select-item">
        <input
          id={`건축물유형-${labelName}`}
          type="radio"
          name="building_type"
          className="hidden"
          value={id}
          onChange={() => {
            setHouseInfo((prevState) => ({
              ...prevState,
              building_type: id,
              apartment_name: "",
              address: "",
              floor: "",
              flat_number: "",
              purchase_price: "",
              credit_score: "",
            }));
            setValue("apartment_name", "");
          }}
          // checked={hitInputValue === id}
        />
        <label style={hiTypeButton} htmlFor={`건축물유형-${labelName}`}>
          {labelName}
        </label>
      </div>
    );
  }
  return (
    <section className="select-input-box">
      <p className="select-input-title">건축물 유형</p>
      <div className="select-items-mortage grid-two">
        <SelectHIType id="1" labelName="아파트" />
        <SelectHIType id="2" labelName="그 외" />
      </div>
    </section>
  );
}
