import { useState } from "react";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import colorVariables from "assets/scss/_variables.scss";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { windowSizeAtom } from "recoil/atom";

import { cdn_url } from "utils/api/_utils";
import { BankImgCdnMapping } from "utils/bankImgMapping";

import { numToKorean } from "utils/numToKorean";

function ProductCard({
  lowest,
  greatest,
  showSingle,
  bankName,
  productName,
  interest,
  amount,
  productCode,
  sequence,
  data,
  title,
  how,
  PrnlRt,
  ImDpAYN,
  is_progressed,
  bridge_url,
}) {
  const [hover, setHover] = useState(false);
  const history = useHistory();
  const windowSize = useRecoilValue(windowSizeAtom);
  const badgeAnimation = useAnimation();
  // const containerAnimation = useAnimation();

  const onMouseOver = () => {
    // containerAnimation.start({
    //   filter: "drop-shadow(0 0 5px rgba(0, 0, 0, 0.15)",
    //   transition: { duration: 0.5 },
    // });
    badgeAnimation
      .start({ opacity: 0, transition: { duration: 0.2 } })
      .then(() => setHover(true));
  };
  const onMouseLeave = () => {
    // containerAnimation.start({
    //   filter: "drop-shadow(0 0 0px rgba(0, 0, 0, 0)",
    // });
    setHover(false);
    badgeAnimation.start({ opacity: 1, transition: { duration: 0.2 } });
  };
  const onMouseDown = () => {
    history.push({
      pathname: `/loan-products/${productCode}`,
      state: {
        sequence: sequence,
        productCode: productCode,
        loanListArray: data,
        how: how,
      },
    });
  };

  return (
    <>
      <motion.div
        className="product-card-container"
        onMouseOver={windowSize.width > 758 ? onMouseOver : null}
        onMouseLeave={windowSize.width > 758 ? onMouseLeave : null}
        onMouseDown={windowSize.width < 759 ? onMouseDown : null}
      >
        <div className="product-title">
          <div className="title">
            <div className="bank-img">
              <img
                src={`${cdn_url}/images/bank-images/${BankImgCdnMapping(
                  bankName
                )}.svg`}
                alt="bank"
              />
            </div>
            <div className="bank-name">{bankName}</div>
          </div>
          <div className="badges">
            {lowest && !hover ? (
              <motion.div className="badge" animate={badgeAnimation}>
                최저금리
              </motion.div>
            ) : null}
            {(greatest || showSingle) && !hover ? (
              <motion.div className="badge" animate={badgeAnimation}>
                최대한도
              </motion.div>
            ) : null}
            {!hover && (
              <>
                {ImDpAYN === "Y" ? (
                  <motion.div className="badge">
                    <motion.p animate={badgeAnimation}>당일입금</motion.p>
                  </motion.div>
                ) : null}
                {PrnlRt === "Y" ? (
                  <motion.div className="badge">
                    <motion.p animate={badgeAnimation}>우대금리</motion.p>
                  </motion.div>
                ) : null}
              </>
            )}
            <AnimatePresence>
              {hover && (
                <motion.div
                  className="learn-more"
                  onClick={onMouseDown}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                >
                  <motion.p>더 알아보기</motion.p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
        <div className="product-body">
          <h3>{productName}</h3>
          <div className="product-infos">
            <div
              className="info"
              style={{
                color: lowest ? colorVariables.mainColor : "",
              }}
            >
              <h5>{title === "신용대출" ? "금리" : "연 예상 금리"}</h5>
              <p>{interest}%</p>
            </div>
            <div
              className="info"
              style={{
                color: greatest || showSingle ? colorVariables.mainColor : "",
              }}
            >
              <h5>{title === "신용대출" ? "한도" : "최대한도"}</h5>
              <p>{amount.toLocaleString()}원</p>
              <span
                style={{
                  color: greatest || showSingle ? colorVariables.mainColor : "",
                }}
              >
                ({numToKorean(amount)}원)
              </span>
            </div>
          </div>
        </div>
        {(lowest || greatest || showSingle) && (
          <div className="product-point-border" />
        )}
      </motion.div>
    </>
  );
}

export default ProductCard;
