import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import * as state from "recoil/atom";
import TermsModal from "./screens/terms-content/Terms_Modal";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UserPhone from "./screens/basic-info/UserPhone";
import Carrier from "./screens/basic-info/Carrier";
import UserNumber from "./screens/basic-info/UserNumber";
import { regexPhone, regexExp } from "utils/regex";
import { validate } from "utils/vaildateUserNumber";
import AllAgreeTermsContent from "./screens/terms-content/All_Agree_Terms_Content";
import "./Self_Certification.scss";
import { cdn_url } from "utils/api/_utils";
import { useLocation } from "react-router-dom";
const edenLogo = `${cdn_url}/assets/eden-financial-logo-mobile.svg`;

export default function Self_Certification(props) {
  const [userInfo, setUserInfo] = useRecoilState(state.userInfoState);
  const history = useHistory();
  const location = useLocation();
  const windowSize = useRecoilValue(state.windowSizeAtom);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm();
  useEffect(() => window.scrollTo(0, 0), []);

  // 모달, 약관
  const [isAllAgree, setIsAllAgree] = useState(false);
  const [termsModalShown, setTermsModalShown] = useState({
    status: false,
    heading: "",
  });
  useEffect(() => {
    if (termsModalShown.status === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [termsModalShown.status]);

  // 순차 폼 환경설정
  const [formCounter, setFormCounter] = useState(0);
  const [showForm, setShowForm] = useState([
    { id: "user_name", status: true },
    { id: "user_phone", status: false },
    { id: "carrier", status: false },
    { id: "user_number", status: false },
    { id: "agreement", status: false },
  ]);
  const defaultUserInfo = {
    user_agreement: userInfo.user_agreement ?? "",
    user_rrn_back: userInfo.user_rrn_back ?? "",
    user_rrn_back2: userInfo.user_rrn_back2 ?? "",
    purpose: userInfo.purpose ?? "",
    amount: userInfo.amount ?? "",
    salary: userInfo.salary ?? "",
    income_type: userInfo.income_type ?? "",
    employment_type: userInfo.employment_type ?? "",
    house_ownership_type: userInfo.house_ownership_type ?? "",
    job_name: userInfo.job_name ?? "",
    join_year: userInfo.join_year ?? "",
    join_month: userInfo.join_month ?? "",
    plate_number: userInfo.plate_number ?? "",
    business_type: userInfo.business_type ?? "",
    business_number: userInfo.business_number ?? "",
    health_insurance_type: userInfo.health_insurance_type ?? "100",
    regidence_type: userInfo.regidence_type ?? "",
  };

  const user_rrn_front = userInfo.user_rrn_front;
  const user_rrn_back = userInfo.user_rrn_back;
  // 폼 통과했을 때 실행
  const onFormValid = (data) => {
    if (data.user_phone?.length < 11) {
      setError("user_phone", {
        message: "올바른 휴대폰 번호 형식이 아닙니다",
      });
      return;
    } else {
      if (formCounter < 3) {
        setFormCounter((prev) => prev + 1);
      } else {
        if (
          /([0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1,2][0-9]|3[0,1]))/.test(
            user_rrn_front
          ) === false
        ) {
          setError("user_rrn_front", { shouldFocus: true });
          return;
        }
        if (Boolean(validate(user_rrn_front, user_rrn_back)) === false) {
          setError("user_rrn_back", { shouldFocus: true });
          return;
        }
        if (!isAllAgree) {
          switchAllAgree();
          return;
        } else {
          const newUserInfo = {
            ...data,
            user_phone: data.user_phone.split("-").join(""),
            ...defaultUserInfo,
          };
          // window.sessionStorage.setItem("user", newUserInfo.user_name);
          setUserInfo(newUserInfo);
          if (props.how === "담보") {
            // history.push("/mortage-loan");
            history.push("/mortage-certification/more");
          } else if (props.how === "신용") {
            history.push("/credit-certification/more");
          } else if (props.how === "할부") {
            history.push("/installment");
          }
        }
      }
    }
  };

  // 인풋 순차 표시
  const [submitText, setSubmitText] = useState("다음");
  useEffect(() => {
    if (formCounter < 4) {
      let showFormCopy = [...showForm];
      const nextChange = { ...showForm[formCounter], status: true };
      showFormCopy[formCounter] = nextChange;
      setShowForm([...showFormCopy]);
    }
    if (formCounter === 2) {
      getRevisitCheck();
      // setShowExistModal(true);
    }
    if (formCounter === 3) {
      setSubmitText("본인인증 하기");
    }
  }, [formCounter]);

  const [revisitCode, setRevisitCode] = useRecoilState(state.revisitCodeState); // 재방문 코드

  const getRevisitCheck = () => {
    setRevisitCode(0);
  };

  const handleChangeValue = (event) => {
    if (formCounter === 2) {
      setFormCounter((prev) => prev + 1);
    }
    setUserInfo((prevState) => ({
      ...prevState,
      [`${event.target.name}`]: event.target.value,
    }));
  };

  const handlePhoneValue = (event) => {
    setValue("user_phone", regexPhone(event.target.value));
    // setValue("user_phone", event.target.value); // API 통신 시 대쉬 제거
    setUserInfo((prevState) => ({
      ...prevState,
      [`user_phone`]: regexExp(event.target.value),
    }));
  };

  // 폼 에러 발생했을 때
  const onFormInvalid = (data) => {
    console.log(">>>error!", data);
  };

  // 인풋 애니메이션 정의
  const motionVariants = {
    initial: { y: 50, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { type: "tween", duration: 0.3 } },
  };

  // 약관동의
  const [agreeEachCheck, setAgreeEachCheck] = useState({
    이든든: false,
    금융기관: false,
    서민금융진흥원: false,
    서울보증보험: false,
  }); // 약관 개별 동의

  // 전체 동의서
  const switchAllAgree = () => {
    const tmpObj = { ...agreeEachCheck };
    if (isAllAgree === true) {
      for (let key in tmpObj) {
        tmpObj[key] = false;
      }
      setIsAllAgree(false);
      setAgreeEachCheck(tmpObj);
    } else {
      for (let key in tmpObj) {
        tmpObj[key] = true;
      }
      setIsAllAgree(true);
      setAgreeEachCheck(tmpObj);
    }

    setUserInfo((prevState) => ({
      ...prevState,
      [`user_agreement`]: !isAllAgree ? "1" : "0",
    }));
  };

  return (
    <>
      <TermsModal
        {...termsModalShown}
        setTermsModalShown={setTermsModalShown}
        windowSize={windowSize}
      />
      <form
        onSubmit={handleSubmit(onFormValid, onFormInvalid)}
        className="self-certification-container"
      >
        <div className="self-certification-outer">
          <img src={edenLogo} alt="e-든든" />
          <div className="outer-textbox">
            <p>본인인증</p>
            {props.how !== "할부" && (
              <div className="indicator">
                <div className="pills active" />
                <div className="pills" />
                <div className="pills" />
                {location.pathname === "/mortage-certification" && (
                  <div className="pills" />
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className="self-certification-inner"
          style={{
            minHeight: windowSize.width <= 758 && window.innerHeight - 100,
          }}
        >
          <div className="heading">
            <h2>
              {location.pathname === "/ism-certification"
                ? "할부/리스를 받기 위해"
                : "맞춤 대출을 받기 위해"}
              <br />
              정보가 필요해요
            </h2>
            <p>고객님 본인임을 확인하기 위한 절차입니다</p>
          </div>
          <div className="input-container">
            <h3 className="input-title">
              이름
              {errors?.user_name ? (
                <span>{errors?.user_name?.message}</span>
              ) : null}
            </h3>
            <div className="input-box">
              <input
                {...register("user_name", {
                  required: "이름은 필수 입력 항목입니다",
                  onChange: handleChangeValue,
                  pattern: {
                    value: /^[가-힣]{2,20}$/,
                    message: "2글자 이상의 올바른 한글 이름을 적어주세요",
                  },
                })}
                autoFocus
                autoComplete="off"
                autoCorrect="off"
                placeholder="이름 입력"
                defaultValue={userInfo.user_name}
                className={`input-text ${errors?.user_name ? "error" : ""}`}
              />
            </div>
          </div>
          {showForm[1].status && (
            <UserPhone
              userInfo={userInfo}
              register={register}
              errors={errors}
              setValue={setValue}
              motionVariants={motionVariants}
              setUserInfo={setUserInfo}
              handleChangeValue={handlePhoneValue}
              formCounter={formCounter}
            />
          )}
          {showForm[2].status && revisitCode === 0 && (
            <Carrier
              userInfo={userInfo}
              register={register}
              errors={errors}
              motionVariants={motionVariants}
              handleChangeValue={handleChangeValue}
            />
          )}
          {showForm[3].status && revisitCode === 0 && (
            <>
              <UserNumber
                register={register}
                errors={errors}
                motionVariants={motionVariants}
                setValue={setValue}
              />
              <AllAgreeTermsContent
                setTermsModalShown={setTermsModalShown}
                isAllAgree={isAllAgree}
                setIsAllAgree={setIsAllAgree}
                switchAllAgree={switchAllAgree}
                agreeEachCheck={agreeEachCheck}
                setAgreeEachCheck={setAgreeEachCheck}
                userInfo={userInfo}
              />
            </>
          )}
          {formCounter === 3 && !isAllAgree ? (
            <div
              className={`sc-button ${
                submitText === "본인인증 하기" && !isAllAgree ? "inactive" : ""
              } `}
              onClick={() =>
                formCounter === 3 &&
                !isAllAgree &&
                alert("모든 약관에 동의해주셔야 합니다")
              }
            >
              <span>{submitText}</span>
            </div>
          ) : (
            <button className={`sc-button ${submitText === "본인인증 하기"}`}>
              <span>{submitText}</span>
            </button>
          )}
        </div>
      </form>
    </>
  );
}
