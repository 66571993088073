import { regexNum } from "utils/regex";
import styleVariables from "assets/scss/_variables.scss";
import { numToKorean } from "utils/numToKorean";

export default function MortagePurpose({
  register,
  errors,
  handleChangeValue,
  moreInfo,
  getValues,
}) {
  const mortagePurposeArray = ["구매자금", "전세자금", "생활안정자금"];
  return (
    <>
      <section className="select-input-box">
        <p className="select-input-title">
          대출목적
          {errors?.purpose ? <span>대출목적은 필수 항목입니다</span> : null}
        </p>
        <div className="select-items-mortage grid-three">
          {mortagePurposeArray.map((item) => (
            <div className="select-item" key={item}>
              <input
                {...register("purpose", {
                  required: true,
                  onChange: handleChangeValue,
                })}
                id={item}
                type="radio"
                name="purpose"
                value={item}
                className="hidden"
                checked={moreInfo.purpose === item}
              />
              <label
                style={{
                  backgroundColor:
                    moreInfo.purpose === item
                      ? styleVariables.subColor
                      : "transparent",
                  color:
                    moreInfo.purpose === item
                      ? styleVariables.mainColor
                      : "#ccc",
                  fontWeight: moreInfo.purpose === item ? "bold" : "",
                  border: moreInfo.purpose === item ? "none" : "solid 1px #ccc",
                }}
                htmlFor={item}
              >
                {item}
              </label>
            </div>
          ))}
        </div>
      </section>
      <section className="text-input-box">
        <p className="text-input-title">
          대출희망금액
          {errors?.amount ? (
            <span>대출희망금액을 올바르게 입력해주세요</span>
          ) : null}
        </p>
        <div
          className="text-input-item"
          style={{ flexDirection: "row", alignItems: "flex-end" }}
        >
          <input
            {...register("amount", {
              required: true,
              onChange: handleChangeValue,
              pattern: /[0-9]/,
            })}
            inputMode="numeric"
            autoComplete="off"
            autoCorrect="off"
            placeholder="대출 희망 금액"
            name="amount"
            maxLength={6}
            className={`${errors?.amount ? "error" : null}`}
            value={regexNum(moreInfo.amount)}
          />
          <span>만원</span>
        </div>
        {getValues("amount") > 0 && (
          <span className="text-sub-title" id="sub-txt-salary">
            {(getValues("amount") * 10000).toLocaleString("ko-KR")} (
            {numToKorean(getValues("amount") * 10000)}원)
          </span>
        )}
      </section>
    </>
  );
}
