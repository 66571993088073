export default function Agreement_Insurance() {
  // (필수)계약 체결·이행등을 위한 필수동의서(서울보증보험)
  return (
    <div className="agreement-container">
      <section>
        <p>2022.02.14</p>
        <b>[서울보증보험(주)] 귀중</b>
        <span>
          귀하는 개인(신용)정보의 수집‧이용 및 조회, 제공에 관한 동의를 거부하실
          수 있으며, 개인의 신용도 등을 평가하기 위한 목적 이외의 개인(신용)정보
          제공 동의는 철회할 수 있습니다. 다만, 본 동의는 ‘보험계약
          인수심사‧체결‧이행‧유지‧관리’를 위해 필수적인 사항이므로 동의를
          거부하시는 경우 관련 업무 수행이 불가능합니다. 또한 본 동의서에 의한
          개인(신용)정보 조회는 귀하의 개인신용평점(등급)에 영향을 주지
          않습니다(다만, 귀하의 개인신용평점(등급)이 존재하지 않는 경우는
          제외합니다).
        </span>
      </section>
      <section>
        <h3>1. 수집·이용에 대한 사항</h3>
        <h4 className="list-title">1-1. 수집·이용 목적</h4>
        <ul>
          <li>보험계약의 인수심사‧체결‧이행‧유지‧관리(변경 및 갱신 포함)</li>
          <li>
            (1) 통계처리(모형개발‧요율산출 및 검증 등), 민원처리 및 분쟁 대응,
            금융거래 관련 업무
          </li>
          <li>
            (2) 보험모집질서의 유지(공정경쟁질서 유지에 관한 협정업무 포함)
          </li>
          <li>(3) 가입한 보험계약 상담, 법률 및 국제 협약 등의 의무 이행 </li>
          <li>(4) 보험사고 조사(보험사기 조사 포함) 및 보험금 지급‧심사</li>
          <li>(5) 대위변제시 대위권 행사</li>
        </ul>
        <h4 className="list-title">1-2. 보유 및 이용기간</h4>
        <ul>
          <li>
            (1) 동의일로부터 거래 종료 후 5년까지
            <br />
            (단, 다른 관련 법령에 해당하는 경우 해당 법령상의 보존기간을 따름)
          </li>
          <li>(2) 계약미체결시 : 정보 제공일로부터 최대 3개월</li>
        </ul>
        <span>
          ※위 보유 기간에서의 거래 종료일이란 “1 당사와의 모든 계약(보험계약,
          보험금 지급, 대위 변제 채권, 담보 제공 등)의 만기, 해지‧해제‧취소,
          철회일 또는 소멸일 2 보험금 청구권 소멸시효 완성일(상법 제662조),
          채권‧채무 관계 소멸일 및 그 밖의 사유로 거래관계가 종료한 날 중 가장
          나중에 도래한 사유를 기준으로 판단한 날”을 말합니다.
        </span>
        <h4 className="list-title">1-3. 수집·이용 항목간</h4>
        <ul>
          <li>
            (1) 고유식별정보 주민등록번호, 외국인등록번호, 여권번호,
            운전면허번호
          </li>
          <li>
            (2) 개인(신용)정보
            <ul className="list-2depth">
              <li>⦁ 일반개인정보</li>
              <li>
                - 성명, 주소, 생년월일, 이메일, 유‧무선 전화번호, 성별, 국적,
                직업, 국내거소신고번호
              </li>
              <li>⦁ 신용거래정보</li>
              <li>
                - 금융거래 업무 관련 정보(대출‧보증‧담보제공 등의 종류, 기간,
                금액 및 한도를 포함한 금융거래현황, 보험료 출‧수납을 위한
                계좌정보 등), 보험계약정보(상품종류, 기간, 보험료, 보증대상
                계약‧거래내역에 관한 정보 등), 보험금정보(보험금 지급사유,
                지급금액 등), 계약 전 알릴 의무사항
              </li>
              <li>⦁ 신용도판단정보</li>
              <li>
                - 연체‧대위변제‧대지급‧부도‧금융질서 문란 및 관련인 등록과
                관련된 금액, 발생, 해소 시기 등에 관한 정보
              </li>
              <li>⦁ 신용능력정보</li>
              <li>
                - 재산‧채무‧소득의총액, 납세실적, 사회보험료납부여부,
                영업현황등거래능력을판단할수있는정보, 직장정보
              </li>
              <li>⦁ 공공정보</li>
              <li>
                - 법원 및 공공기관의 재판‧결정 또는 체납 등의 공공정보,
                자동차보험계약정보, 보증대상계약 관련 차대번호·부동산 고유번호,
                개인신용평점(등급), 보험계약자와 연대보증인의 관계 등 기타
                개인신용정보, 분리보관정보
              </li>
            </ul>
          </li>
        </ul>
      </section>
      <section>
        <h3>2. 제공에 관한 사항 </h3>
        <h4 className="list-title">2-1. 제공받는 자</h4>
        <ul>
          <li>
            (1) 공공기관 등 : 금융위원회, 금융감독원, 국세청, 감사원,
            예금보험공사 등 법령상 업무 수행기관(위탁사업자 포함), 국토교통부
          </li>
          <li>(2) 종합신용정보집중기관: 한국신용정보원</li>
          <li>
            (3) 신용정보회사 : 코리아크레딧뷰로(주), NICE평가정보(주),
            한국기업데이터(주)
          </li>
          <li>(4) 보험계약의 피보험자</li>
          <li>(5) 보험회사 등 : 국내 재보험사, 공제사업자</li>
          <li>(6) 금융거래기관 : 계좌개설 금융기관, 금융결제원</li>
          <li>(7) 보험협회 : 생명‧손해보험협회</li>
          <li>(8) 기 체결 보험계약의 차대번호로 신규 보험계약을 신청한 자</li>
          <li>
            (9) 부동산 매매 전‧월세 관련 기 체결 보험계약에 대해 동일 목적물로
            신규보험계약을 신청한 자
          </li>
        </ul>
        <h4 className="list-title">2-2. 제공받는 자의 이용목적</h4>
        <ul>
          <li>
            (1) 공공기관 등 : 법령에 따른 업무수행(위탁업무 포함), 보증대상 계약
            관련 차대번호 확인 업무 수행
          </li>
          <li>
            (2) 종합신용정보집중기관 : 개인(신용)정보 조회, 신용정보의 집중관리
            및 활용 등 법령에서 정한 종합신용정보집중기관의 업무수행
          </li>
          <li>(3) 금융기관 : 본인 확인, 금융거래 업무 등</li>
          <li>(4) 기타기관 : 본인의 신용판단 등</li>
          <li>
            (5) 보험회사 등: 재보험 가입 및 재보험 청구 등 재보험 업무, 중복보험
            확인 및 비례보상
          </li>
          <li>(6) 금융거래기관 : 금융거래 업무</li>
          <li>
            (7) 보험협회 : 보험계약 관련 업무지원, 공정경쟁 질서유지에 관한 협정
            업무
          </li>
          <li>
            (8) 기 체결 보험계약의 차대번호로 신규 보험계약을 신청한 자 :
            보험사기 조사 등 보험계약의 체결‧유지‧관리에 필요한 업무 수행
          </li>
          <li>
            (9) 부동산 매매, 전‧월세 관련 기 체결 보험계약에 대해 동일 목적물로
            신규보험계약을 신청한 자 : 보험사기조사 등 보험계약의
            체결‧유지‧관리에 필요한 업무 수행
          </li>
        </ul>
        <h4 className="list-title">2-3. 제공 및 이용기간</h4>
        <ul>
          <li>
            (1)제공받는 자의 이용목적을 달성할 때까지 (관련 법령상 보존기간을
            따름) ※외국 재보험사의 국내지점이 재보험계약 가입 판단 지원,
            보험계약 공동인수 지원 업무를 위탁하기 위한 경우 별도의 동의 없이
            외국 소재 본점에 귀하의 정보를 이전할 수 있습니다.
          </li>
        </ul>
        <h4 className="list-title">2-4. 제공 항목</h4>
        <ul>
          <li>
            (1) 고유식별정보 주민등록번호, 외국인등록번호, 여권번호,
            운전면허번호
          </li>
          <li>
            (2) 개인(신용)정보
            <ul className="list-2depth">
              <li>⦁ 일반개인정보</li>
              <li>
                - 성명, 주소, 생년월일, 이메일, 유‧무선 전화번호, 성별, 국적,
                직업,국내거소신고번호
              </li>
              <li>⦁ 신용거래정보</li>
              <li>
                - 금융거래 업무 관련 정보(대출‧보증‧담보제공 등의 종류, 기간,
                금액 및 한도 등 금융거래현황, 보험료 출‧ 수납을 위한 계좌정보
                등), 보험계약정보(보증대상 계약‧거래내역에 관한 정보, 상품종류,
                기간, 보험료 등), 보험금정보(보험금 지급사유, 지급금액 등), 계약
                전 알릴 의무사항
              </li>
              <li>⦁ 신용도판단정보</li>
              <li>
                - 연체‧대위변제‧대지급‧부도‧금융질서 문란 및 관련인 등록과
                관련된 금액, 발생‧해소 시기 등에 관한 정보
              </li>
            </ul>
          </li>
        </ul>
        <span style={{ paddingTop: "30px" }}>
          ※ 업무위탁을 목적으로 개인(신용)정보를 처리하는 경우 별도의 동의 없이
          업무 수탁자에게 귀하의 정보를 제공할 수 있습니다. (홈페이지
          [www.sgic.co.kr]) 신용정보활용체제 에서 업무수탁자 명단 확인 가능)
        </span>
      </section>
      <section className="last-section">
        <h3>3. 조회에 관한 사항 </h3>
        <h4 className="list-title">3-1. 조회 대상 기관</h4>
        <ul>
          <li>
            (1) 종합신용정보집중기관, 신용정보회사, 금융결제원,
            전자서명인증사업자
          </li>
        </ul>
        <h4 className="list-title">3-2. 조회목적</h4>
        <ul>
          <li>
            (1) 종합신용정보집중기관, 신용정보회사 : 보험계약의
            인수심사‧체결‧이행‧유지‧관리(변경 및 갱신 포함), 보험사고
            조사(보험사기 조사 포함) 및 보험금 지급‧심사, 대위변제시 대위권
            행사, 통계처리(모형 개발, 요율 산출 및 검증 등), 민원처리 및
            분쟁대응, 금융거래 관련 업무, 실명인증
          </li>
          <li>
            (2) 금융결제원 : 부도/당좌거래정지 여부 조회, 실명인증, 본인인증,
            전자서명인증
          </li>
        </ul>
        <h4 className="list-title">
          3-3. 조회 동의의 효력기간 당 사의 조회 결과 귀하와의 보험거래가
          개시되는 경우 해당 거래종료일까지 제공‧조회 동의의 효력이 지속됩니다.
          다만, 당 사의 조회 결과 귀하가 신청한 보험 거래의 설정이 거절된
          경우에는 그 시점부터 동의의 효력은 소멸합니다.
        </h4>
        <h4 className="list-title">3-4. 조회 항목</h4>
        <ul>
          <li>
            (1) 고유식별정보 주민등록번호, 외국인등록번호, 여권번호,
            운전면허번호
          </li>
          <li>(2) 개인(신용)정보</li>
        </ul>
        <ul className="list-2depth">
          <li>⦁ 일반개인정보</li>
          <li>
            - 성명, 주소, 생년월일, 이메일, 유‧무선 전화번호, 성별, 국적, 직업,
            국내거소신고번호
          </li>
          <li>⦁ 신용거래정보</li>
          <li>
            - 금융거래 업무 관련 정보(대출‧보증‧담보제공 등의 종류, 기간, 금액
            및 한도 등 금융거래현황 등), 보험계약정보(상품종류, 기간, 보험료
            등), 보험금정보(보험금 지급사유, 지급금액 등)
          </li>
          <li>⦁ 신용도판단정보</li>
          <li>
            - 연체‧대위변제‧대지급‧부도‧금융질서 문란 및 관련인 등록과 관련된
            금액, 발생, 해소 시기 등에 관한 정보
          </li>
          <li>⦁ 공공정보 등</li>
          <li>
            - 법원 및 공공기관의 재판‧결정 또는 체납 등의 공공정보, 자동차
            보험계약 정보, 보증대상계약 관련 차대번호·부동산 고유번호,
            개인신용평점(등급)등 기타 개인신용정보, 신용정보회사‧전자
            서명인증사업자의 실명인증, 본인인증, 전자서명인증 등을 위한 정보
          </li>
        </ul>
      </section>
    </div>
  );
}
