export const siteLink = [
  { id: "1", name: "민원24", link: "https://www.gov.kr/portal/main" },
  {
    id: "2",
    name: "국민연금공단",
    link: "https://www.nps.or.kr/jsppage/main.jsp",
  },
  {
    id: "3",
    name: "국민건강보험 공단",
    link: "https://www.nhis.or.kr/nhis/index.do",
  },
  {
    id: "4",
    name: "금융감독원",
    link: "https://www.fss.or.kr/fss/main/main.do",
  },
  { id: "5", name: "여신금융협회", link: "https://www.crefia.or.kr/" },
  {
    id: "6",
    name: "저축은행중앙회",
    link: "https://www.fsb.or.kr/index.act",
  },
];

export const footerLink = [
  {
    id: "1",
    link: "https://edenfn.notion.site/F-N-9beee9a85a9940d4b2e417c777619438",
    name: "채용",
  },
  {
    id: "2",
    link: "https://policy.e-denden.com/service",
    name: "이용약관",
  },
  {
    id: "3",
    link: "https://policy.e-denden.com/policy",
    name: "개인정보처리방침",
  },
];
