import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import * as state from "recoil/atom";
import color from "assets/scss/_variables.scss";
import { TermsContentArray } from "../../Self_Certification_Funtion";
import TermsContent from "./Terms_Content";

export default function AllAgreeTermsContent({
  setTermsModalShown,
  isAllAgree,
  setIsAllAgree,
  switchAllAgree,
  agreeEachCheck,
  setAgreeEachCheck,
  userInfo,
}) {
  // 약관 아코디언
  const [activeAccordion, setAcctiveAccordion] = useState({
    이든든: false,
    금융기관: false,
    서민금융진흥원: false,
    서울보증보험: false,
  });

  useEffect(() => {
    if (userInfo.user_agreement === "1") {
      setIsAllAgree(true);
    } else {
      setIsAllAgree(false);
    }
  }, [userInfo.user_agreement]);

  // 필수약관전체 동의 박스 스타일
  const NoneAgreeBoxStyle = {
    border: "1px solid #cccccc",
    borderRadius: "8px",
    backgroundColor: "#fff",
    color: "#cccccc",
    marginTop: 0,
  };

  // 필수약관전체 동의 박스 스타일
  const AgreeBoxStyle = {
    border: `1px solid ${color.mainColor}`,
    borderRadius: "8px",
    backgroundColor: "#fff",
    color: `${color.mainColor}`,
    marginTop: 0,
  };

  return (
    <div className="terms-container">
      {isAllAgree ? (
        <div
          className="all-agree-box"
          name="user_agreement"
          onClick={() => {
            switchAllAgree();
          }}
          style={AgreeBoxStyle}
        >
          <span>필수 약관 전체 동의</span>
        </div>
      ) : (
        <button
          className="all-agree-box"
          name="user_agreement"
          style={NoneAgreeBoxStyle}
        >
          <span>필수 약관 전체 동의</span>
        </button>
      )}
      <div className="terms-content">
        {TermsContentArray.map(({ label, title, subTerms }, key) => (
          <TermsContent
            key={key}
            label={label}
            title={title}
            subTerms={subTerms}
            setTermsModalShown={setTermsModalShown}
            agreeEachCheck={agreeEachCheck}
            setAgreeEachCheck={setAgreeEachCheck}
            activeAccordion={activeAccordion}
            setAcctiveAccordion={setAcctiveAccordion}
            isAllAgree={isAllAgree}
            setIsAllAgree={setIsAllAgree}
          />
        ))}
      </div>
    </div>
  );
}
